// import React, { useContext, useEffect, useState } from 'react';
// import TableAccessRight from '../../../components/TableAccessRight';
// import axiosIntance from '../../../config/axios'
// import { RootContext } from '../../../App';
// import refactorStructureData from '../../../helpers/refactorDataMenu';
import TableAccessRightDetail from '../../../components/TableAcccessRightDetail';
// import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const AccessRightDetail = () => {
  

  return (
    <div>
    <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
    <div className="px-3 md:px-8 h-auto -mt-24">
      <div className="container mx-auto max-w-full">
        <div className="grid grid-cols-1 px-4 mb-16">
         <TableAccessRightDetail  />
        </div>
      </div>
    </div>
  </div>
  )
}

export default AccessRightDetail;
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";

export default function TableMarketWinlose({ data, pending}) {

    const columns = [
        {
            name: "Market",
            selector: (row, index) => row.username,
            width: "180px",
            sortable: true,
        },
        {
            name: "Date",
            selector: (row) => row.type,
            sortable: true,
        },
        {
            name: "Winlose",
            selector: (row) => row.number,
            sortable: true,
        },
        {
            name: "Winlose History",
            selector: (row) => row.bet,
            sortable: true,
        },
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    <h2>Market Win Lose</h2>
                </CardHeader>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={pending}
                        pagination
                    />
                </CardBody>
            </Card>
        </>
    );
}


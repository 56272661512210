import React, { useContext, useState } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useEffect } from "react";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
import TableBankAccount from "../../components/TableBankAccount";
import { Button, InputIcon, Modal, ModalBody, ModalFooter, ModalHeader } from "@material-tailwind/react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useDebouncedCallback } from "use-debounce/lib";
import Swal from "sweetalert2";


const BankAccount = () => {

    const confirmSelectedBankNumInit = {
        id: '',
        bank_num: '',
    }

    const [data, setData] = useState()
    const [pending, setPending] = useState(true)
    const history = useHistory()
    const { state, setState } = useContext(RootContext)
    const [length, setLength] = useState(20)
    const [bankName, setBankName] = useState()
    const [bankAccount, setBankAccount] = useState()
    const [selectedBankNum, setSelectedBankNum] = useState(confirmSelectedBankNumInit)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const debounced = useDebouncedCallback((action) => {
        action()
    }, 500)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [length, bankName, bankAccount])

    const getData = () => {

        const token = localStorage.getItem("access_token");

        const group = JSON.parse(localStorage.getItem("group"))
        const data = {
            token: token,
            group: group.wbid,
            bnam: bankName,
            bacc: bankAccount,
            lgth: length
        }
        if (!token) {
            history.push("/login");
        } else {
            try {

                axiosInstance("get_bank_account", {
                    method: "POST",
                    data,
                })
                    .then((res) => {
                        const data = res.data.data.list
                        setData(data)
                        setState({
                            ...state,
                            group: res.data.data.additional_data.group,
                            menu: refactorStructureData(res.data.data.additional_data.menu),
                        });
                    })
                    .then(() => {
                        setPending(false);
                    })
                    .catch((err) => {
                        localStorage.removeItem("access_token");
                        history.push("/login");
                    });
            } catch (error) {
                console.log("ERROR: ", error);
            }
        }
    };

    const handleModalDelete = (isOpen, data) => {
        setIsModalOpen(isOpen);
        setSelectedBankNum((state) => ({
            ...state,
            id: data.id,
            bank_num: data.bnum,
        }))

    };

    const handleOnSubmit = () => {
        setLoading(true);

        try {
            const token = localStorage.getItem("access_token");

            const group = JSON.parse(localStorage.getItem("group"))

            axiosInstance("delete_bank_account", {
            method: "POST",
            data: {
                token: token,
                group: group.wbid,
                bank_num: selectedBankNum.bank_num,
            },
            })
            .then((res) => {
                Swal.fire(
                    'Deleted!',
                    'Your data is deleted!',
                    'success'
                  )
                getData()
                setLoading(false);
                setIsModalOpen(false);
                // setState(confirmSelectedBankNumInit)
            })
            .catch((err) => {
                console.log('Error', err)
                setLoading(false);
                setIsModalOpen(false);
                // setState(confirmSelectedBankNumInit)
            });
        } catch (error) {
            setLoading(false);
            setIsModalOpen(false);
            // setState(confirmSelectedBankNumInit)
            console.log('error => ', error);
        }
    };


    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <Card>
                            <h5>Advanced Search</h5>
                            <CardBody className="flex gap-4">
                                <InputIcon
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Bank name"
                                    iconName={"search"}
                                    onChange={(event) => {
                                        debounced(setBankName(event.target.value))
                                    }}
                                />
                                <InputIcon
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Bank Account"
                                    iconName={"search"}
                                    onChange={(event) => {
                                        debounced(setBankAccount(event.target.value))
                                    }}
                                />
                            </CardBody>

                        </Card>
                        <Card className="mt-12">
                            <CardHeader color="blueGray" contentPosition="left">
                                <h2 className="text-white text-2xl">Bank Account</h2>
                            </CardHeader>
                            <CardBody>
                                <Link to="/banking/create_bank_account">
                                    <Button
                                        color="lightBlue"
                                        buttonType="filled"
                                        size="sm"
                                        rounded={false}
                                        className="mb-4"
                                        block={false}
                                        iconOnly={false}
                                        ripple="light"
                                    >Add Bank Account</Button>
                                </Link>
                                <TableBankAccount pending={pending} data={data}
                                    onChangeRowsPerPage={(lengthPage) => {
                                        setLength(lengthPage)
                                    }} handleModalDelete={handleModalDelete} />
                            </CardBody>
                        </Card>

                        <Modal
                            size="sm"
                            active={isModalOpen}
                            toggler={() => setIsModalOpen(false)}
                        >
                            <ModalHeader toggler={() => setIsModalOpen(false)}>
                                Delete Bank Account
                            </ModalHeader>
                            <ModalBody>
                                <div className='flex flex-col space-y-4 w-72'>
                                    <p className="text-xs">Apakah Anda Yakin Akan menghapus data ini?</p>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="red"
                                    buttonType="link"
                                    onClick={() => setIsModalOpen(false)}
                                    ripple="dark"
                                >
                                    Cancel
                                </Button>

                                <Button
                                    color="red"
                                    onClick={() => handleOnSubmit()}
                                    ripple="light"
                                >
                                    {loading ? 'Loading...' : 'Delete'}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankAccount;

import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import InputIcon from '@material-tailwind/react/InputIcon';
import DataTable from 'react-data-table-component';
import Button from '@material-tailwind/react/Button';

export default function CardTable({ data, pending, handleModal }) {
  const columns = [
    {
      name: '#',
      width: '80px',
      selector: (row, idx) => idx + 1,
      sortable: true,
    },
    {
      name: 'Username',
      selector: (row) => row.unam,
      sortable: true,
    },
    {
      name: 'Current Coin',
      selector: (row) => row.coin,
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => (
        <Button
          color="lightBlue"
          buttonType="filled"
          size="sm"
          rounded={false}
          block={false}
          iconOnly={false}
          ripple="light"
          onClick={() => {
            handleModal(true, row);
          }}
        >
          +
        </Button>
      ),
      sortable: true,
    },
  ];



  return (
    <>
      <Card>
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Coin Admin</h2>
        </CardHeader>
        <CardBody>
          <div className="flex justify-end">
            <div style={{ width: 250 }}>
              <InputIcon
                type="text"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="Search"
                iconName="search"
              />
            </div>
          </div>
          <DataTable
            columns={columns}
            data={data}
            progressPending={pending}
            pagination
          />
        </CardBody>
      </Card>
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
import TableCashbackHistory from "../../components/TableCashbackHistory";
import TableRolingHistory from "../../components/TableRolingHistory";

export default function RolingHistory() {

  const history = useHistory();
  const [data, setData] = useState();
  const [pending, setPending] = useState(true);
  const { state, setState } = useContext(RootContext);

  function handleDetail(detaildate) {

    history.push('/money_management/roling/'+detaildate);
  }

  const getData = () => {

    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"));

    const data = {
      token: token,
      group: group.wbid,
    }

    if (!token) {

      history.push("/login");
    } else {

      try {

        axiosInstance("get_roling_history", {
          method: "POST",
          data,
        }).then((res) => {

            const data = res.data.data.list;

            setData(data);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

          }).then(() => {

            setPending(false);
          }).catch((err) => {

            localStorage.removeItem("access_token");
            history.push("/login");
            console.log("error: "+err);
          });
      } catch (error) {

        console.log("ERROR: ", error);
      }
    }
  };

  useEffect(() => {

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableRolingHistory
              data={data}
              pending={pending}
              detail={handleDetail}
            />
          </div>
        </div>
      </div>
    </>
  );
}

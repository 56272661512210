import React, { useContext, useState, useEffect } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
import TableBonus from "../../components/TableBonus";
import { Button, InputIcon } from "@material-tailwind/react";
// import { Link } from "react-router-dom";
import Select from "react-select";

const Bonus = () => {
    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const history = useHistory();
    const { state, setState } = useContext(RootContext);
    const [length, setLength] = useState(20);
    const [user, setUser] = useState(data.uid)
    const [optionsUser, setOptionsUser] = useState([]);
  const [loading, setLoading] = useState(false);


    const listUser = () => {
        axiosInstance.post('get_user_list', {
            token: localStorage.getItem("access_token"),
            group: JSON.parse(localStorage.getItem("group")).wbid
        }).then((res) => {
            console.log(res.data.data.list);
            setOptionsUser(res.data.data.list.map((data) => {
                return {
                    label: data.user,
                    value: data.uid
                }
            }))
        })
    }

    useEffect(() => {
        getData();
        listUser();
    }, [length]);

    const handleAdd = () => {
        setLoading(true);
        // input ke api
        const token = localStorage.getItem("access_token");
        const group = JSON.parse(localStorage.getItem("group"));
        const data = {
            token: token,
            group: group.wbid,
            uid: user,
        };
        try {
            axiosInstance("add_user_exclude", {
                method: "POST",
                data,
            })
                .then((res) => {
                    // const data = res.data.data.list;
                    getData();
                    setLoading(false);
                })
                .catch((err) => {
                    localStorage.removeItem("access_token");
                    history.push("/login");
                });
        } catch (error) {
            console.log("ERROR: ", error);
        }
    };

    const handleSave = () => {
        // input ke api
        setLoading(true);
        const token = localStorage.getItem("access_token");
        const group = JSON.parse(localStorage.getItem("group"));
        const data = {
            token: token,
            group: group.wbid,
            uid: user,
        };
        try {
            axiosInstance("update_bonus", {
                method: "POST",
                data,
            })
                .then((res) => {
                    // const data = res.data.data.list;
                    getData();
                    setLoading(false);
                })
                .catch((err) => {
                    localStorage.removeItem("access_token");
                    history.push("/login");
                });
        } catch (error) {
            console.log("ERROR: ", error);
        }
    };

    const handleDelete = (userId) => {
        // input ke api
        setLoading(true);
        const token = localStorage.getItem("access_token");
        const group = JSON.parse(localStorage.getItem("group"));
        const data = {
            token: token,
            group: group.wbid,
            uid: userId,
        };
        try {
            axiosInstance("delete_user_exclude", {
                method: "POST",
                data,
            })
                .then((res) => {
                    // const data = res.data.data.list;
                    getData();
                    setLoading(false);
                })
                .catch((err) => {
                    localStorage.removeItem("access_token");
                    history.push("/login");
                });
        } catch (error) {
            console.log("ERROR: ", error);
        }
    };



    const getData = () => {
        setLoading(true);
        const token = localStorage.getItem("access_token");
        const group = JSON.parse(localStorage.getItem("group"));
        const data = {
            token: token,
            group: group.wbid,
        };
        try {
            axiosInstance("get_bonus", {
                method: "POST",
                data,
            })
                .then((res) => {
                    const data = res.data.data.list;
                    setData(data);
                    setLoading(false);
                    setState({
                        ...state,
                        group: res.data.data.additional_data.group,
                        menu: refactorStructureData(res.data.data.additional_data.menu),
                    });

                })
                .then(() => {
                    setPending(false);
                })
                .catch((err) => {
                    localStorage.removeItem("access_token");
                    history.push("/login");
                });
        } catch (error) {
            console.log("ERROR: ", error);
        }
    };



    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">

                        <Card className="mt-12">
                            <CardHeader color="blueGray" contentPosition="left">
                                <h2 className="text-white text-2xl">Bonus</h2>
                            </CardHeader>
                            <CardBody className="flex gap-4">
                                {/* <form onSubmit={submit}> */}
                                    <div className="row"></div>
                                    <InputIcon
                                        type="text"
                                        color="lightBlue"
                                        size="sm"
                                        outline={true}
                                        placeholder="Percentage"
                                        iconName="percentage"
                                    />
                                    <InputIcon
                                        type="text"
                                        color="lightBlue"
                                        size="sm"
                                        outline={true}
                                        placeholder="Days"
                                        iconName="numbers"
                                    />
                                    <InputIcon
                                        type="text"
                                        color="lightBlue"
                                        size="sm"
                                        outline={true}
                                        placeholder="Hours"
                                        iconName="numbers"
                                    />
                                    <div style={{ width: '100%' }}>
                                    <Select
                                        options={optionsUser.map((option) => {
                                            return {
                                                value: option.value,
                                                label: option.label
                                            }
                                        })
                                        }
                                        onChange={(event) => {
                                            setUser(event.value)
                                        }}
                                        placeholder={data.user}
                                    />
                                    </div>
                                    {/* <Link to="/bonus/create_bonus"> */}
                                    <div style={{ width: '50%' }}>
                                        <Button
                                            color="lightBlue"
                                            buttonType="filled"
                                            size="sm"
                                            rounded={false}
                                            className="mb-4"
                                            block={false}
                                            iconOnly={false}
                                            ripple="light"
                                            onClick={handleAdd}>
                                            {loading ? 'Loading...' : 'Add User'}
                                        </Button>
                                        </div>
                                    {/* </Link> */}
                                {/* </form> */}
                            </CardBody>
                            <CardBody>
                                <TableBonus pending={pending} data={data} loading={loading} handleDelete={handleDelete}
                                    onChangeRowsPerPage={(lengthPage) => {
                                        setLength(lengthPage)
                                    }} />
                                <Button
                                    color="lightGreen"
                                    buttonType="filled"
                                    size="sm"
                                    rounded={false}
                                    className="mb-4"
                                    block={false}
                                    iconOnly={false}
                                    ripple="light"
                                    style={{ float: 'right' }}
                                    onClick={handleSave}
                                >{loading ? 'Loading...' : 'Save'}</Button>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bonus;

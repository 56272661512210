import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import Select from 'react-select'


export default function CardTable({ reportData, pending, periodEvent, monthName }) {
    const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const columns = [
        {
            name: "Type",
            // width: '150px',
            selector: (row, index) => row.type,
            sortable: true,
        },{
            name: "Turnover",
            // width: '150px',
            selector: (row, index) => addCommas(row.turnover),
            sortable: true,
        },{
            name: "Menang / Kalah",
            // width: '150px',
            selector: (row, index) => addCommas(row.winlose),
            sortable: true,
            right: true
        },{
            name: "Komisi Agent",
            // width: '150px',
            selector: (row, index) => addCommas(row.komisi),
            sortable: true,
            right: true
        },{
            name: "Tagihan Agent",
            // width: '150px',
            selector: (row, index) => addCommas(row.tagihan),
            sortable: true,
            right: true
        },
    ];

    const monthMapping = {
        January: 0,
        February: 1,
        March: 2,
        April: 3,
        May: 4,
        June: 5,
        July: 6,
        August: 7,
        September: 8,
        October: 9,
        November: 10,
        December: 11
      };

    const period = [
        {
            value: 0,
            label: 'January',
        },{
            value: 1,
            label: 'Febuary',
        },{
            value: 2,
            label: 'March',
        },{
            value: 3,
            label: 'April',
        },{
            value: 4,
            label: 'May',
        },{
            value: 5,
            label: 'June',
        },{
            value: 6,
            label: 'July',
        },{
            value: 7,
            label: 'Auguest',
        },{
            value: 8,
            label: 'September',
        },{
            value: 9,
            label: 'Oktober',
        },{
            value: 10,
            label: 'November',
        },{
            value: 10,
            label: 'December',
        },
    ]

  return (
    <>
      <Card>
        <h5>Advanced Search</h5>
        <CardBody>
            <div className="flex gap-4">
                <Select 
                    className="w-full"
                    size="sm"
                    options = {period.map((option) => {
                            return {
                                value: option.value,
                                label: option.label
                            }
                        })
                    }
                    onChange={periodEvent}
                    placeholder="Periode" 
                />
            </div>
        </CardBody>
      </Card>
      <Card className="mt-10">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Report Detail {monthName}</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={reportData}
            progressPending={pending}
            // paginationRowsPerPageOptions={[10, 50, 100, 500]}
            // onChangeRowsPerPage={onChangeRowsPerPage}
            // pagination
          />
        </CardBody>
      </Card >
    </>
  );
}
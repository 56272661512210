import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { Button } from "@material-tailwind/react";
import DataTable from "react-data-table-component";

export default function TableWithdrawRequestHistory({ data, pending }) {

    const columns = [
        {
            name: 'No.',
            selector: (row, index) => index + 1,
            width: '80px',
            sortable: true
        },
        {
            name: "Tanggal Transaksi",
            selector: (row) => row.date,
            sortable: true,
            width: "180px"
        },
        {
            name: "Total Amount",
            selector: (row) => <tt>{row.nominal}</tt>,
            sortable: true,
            right: true,
        },
        {
            name: "Jumlah Transaksi",
            selector: (row) => <tt>{row.total}</tt>,
            sortable: true,
            right: true,
        },
       
        {
            name: "Aksi",
            sortable: true,
            selector: row => <Button
                            color="blue"
                            buttonType="filled"
                            size="sm"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="light"
                            onClick={() => window.location.href = `/money_management/withdraw_history_detail/${row.date}`}
                        >Detail
                        </Button>,
            ignoreRowClick: true,
            ignoreRowExpansion: true,
            width: '120px'

        },
    ];

    const handleExport = (dataJson) => {
    const header = '"date","amount","total"\n';
    const csvData = dataJson.map(d => `"${d.date}","${d.nominal}","${d.total}"\n`).join('');
    const blob = new Blob([header + csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'withdraw-lastmonth.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    };

    return (
        <>
            <Card className="mt-12">
                <CardHeader color="blueGray" contentPosition="left">
                    <h2 className="text-white text-2xl">Withdraw History</h2>
                </CardHeader>
                <CardBody>
                <Button onClick={() => handleExport(data)}>Export To CSV</Button>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={pending}
                />
                </CardBody>
            </Card>
        </>
    );
}

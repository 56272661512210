import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
// import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Label } from "@material-tailwind/react";
export default function TableMeta({ data, pending, onChangeRowsPerPage, }) {


    const columns = [
        {
            name: "#",
            selector: (row) => row.id,
            width: '80px'
        },
        {
            name: "Url site meta",
            selector: (row) => row.url,
            sortable: true
        },
        {
            name: "Metadata site",
            selector: (row) => row.mdat,
            sortable: true
        },
        {
            name: "Active",
            selector: (row) => (row.actv === 1 ? <Label color={"green"}>Active</Label> : <Label color={"red"}>Non active</Label>)
        },
        {
            name: "",
            selector: (row) => (
                <div className="flex gap-4">
                    <Link to={"/web_management/cms/meta_update/" + row.id}>
                        <Button
                            color="green"
                            buttonType="filled"
                            size="sm"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="light"
                        >
                            Update
                        </Button>
                    </Link>
                </div>
            )
        }
    ];




    return (
        <Card>
            <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Site meta</h2>
            </CardHeader>
            <CardBody>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={pending}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    pagination
                />
            </CardBody>
        </Card>
    );
}

import React, { useContext, useEffect, useState } from 'react';
import TablePlayerRecords from "components/TablePlayerRecords";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import { RootContext } from '../../App';
import refactorStructureData from '../../helpers/refactorDataMenu';
export default function PlayerList() {
  const history = useHistory();
  const [playerRecords, setPlayerRecords] = useState()
  const [pending, setPending] = useState(true);
  const [username, setUsername] = useState(null)
  const [fromCreateAt, setFromCreateAt] = useState(null)
  const [toCreateAt, setToCreateAt] = useState(null)
  const [length, setLength] = useState(10)
  const { state, setState } = useContext(RootContext)
  const debounced = useDebouncedCallback(
    (action) => {
      action()
    },
    200
  );

  useEffect(() => {
    axiosInstance.post(`/verify_token`, {
      token: localStorage.getItem("access_token")
    }).then((res) => {
      // localStorage.setItem("access_token", res.data.new_token)
    }).catch((err) => {
      localStorage.removeItem("access_token")
      window.location.href = "/login"
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    verifyToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, toCreateAt, fromCreateAt, length])

  const verifyToken = () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      history.push("/login");
    } else {
      try {
        axiosInstance("verify_token", {
          method: "POST",
          data: {
            token: token,
          },
        })
          .then((res) => {
            setState({
              ...state,
              group: res.data.data.group,
              menu: refactorStructureData(res.data.data.menu),
            });
            if (!localStorage.getItem("group")) {
              localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
            }
          })
          .catch((err) => {

            localStorage.removeItem("access_token");
            history.push("/login");
          });

      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };


  const getData = () => {
    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"))

    if (!token) {
      history.push("/login");
    } else {
      try {
        axiosInstance("get_user_record", {
          method: "POST",
          data: {
            token: token,
            group: group.wbid,
            user: username,
            crea: fromCreateAt,
            cre2: toCreateAt,
            lgth: length
          },
        })
          .then((res) => {
            
            console.log(res.data)
            setPlayerRecords(res.data.data)
            setPending(false);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });
          })
          .catch((err) => {
            // localStorage.removeItem("access_token");
            // history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto">
      </div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TablePlayerRecords pending={pending} title="Player List" playerRecords={playerRecords}
              usernameEvent={(event) => {
                debounced(() => setUsername(event.target.value))
              }}
              fromCreateAtEvent={(event) => {
                debounced(() => setFromCreateAt(event.target.value))
              }}
              toCreateAtEvent={(event) => {
                debounced(() => setToCreateAt(event.target.value))
              }}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setLength(currentRowsPerPage)
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

import { Link, useLocation } from "react-router-dom";

import Button from "@material-tailwind/react/Button";

import Icon from "@material-tailwind/react/Icon";

import Image from "@material-tailwind/react/Image";

import Dropdown from "@material-tailwind/react/Dropdown";

import DropdownItem from "@material-tailwind/react/DropdownItem";

import ProfilePicture from "assets/img/profile.jpg";

import SoundNotification from "assets/sound/bell-notification.wav"

import { RootContext } from "../App";

import { useEffect, useState } from "react";

import axiosIntance from '../config/axios';

import Select from 'react-select';

export default function AdminNavbar({ showSidebar, setShowSidebar }) {

  const location = useLocation().pathname;

  const [notification , setNotification] = useState();

  const getNotif = () => {

    var audioDeposit = new Audio(SoundNotification);

    if(localStorage.getItem("access_token")){

      axiosIntance.post('get_notification' ,{

        token : localStorage.getItem("access_token"),
        
        group: JSON.parse(localStorage.getItem("group"))?.wbid

      }).then((res)=>{

        setNotification(res.data.data.list);

        if (res.data.data.list.depo > 0 || res.data.data.list.wdrw > 0) {  

          audioDeposit.autoplay=true;

          audioDeposit.load();

          audioDeposit.play();
        }
      })
    }
  }

  useEffect(()=>{

    if(localStorage.getItem("access_token")){

      axiosIntance.post('get_notification' ,{

        token : localStorage.getItem("access_token"),

        group: JSON.parse(localStorage.getItem("group"))?.wbid

      }).then((res)=>{

        setNotification(res.data.data.list);
      })

    }

    setInterval(() => {

      console.log('Get Notif');
      getNotif();

    }, 25000);

  },[]);

  return (
    <RootContext.Consumer>
      {({ state }) => (

        <nav className="bg-light-blue-500 md:ml-64 py-6 px-3">
          <div className="container max-w-full mx-auto flex items-center gap-4 justify-between md:pr-8 md:pl-10">
            <div className="md:hidden">
              <Button
                color="transparent"
                buttonType="link"
                size="lg"
                iconOnly
                rounded
                ripple="light"
                onClick={() => setShowSidebar("left-0")}
              >
                <Icon name="menu" size="2xl" color="white" />
              </Button>
              <div
                className={`absolute top-2 md:hidden ${showSidebar === "left-0" ? "left-64" : "-left-64"
                  } z-50 transition-all duration-300`}
              >
                <Button
                  color="transparent"
                  buttonType="link"
                  size="lg"
                  iconOnly
                  rounded
                  ripple="light"
                  onClick={() => setShowSidebar("-left-64")}
                >
                  <Icon name="close" size="2xl" color="white" />
                </Button>
              </div>
            </div>


            <div className="flex justify-between items-center w-full">
              <p className="uppercase text-white text-sm  mt-1">
                {
                  location.includes("contact_us_update") && "web management > CMS >UPDATE CONTACT US"
                }
                {location === "/"
                  ? "DASHBOARD"
                  :
                  location.includes("player_detail") ||
                    location.includes("list_referal") ||
                    location.includes("contact_us_update") ||
                    location.includes("web_management/cms/promotional_group")
                    ? null : location
                      .toUpperCase()
                      .replace("/", "")
                      .split("/").join(" > ")
                      .split("_")
                      .join(" ")}
                {
                  location.includes("player_detail") && "PLAYER MANAGEMENT > PLAYER DETAIL"
                }
                {
                  location.includes("list_referal") && "PLAYER MANAGEMENT > LIST REFERRAL"
                }
                {
                  location.includes("web_management/cms/promotional_group") && "WEB MANAGEMENET > CMS > PROMOTIONAL_BANNER"
                }

              </p>


              <div className="flex gap-3 justify-center " style={{ padding: '0 10px' }}>
                <Link to="/money_management/deposit_request" className="notification"
                  style={{
                    position: 'relative',
                  }}
                >
                  <Button size="sm" color="green">Deposit</Button>
                  <span
                    id="notification_deposit"
                    className="bg-black"
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                      fontSize: '12px',
                      padding: '1px 5px',
                      borderRadius: '50%',
                      color: 'white',
                    }}>{notification ? notification?.depo : 0}</span>
                </Link>
                <Link to="/money_management/withdraw_request" className="notification"
                  style={{
                    position: 'relative',
                  }}
                >
                  <Button size="sm" color="red">Withdraw</Button>
                  <span
                    id="notification_withdraw"
                    className="bg-black"
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                      fontSize: '12px',
                      padding: '1px 5px',
                      borderRadius: '50%',
                      color: 'white',
                    }}>{notification ? notification?.wdrw : 0}</span>
                </Link>
                <Link to="/web_management/cms/promotional_banner" className="notification"
                  style={{
                    position: 'relative',
                  }}
                >
                  <Button size="sm" color="blue">Promotion</Button>
                  <span
                    className="bg-black"
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                      fontSize: '12px',
                      padding: '1px 5px',
                      borderRadius: '50%',
                      color: 'white',
                    }}>{notification ? notification?.prmo : 0}</span>
                </Link>
                <Link to="" className="notification"
                  style={{
                    position: 'relative',
                  }}
                >
                  <Button size="sm" color="indigo">Memo</Button>
                  <span
                    className="bg-black"
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                      fontSize: '12px',
                      padding: '1px 5px',
                      borderRadius: '50%',
                      color: 'white',
                    }}>{notification ? notification?.memo : 0}</span>
                </Link>



              </div>


              <div className="flex">

                <div className="flex items-center gap-4  mr-1 ml-6">
                  <label className="text-white">Group</label>
                  <div style={{ width: '200px' }}>
                    <Select
                      options={state.group.map((data) => {
                        return {
                          label: data.name,
                          value: data,
                        }
                      })}
                      placeholder={localStorage.getItem("group") ? JSON.parse(localStorage.getItem("group")).name : "Choose Group"}
                      onChange={(event) => {
                        localStorage.setItem("group", JSON.stringify(event.value));
                        window.location.reload();
                      }}
                    />
                  </div>

                </div>

                <div className="-mr-4 ml-6">
                  <Dropdown
                    color="transparent"
                    buttonText={
                      <div className="w-12">
                        <Image src={ProfilePicture} rounded />
                      </div>
                    }
                    rounded
                    style={{
                      padding: 0,
                      color: "transparent",
                    }}
                  >
                    <DropdownItem color="lightBlue">Action</DropdownItem>
                    <DropdownItem color="lightBlue">
                      Another Action
                    </DropdownItem>
                    <DropdownItem color="lightBlue">
                      Something Else
                    </DropdownItem>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </nav>
        
      )
      }
    </RootContext.Consumer >
  );
}


import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
import { Link } from "react-router-dom";
import { Label } from "@material-tailwind/react";
export default function TableUserList({ data, pending, onChangeRowsPerPage }) {


    const columns = [
        {
            name: "#",
            selector: (row) => row.id,
            width: '80px'
        },
       
        {
            name: "Name",
            selector: (row) => row.name
        },
          
        {
            name: "Group Name",
            selector: (row) => row.gnam
        },
        {
            name: "Access name",
            selector: (row) => row.accs
        },
        {
            name: "Last IP",
            selector: (row) => row.laip
        },
        {
            name: "Last login date",
            selector: (row) => row.lalo
        },
        {
            name: "Admin coin",
            selector: (row) => row.coin
        },
        {
            name: "Status",
            selector: (row) => row.stat === "1" ? <Label color={"green"}>Active</Label> : <Label color={"red"}>NonActive</Label>
        },
        {
            name: "",
            width: '150px',
            selector: (row) => (
                <Link to={"/web_management/cms/user_list/" + row.id}>
                    <Button >Detail</Button>
                </Link>
            )
        }
    ];




    return (

        <DataTable
            columns={columns}
            data={data}
            progressPending={pending}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination
        />
    );
}

import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
import axiosInstance from "../config/axios";
import Swal from "sweetalert2";
import SweetAlert2 from "react-sweetalert2";
import {  useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

export default function TableBonusDepositRequest({ data, pending, acceptEvent, declineEvent }) {

  const token = localStorage.getItem("access_token");
  const history = useHistory();

  const group = JSON.parse(localStorage.getItem("group"))

  const [swalProps, setSwalProps] = useState({});

  // const [bankAccountName, setBankAccountName] = useState();

  // const [accountBank, setBankAccount] = useState({});

  // let [accountBankId, setBankAccountId] = useState();

  const [idAccept , setIdAccept] = useState();
  const [userAccept , setUsernameAccept] = useState();

  useEffect(()=>{
  
    const data = {
      token: token,
      group: group.wbid,
      uid: idAccept,
      username: userAccept,
    };
    // console.log(data)
    if(swalProps.isConfirmed){
      axiosInstance
      .post("bonus_accept", data)
      .then((res) => {
        acceptEvent()
        Swal.fire("Success", "success", "success");
      })
      .catch((err) => {
        Swal.fire("Failed", "failed", "error");

        localStorage.removeItem("access_token");
        history.push("/login");
      });


    }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
  },[swalProps])
// },[accountBankId , swalProps])

  function handleClick(id,sban) {
    const data = {
      token: token,
      group: group.wbid,
      bnam: sban,
    };
    axiosInstance
      .post("get_bank_account", {
        ...data
      })
      .then((res) => {
        // const response = res.data.data.list[0];
        // setBankAccountId(response.id)
        setSwalProps({
          show: true,
          title: "Confirmation!",
          onResolve: (result) => {
            
            if (result.isConfirmed) {
              setSwalProps({ ...swalProps, isConfirmed: true , show: false });
            }

            setSwalProps({ ...swalProps, show: false });
          },
        });
      })
      .catch((err) => {
        // console.log(err.response);
        Swal.fire("Failed", "failed", "error");

        localStorage.removeItem("access_token");
        history.push("/login");
      });
    
  }

  // const accept = (event, id) => {
  //   const data = {
  //     token: token,
  //     group: group.wbid,
  //     req_id: id
  //   }

  //   axiosInstance.post('deposit_accept', data).then((res) => {
  //     Swal.fire(
  //       res.data.message,
  //       res.data.message,
  //       'success'
  //     )
  //   }).catch((err) => {
  //     console.log(err)
  //   })
  //   event.preventDefault()
  // }

  const decline = (event, id) => {
    const data = {
      token: token,
      group: group.wbid,
      req_id: id
    }
    Swal.fire({
      title: 'Admin note',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        declineEvent()
        axiosInstance.post('deposit_decline', { admin_note: login, ...data }).then((res) => {
          Swal.fire(res.data.message, 'Deposit Request is rejected', 'success')
        }).catch((err) => {
          // console.log(err)
        })
        event.preventDefault()
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
 

    })
    .catch((err) => {
      // console.log(err.response);
      Swal.fire("Failed", "failed", "error");

      localStorage.removeItem("access_token");
      history.push("/login");
    });



  }


  const columns = [
    {
      name: "ID",
      width: "80px",
      selector: (row, index) => row.uid,
      sortable: true,
    },
    {name:"Username",
    selector:(row)=>row.user
  },
    {
      name: "Amount",
      selector: (row) => <tt>{row.amount}</tt>,
      sortable: true,
      right: true,
    },
    {
      name: "Kode",
      selector: (row) => row.code,
      sortable: true,
      width: '220px'
    },
    {
      name: "",
      selector: (row) => (
        <div className="flex gap-4">
          <Button
            color="green"
            buttonType="filled"
            size="sm"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
            onClick={(event) => {
              handleClick(row.uid, row.sban);
              setIdAccept(row.uid);
              setUsernameAccept(row.user);
              // accept(event, row.id)
              // acceptEvent()
            }}
          >
            Accept
          </Button>
          <Button
            color="deepOrange"
            buttonType="filled"
            size="sm"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
            onClick={(event) => {
              decline(event, row.uid)
            }}
          >
            Decline
          </Button>
        </div>

      ),
      sortable: true,
      width: '250px'
    },
  ];


  return (
    <Card>
      <CardHeader color="blueGray" contentPosition="left">
        <h2 className="text-white text-2xl">Bonus Deposit Request</h2>
      </CardHeader>
      {
            swalProps.show && (
              <SweetAlert2 {...swalProps}>
              <div style={{ height: "50px" }}>
                <h3>Accept Permintaan Bonus Deposit ini?</h3>
              </div>
            </SweetAlert2>
            )
          }
      <CardBody>
        <div className="flex justify-between">
          <div className="mb-6 flex gap-4">
            {/* <Link to="/money_management/create_deposit_request">
              <Button
                color="lightBlue"
                buttonType="filled"
                size="regular"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="light"
              >
                Manual Deposit
              </Button>
            </Link> */}
          </div>
        </div>
        <DataTable
          columns={columns}
          data={data}
          progressPending={pending}
          pagination
        />
      </CardBody>
    </Card>
  );
}

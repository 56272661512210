import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { RootContext } from "../../App";
import TableAdjustmentDetail from "../../components/TableAdjustmentDetail";
import axiosInstance from "../../config/axios";
import refactorStructureData from "../../helpers/refactorDataMenu";

export default function AdjustmentRequestDetail() {
  const [data, setData] = useState();
  const [pending, setPending] = useState(true);
  // const [length, setLength] = useState(15)
  const [length] = useState(15)
  const [username, setUsername] = useState()
  const [date, setDate] = useState()
  const history = useHistory()
  const [listProvider, setListProvider] = useState()
  const [provider, setProvider] = useState()
  const debounced = useDebouncedCallback((action) => { action() }, 200)
  const { state, setState } = useContext(RootContext)

  useEffect(() => { 
    getData() 
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [username, date, provider])

  const getData = () => {

    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"))
    const data = {
      token: token,
      group: group.wbid,
      user: username,
      date: date,
      wallet: provider,
      lgth: length,
    }
    if (!token) {
      history.push("/login");
    } else {
      try {

        axiosInstance("get_adjustment_detail", {
          method: "POST",
          data,
        })
          .then((res) => {
            const data = res.data.data.list
            setData(data)
            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });
            setListProvider(res.data.data.adv_search.wallet_to['list provider'].map((data) => {
              return {
                label: data.name,
                value: data.name
              }
            }))
          })
          .then(() => {
            setPending(false);
          })
          .catch((err) => {
            localStorage.removeItem("access_token");
            history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableAdjustmentDetail
              data={data}
              pending={pending}
              usernameEvent={(event) => {
                debounced(setUsername(event.target.value))
              }}
              dateEvent={(event) => {
                setDate(event.target.value)
              }}
              listProvider={listProvider}
              providerEvent={(event) => {
                setProvider(event.value)
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

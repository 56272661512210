import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../config/axios";
import { useHistory } from "react-router-dom";
import TableDepositRequestHistory from "../../components/TableDepositRequestHistory";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";

export default function DepositRequestDetail() {

    const [data, setData] = useState();

    const [pending, setPending] = useState(true);

    const {state , setState} = useContext(RootContext);

    const history = useHistory();

    useEffect(() => {

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"));

    const getData = () => {

        const data = {
            token: token,
            group: group.wbid,
        }

        if (!token) {

            history.push("/login");
        } else {

            try {

                axiosInstance("get_deposit_mutation", {
                    method: "POST",
                    data,
                })
                    .then((res) => {

                        const arrayDepositMutation = res.data.data.list;

                        setData(arrayDepositMutation);

                        setState({

                            ...state,
                            group: res.data.data.additional_data.group,
                            menu: refactorStructureData(res.data.data.additional_data.menu),
                            
                          });
                        
                    })
                    .then(() => {

                        setPending(false);
                    })
                    .catch((err) => {

                        //localStorage.removeItem("access_token");

                        //history.push("/login");

                        console.log(err);

                    });
            } catch (error) {

                console.log("ERROR: ", error);

            }
        }
    };

    return (
        <>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableDepositRequestHistory
                            data={data}
                            pending={pending}
                        />
                       
                    </div>
                </div>
            </div>
        </>
    );
}

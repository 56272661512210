import React, { useContext, useEffect, useState } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import axiosInstance from '../../../config/axios';
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import { Button } from "@material-tailwind/react";
import { useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import { Input } from "@material-tailwind/react";

const CreateSlideshowBanner = () => {

  const [inputFields, setInputFields] = useState();
  const { state, setState } = useContext(RootContext);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(false);
  const history = useHistory();
  const group = JSON.parse(localStorage.getItem("group"));

  const fileHandler = (e) => {

    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {

      setInputFields(event.target.result);

    }

  }

  const submitHandle = () => {

    setLoading(true);
    const token = localStorage.getItem("access_token");
    axiosInstance.post('add_baner', {

      token: token,
      group: group.wbid,
      title: title,
      file: inputFields,

    }).then((res) => {

      if(res.data.code === 0){

        Swal.fire(
          'Saved!',
          'Your data is saved!',
          'success'
        ).then( () => {

          history.push("/web_management/cms/slideshow_banner");

        });
      }else{

        Swal.fire(
          'Failed!',
          'Failed Upload!',
          'Warning'
        );

        console.log(res.data.message);
      }

    }).catch((err) => {

      Swal.fire(
        'Failed!',
        'Failed Upload!',
        'Warning'
      );

      console.log(err);

    })

  }

  const getMenu = () => {

    const token = localStorage.getItem("access_token");

    axiosInstance.post('get_menu', {

      token: token,
      group: group.wbid,

    }).then((res) => {

      setState({

        ...state,

        group: res.data.data.additional_data.group,

        menu: refactorStructureData(res.data.data.additional_data.menu),

      });

    }).catch((err) => {

      console.log(err);

    })

  }

  useEffect(() => {

    getMenu();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <div>

      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>

      <div className="px-3 md:px-8 h-auto -mt-24">

        <div className="container mx-auto max-w-full">

          <div className="grid grid-cols-1 px-4 mb-16">

            <Card>

              <CardHeader color="blueGray" contentPosition="left">

                <div className='flex justify-between ' style={{ width: '900px' }}>

                  <h2 className="text-white text-2xl">Add Slide Banner</h2>

                </div>

              </CardHeader>

              <CardBody>
              <div className="flex w-72 flex-col gap-6">
                <Input 
                  variant="outlined" 
                  label="Outlined"
                  placeholder="Title"
                  onChange={(event) => {

                    setTitle(event.target.value);
                  }}
                />
              </div>

                  <div className='mb-1'>

                  <label>

                    Image: *.jpg, *.png, *.webp are accepted.

                  </label>

                  <br />

                  <label>

                    Size: 600x400 px.

                  </label>

                  </div>

                  <br />

                  <input type="file" name="file" onChange={fileHandler} />

                  <br />

                  <Button 
                    className="mt-5"
                    onClick={submitHandle}
                  >
                    {loading ? 'Loading...' : 'Save'}
                  </Button>

              </CardBody>

            </Card>

          </div>

        </div >

      </div >

    </div >

  )

}

export default CreateSlideshowBanner;
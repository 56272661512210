import React, { useContext, useEffect, useState } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { Button, Input, Textarea } from '@material-tailwind/react';
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import axiosInstance from '../../../config/axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import SelectRequired from '../../../components/RequiredSelect';
const PromotionalBannerNew = () => {
    const { state, setState } = useContext(RootContext)
    const token = localStorage.getItem("access_token");
    const [isSubmit, setIsSubmit] = useState(false)
    const history = useHistory()
    const group = JSON.parse(localStorage.getItem("group"))
    const [value, setValue] = useState({
    })
    const [promoGroup, setPromoGroup] = useState()

    const getPromoGroup = () => {
        if (!token) {
            history.push("/login");
        } else {
            try {
                axiosInstance("get_site_promo", {
                    method: "POST",
                    data: {
                        token: token,
                        group: group.wbid
                    },
                })
                    .then((res) => {
                        setPromoGroup(res.data.data.list.map((data) => {
                            return {
                                label: data.gnam,
                                value: data.idp
                            }
                        }))
                        setState({
                            ...state,
                            group: res.data.data.additional_data.group,
                            menu: refactorStructureData(res.data.data.additional_data.menu),
                        });
                        if (!localStorage.getItem("group")) {
                            localStorage.setItem("group", JSON.stringify(res.data.data.additional_data.group[0]))
                        }
                    })
                    .catch((err) => {


                    });

            } catch (error) {
                console.log("ERROR: ", error);
            }
        }
    };

    useEffect(() => {
        getPromoGroup()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const handleSubmit = (event) => {
        setIsSubmit(true)
        var bodyFormData = new FormData()
        bodyFormData.append("token", token)
        bodyFormData.append('group', group.wbid)
        bodyFormData.append('idp', value.idp)
        bodyFormData.append('ordering', value.ordering)
        bodyFormData.append('title', value.title)
        bodyFormData.append('description', value.description)
        bodyFormData.append('url_to', value.url_to)
        bodyFormData.append('banner_img', value.banner_img)
        axiosInstance.post('create_site_promo_content', bodyFormData).then((res) => {
            history.push('/web_management/cms/promotional_banner')
            Swal.fire('Success', 'data is saved', 'success')
        }).catch((err) => {
            console.log(err.response)
            setIsSubmit(false)
            Swal.fire("Your file is not image", 'Please choose .jpg .png file', 'error')
        })
        event.preventDefault()
    }

    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <Card>
                            <CardHeader color="blueGray" contentPosition="left">
                                <h2 className="text-white text-2xl">Create Promotinal Banner</h2>
                            </CardHeader>
                            <CardBody >
                                <form onSubmit={handleSubmit} className="flex flex-col gap-4">

                                    <SelectRequired options={promoGroup}
                                        onChange={(event) => {
                                            setValue({ ...value, idp: event.value })
                                        }}
                                        placeholder="Promo Group"
                                        required
                                    />
                                    <Input
                                        type="number"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Ordering banner"
                                        onChange={(event) => setValue({ ...value, ordering: event.target.value })}
                                        required
                                    />
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Title"
                                        onChange={(event) => setValue({ ...value, title: event.target.value })}
                                        required
                                    />
                                    <Textarea
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Description"
                                        onChange={(event) => setValue({ ...value, description: event.target.value })}
                                        required
                                    />
                                    <Input
                                        type="file"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Banner Image"
                                        onChange={(event) => setValue({ ...value, banner_img: event.target.files[0] })}

                                        required
                                    />
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Another Link"
                                        onChange={(event) => {
                                            setValue({ ...value, url_to: event.target.value })
                                        }}
                                        required
                                    />
                                    <Button disabled={isSubmit}>
                                        {isSubmit ? "Loading..." : "Save"}
                                    </Button>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromotionalBannerNew;
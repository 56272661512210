
import DataTable from "react-data-table-component";
// import { Button, Card, CardBody, CardHeader, Input, Label } from "@material-tailwind/react";
import { Button, Card, CardBody, CardHeader, Input } from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import axiosInstance from '../config/axios';
import Swal from "sweetalert2";
import refactorStructureData from "../helpers/refactorDataMenu";
import { RootContext } from "../App";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
export default function TableAccessRight() {
  const [access , setAccess] = useState([])
  const [accessName , setAccessName] = useState()
  const {id} = useParams()
  const [pending , setPending] = useState(true)
  const token = localStorage.getItem("access_token")
  const group = JSON.parse(localStorage.getItem("group"))
  const {state , setState} = useContext(RootContext)
  const [length , setLength] = useState(10)
  const [updateAccess , setUpdateAccess] = useState([])
  useEffect(()=>{
    axiosInstance.post('get_access_right_detail' , {
      token :token ,
      group :group.wbid,
      role_id : id,
      lgth : length
    }).then((res)=>{
    const data = res.data.data
      setPending(false)
      setAccess(data.list)
        setAccessName({
            id_role:data.adv_search[0]?.rlid,
            access_name : data.adv_search[0]?.acna
        })
      setState({
        ...state,
        group: res.data.data.additional_data.group,
        menu: refactorStructureData(res.data.data.additional_data.menu),
      })
    })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[length])
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      width:"80px"
    },
    {
      name:"Create",
      selector:(row , index)=>{
        return (
          <input type={"checkbox"} 
          checked={row.crea}
          onClick={(event)=>{
            let permission 
            if(!event.target.checked && row.crea){
                permission = 0
              }else{
                permission = 1
              }
            const value_before = access[index]
       
            access[index] = {...value_before ,crea:permission}
          
            setAccess([...access ])
            setUpdateAccess([...updateAccess , access[index]])
          }} />
        )
      },
      center:true
    },
    {
      name:"View",
      selector:(row , index)=>{
        return (
          <input type={"checkbox"} 
          checked={row.view}
          onClick={(event)=>{
            let permission 
            if(!event.target.checked && row.view){
                permission = 0
              }else{
                permission = 1
              }
              
            const value_before = access[index]
          
            access[index] = {...value_before ,view:permission}
            setAccess([...access ])
            setUpdateAccess([...updateAccess , access[index]])

          }} 
          />
        )
      },
      center:true
    },
    {
      name:"Update",
      selector:(row , index)=>{
        return (
          <input type={"checkbox"} 
          checked={row.updt}

          onClick={(event)=>{
            let permission 
            if(!event.target.checked && row.updt){
                permission = 0
              }else{
                permission = 1
              }
            const value_before = access[index]
          
            access[index] = {...value_before ,updt:permission}
            setAccess([...access ])
            setUpdateAccess([...updateAccess , access[index]])

          }} 
          />
        )
      },
      center:true
    },
    {
      name:"Delete",
      selector:(row , index)=>{
        return (
          <input type={"checkbox"} 
          checked={row.delt}
          onClick={(event)=>{
            let permission 
            if(!event.target.checked && row.delt){
                permission = 0
              }else{
                permission = 1
              }
            const value_before = access[index]
       
            access[index] = {...value_before   ,delt:permission}
            setAccess([...access ])
            setUpdateAccess([...updateAccess , access[index]])

          }} 
          />
        )
      },
      center:true
    },
    {
      name:"All ",
      selector:(row , index)=>{
        return (
          <input type={"checkbox"} 
          onClick={(event)=>{
            let permission 
            if(event.target.checked){
              permission = 1
            }else{
              permission = 0
            }
            const value_before = access[index]
            access[index] = { ...value_before , menu_id:row.meid ,delt:permission ,crea : permission , updt : permission  , view : permission }
            setAccess(access)
            setUpdateAccess([...updateAccess , access[index]])

          }} 
          />
        )
      },
      center:true
    },
    ]

  return (
    <Card>
    <CardHeader color="blueGray" contentPosition="left">
      <h2 className="text-white text-2xl">Access Right Detail</h2>
    </CardHeader>
    <CardBody >
      <div className='flex gap-4 align-items-center'>
      <form className='py-2 flex flex-col gap-4 ' onSubmit={(event)=>{
          event.preventDefault()
          
        
   
          axiosInstance.post('update_access_right' , {
              token : token ,
              group : group.wbid,
              role_id:accessName.id_role,
              access_name:accessName.access_name,
                  access : updateAccess.map((access)=>{
                  return {
                      id : access.id,
                      view : access.view,
                      add : access.crea,
                      edit : access.updt,
                      delete : access.delt
                  }
              })
          }).then((res)=>{
              // console.log(res)
              Swal.fire('Update access' , 'Update access is success' , 'success')
          }).catch((err)=>{
              console.log(err.response)
              Swal.fire('Failed' , 'Update access is failed' , 'error')
  
          })
        }} >
          <div>
              <label>Access Name</label>
              <Input  type="text"
                color="lightBlue"
                size="regular"
                outline={true}placeholder='Name' 
                defaultValue={accessName?.access_name}
                disabled
              />
          </div>
          <div className='flex  justify-center'>
          <Button >Save</Button>
          </div>
        </form>
        <div className='w-full'>
        <DataTable
                columns={columns}
                data={access}
                progressPending={pending}
                pagination
                onChangeRowsPerPage={(row)=>{
                    setLength(row)
                }}
                paginationRowsPerPageOptions={[10, 50, 100, 500]}
            />
         
        </div>
      </div>
    
    </CardBody>
    </Card>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
// import { Button, Checkbox, Input, Textarea } from '@material-tailwind/react';
import { Button, Input } from '@material-tailwind/react';
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import axiosInstance from '../../../config/axios'
// import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import SelectRequired from '../../../components/RequiredSelect';
import Loading from '../../../components/Loading';
const NewUserList = () => {
    const { state, setState } = useContext(RootContext)
    const token = localStorage.getItem("access_token");
    // const [isSubmit, setIsSubmit] = useState(false);
    const [isSubmit] = useState(false);
    // const [changePassword , setChangePassword] = useState(true);
    const [groupList , setGroupList] = useState()
    const [roleList , setRoleList] = useState()
    const history = useHistory()
    const group = JSON.parse(localStorage.getItem("group"))
    const [value, setValue] = useState(null)

    const getRoleList = () => {
        axiosInstance.post('get_admin_role', {
            token: token,
            group: group.wbid
        }).then((res) => {
            setRoleList(res.data.data.map((data) => {
                return { label: data.access_name, value: data.role_id }
            }))
        })
    }

    const getUserList = () => {
        axiosInstance("get_admin_detail", {
            method: "POST",
            data: {
                token: token,
                group: group.wbid,
                id: 1
            },
        })
            .then((res) => {
                const data = res.data.data.list[0]
                let group_weid = data.group_web.map((data)=>{
                    return {
                        label:data.gnam,
                        value:data.weid,
                    }
                }) 
                // const access = role.find((rol)=>rol.label === data.accs)
                setGroupList(res.data.data.adv_search)
                // setValue({...data , actv:data.stat ,  group: group_weid ,accs:access?.value })
                setValue({...data , actv:data.stat ,  group: group_weid })
                setState({
                    ...state,
                    group: res.data.data.additional_data.group,
                    menu: refactorStructureData(res.data.data.additional_data.menu),
                })
                if (!localStorage.getItem("group")) {
                    localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
                }
            })
            .catch((err) => {
                console.log(err)
            });


    };

    useEffect(() => {
        getRoleList()
        getUserList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        let data = {
            token:token,
            group:group.wbid,
            adm_id:-1,
            username : value?.username,
            group_web: value.group.map((data)=>data.value),
            role_id:value.accs,
            allow_ip:value.alip,
            status:value.actv
        }
        if(value.password){
            data = {...data , password:value.password}
        }
        axiosInstance.post("set_admin_list" , data).then((res)=>{
            // console.log(res)

            Swal.fire("Your data saved" , 'success update user' ,'success')
            history.push("/web_management/users/user_list")
        }).catch((err)=>{
            console.log(err.response)
        })
    }



    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            {value ? (
                <div className="px-3 md:px-8 h-auto -mt-24">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 px-4 mb-16">
                            <Card>
                                <CardHeader color="blueGray" contentPosition="left">
                                    <h2 className="text-white text-2xl">Set admin list</h2>
                                </CardHeader>
                                <CardBody >
                                    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                                        <Input
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Username"
                                            onChange={(event) => setValue({ ...value, username:event.target.value, name: event.target.value })}
                                        />
                                        <Input
                                            type="password"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Password"
                                            onChange={(event) => {
                                              setValue({...value , password:event.target.value})
                                            }}
                                            required
                                        />
                                   
                                     
                                        <SelectRequired
                                            options={groupList?.map((data)=>{
                                                return {
                                                    label:data.group_name,
                                                    value : data.web_id
                                                }
                                            })}
                                            onChange={(event)=>{
                                                setValue({...value , group:event})
                                            }}
                                            isMulti
                                            placeholder="Group web"
                                        />

                                        <Input
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Allow IP"
                                            required
                                            onChange={(event) => {
                                                setValue({ ...value, alip: event.target.value })
                                            }}
                                        />
                                     
                                        <SelectRequired
                                        options={roleList}
                                        onChange={(event)=>{
                                            setValue({...value , accs:event.value})
                                        }}
                                        placeholder="Role"
                                        />
                                   
                                        
                                         <SelectRequired
                                            onChange={(event) => { setValue({ ...value, actv: event.value }) }}
                                            options={[{ label: 'Active  ', value: 1 }, { label: 'NonActive', value: 0 }]}
                                            mutiple
                                            required
                                            placeholder="Status"
                                        />
                                        <Button disabled={isSubmit}>Save</Button>
                                    </form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            ) : <Loading />}
        </div>
    )
}

export default NewUserList;
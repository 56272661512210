import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import { Button } from "@material-tailwind/react";

export default function TableDrawResult({ data, pending, rollback, settings }) {

    const columns = [
        {
            name: "Unit",
            selector: (row) => row.name,
            width: "10rem",
            sortable: true,
        },
        {
            name: "Open/Close",
            selector: (row) => <p>{row.open}/{row.close}</p>,
            sortable: true,
        },
        {
            name: "Active Days",
            selector: (row) => {

                let stringUtama = "|";

                if(row.senin == 1){

                    stringUtama += " Mon |";
                }

                if(row.selasa == 1){

                    stringUtama += " Tue |";
                }

                if(row.rabu == 1){

                    stringUtama += " Wed |";
                }

                if(row.kamis == 1){

                    stringUtama += " Thu |";
                }

                if(row.jumat == 1){

                    stringUtama += " Fri |";
                }

                if(row.sabtu == 1){

                    stringUtama += " Sat |";
                }

                if(row.minggu == 1){

                    stringUtama += " Sun |";
                }

                return stringUtama;
            },
            width: "18rem",
            sortable: true,
        },
        {
            name: "4D/3D/2D",
            selector: (row) => {

                if(row.fourd == 1){

                    return "Active"
                }else{

                    return "Disabled"
                }
            },
            sortable: true,
        },
        {
            name: "Colok Bebas",
            selector: (row) => {

                if(row.colokbebas == 1){

                    return "Active"
                }else{

                    return "Disabled"
                }
            },
            sortable: true,
        },
        {
            name: "Shio",
            selector: (row) => {

                if(row.shio === 1){

                    return "Active"
                }else{

                    return "Disabled"
                }
            },
            sortable: true,
        },
        {
            name: "Edit Unit",
            selector: (row) =>

                <Button onClick={() => settings(row.name) }>
                    Edit
                </Button>,
            sortable: true,
        },
    ];

    return (
        <>
            <Card>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={pending}
                    />
                </CardBody>
            </Card>
        </>
    );
}


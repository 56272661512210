import H1 from "@material-tailwind/react/Heading1";

export default function Error404() {
    return (
        <>
            <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto"/>

            <div className="px-3 md:px-8 h-auto -mt-28 mb-16">
               <H1 color="blueGray">404</H1>
               <H1 color="blueGray">Page Not Found</H1>
            </div>
        </>
    );
}

import React, { useContext, useEffect, useState } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import Textarea from "@material-tailwind/react/Textarea";
import axiosInstance from '../../config/axios';
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
// import Select from "react-select";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
import SelectRequired from "../../components/RequiredSelect";
const AdjustmentRequestNew = () => {

    const [isSubmit, setIsSubmit] = useState(false)
    const history = useHistory()
    const { state, setState } = useContext(RootContext)
    const [data, setData] = useState({
        username: null,
        amount: null,
        description: null,
        wallet_to: null,
        mut_type: null
    })

    const verifyToken = () => {
        const token = localStorage.getItem("access_token");
        if (!token) {
            history.push("/login");
        } else {
            try {
                axiosInstance("verify_token", {
                    method: "POST",
                    data: {
                        token: token,
                    },
                })
                    .then((res) => {
                        setState({
                            ...state,
                            group: res.data.data.group,
                            menu: refactorStructureData(res.data.data.menu),
                        });
                        if (!localStorage.getItem("group")) {
                            localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
                        }
                    })
                    .catch((err) => {

                        localStorage.removeItem("access_token");
                        history.push("/login");
                    });

            } catch (error) {
                console.log("ERROR: ", error);
            }
        }
    };

    const submit = (event) => {

        const token = localStorage.getItem("access_token");

        const group = JSON.parse(localStorage.getItem("group"))

        setIsSubmit(true)

        axiosInstance.post('create_adjustment_request', {
            token: token,
            group: group.wbid,
            ...data
        }).then((res) => {
            setIsSubmit(false)
            Swal.fire('Request Adjustment is saved', 'Success', 'success')
            history.push('/money_management/adjustment_request')
        }).catch((err) => {
            setIsSubmit(false)
            console.log()
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.data.mut_type[0],
            })
        })
        event.preventDefault()
    }

    useEffect(() => {
        verifyToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <Card>
                            <CardHeader color="blueGray" contentPosition="left">
                                <h2 className="text-white text-2xl">Manual Adjustment</h2>
                            </CardHeader>
                            <CardBody  >
                                <form onSubmit={submit} className="flex flex-col gap-4">
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Username"
                                        onChange={(event) => {
                                            const state = { ...data, username: event.target.value }
                                            setData(state)
                                        }}
                                        required
                                    />
                                    <Input
                                        type="number"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="amount"
                                        required
                                        onChange={(event) => {
                                            const state = { ...data, amount: event.target.value }
                                            setData(state)
                                        }}

                                    />
                                    <Textarea
                                        type="textarea"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Description"
                                        onChange={(event) => {
                                            const state = { ...data, description: event.target.value }
                                            setData(state)
                                        }}

                                    />
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Wallet to"
                                        required
                                        onChange={(event) => {
                                            const state = { ...data, wallet_to: event.target.value }
                                            setData(state)
                                        }}

                                    />
                                    <SelectRequired required options={[
                                        { value: 1, label: "Credit" },
                                        { value: 0, label: "Debit" }
                                    ]} onChange={(event) => {
                                        const state = { ...data, mut_type: event.value }
                                        setData(state)
                                    }}
                                        placeholder={"Type"}
                                        isReq
                                    />
                                    <Button
                                        color="lightBlue"
                                        buttonType="filled"
                                        size="regular"
                                        rounded={false}
                                        block={false}
                                        iconOnly={false}
                                        ripple="light"
                                        disabled={isSubmit}
                                    >
                                        {isSubmit ? "Loading" : "Save"}
                                    </Button>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdjustmentRequestNew;

import React, { useContext, useState } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import TableRegistrationBankList from "../../components/TableRegistrationBankList";
import Button from "@material-tailwind/react/Button";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useEffect } from "react";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const RegistrationBankList = () => {

  const [data, setData] = useState()
  const [pending, setPending] = useState(true)
  const history = useHistory()
  const { state, setState } = useContext(RootContext)
  const [length, setLength] = useState()
  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length])

  const getData = () => {

    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"))
    const data = {
      token: token,
      group: group.wbid,
      lgth: length
    }
    if (!token) {
      history.push("/login");
    } else {
      try {

        axiosInstance("get_bank_main", {
          method: "POST",
          data,
        })
          .then((res) => {
            const data = res.data.data.list
            setData(data)
            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });
          })
          .then(() => {
            setPending(false);
          })
          .catch((err) => {
            localStorage.removeItem("access_token");
            history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };


  return (
    <div>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Registration Bank List</h2>
              </CardHeader>
              <CardBody>
                <Link to="/banking/register_bank">
                  <Button className="mb-4">Register Bank</Button>
                </Link>
                <TableRegistrationBankList pending={pending} data={data}
                  onChangeRowsPerPage={(row) => {
                    setLength(row)
                  }}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationBankList;

// import { Label } from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import { Button } from "@material-tailwind/react";
export default function TableBonus({ data, pending, loading, handleDelete, onChangeRowsPerPage }) {
    
    const columns = [
        {
            name: "Name",
            selector: (row) => row.username,
            sortable: true,
            width: '200px'
        },
       
       
        {
            name: 'Action',
            selector: (row) => (
                <Button
                color="red"
                buttonType="filled"
                size="sm"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="light"
                onClick={() => {
                    handleDelete(row.uid);
                }}
                >
                {loading ? 'Loading...' : 'Delete'}
                </Button>
            ),
            sortable: true,
        },
        
    ];


    return (
        <>

            <DataTable
                columns={columns}
                data={data}
                progressPending={pending}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
                pagination
            />
        </>
    );
}

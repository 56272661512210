import React, { useContext, useEffect, useState } from "react";
import TableProviderWinLose from "components/TableProviderWinLose";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import refactorStructureData from "../../helpers/refactorDataMenu";
import { RootContext } from '../../App'

export default function PLPLProvider() {
  const history = useHistory();
  const [listPLProvider, setlistPLProvider] = useState();
  const [pending, setPending] = useState(true);
  const [provider, setProvider] = useState(null)
  const [length, setLength] = useState(100)
  const { state, setState } = useContext(RootContext)


  const debounced = useDebouncedCallback(
    (action) => {
      action()
    },
    200
  );

  useEffect(() => {

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    provider,
    length,
  ]);

  const getData = () => {

    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"))
    const data = {
      token: token,
      group: group.wbid,
      provider: provider,
      lgth: length,
    }
    if (!token) {
      history.push("/login");
    } else {
      try {

        axiosInstance("winlose_provider", {
          method: "POST",
          data,
        })
          .then((res) => {
            // console.log(res)
            const array = res.data.data.list;

            const result = [];

            array.forEach((item) => {
                const found = result.find((r) => r.vendor === item.vendor);
                if (found) {
                    found.stake += item.stake;
                    found.wl += item.wl;
                } else {
                  result.push(item);
                }
            });
            let arr = result;
            
            for (let i = 0; i < arr.length; i++) {
              let element = arr[i];
              // Ganti nama key jadi turnover dan menang_kalah
              element['turnover'] = element['stake'];
              element['menang_kalah'] = element['stake'] - element['wl'];
              delete element['stake'];
              delete element['wl'];
            }
            // console.log(arr);

            // const data = res.data.data.list
            const data = arr;

            setlistPLProvider(data)
            setPending(false);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

          })
          .then(() => {
          })
          .catch((err) => {
            // localStorage.removeItem("access_token");
            // history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };


  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableProviderWinLose
              title="Player List"
              listPLProvider={listPLProvider}
              pending={pending}

              providerEvent={(event) => {

                debounced(() => setProvider(event.target.value))

              }}
              
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setLength(currentRowsPerPage)
              }}
              
            />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
// import TableDepositRequset from "../../components/TableDepositRequest";
import axiosInstance from "../../config/axios";
// import { useHistory } from "react-router-dom";
import TableDepositRequestHistoryDetail from "../../components/TableDepositRequestHistoryDetail";
// import TableDepositRequestHistoryDetail from "../../components/TableDepositRequestHistoryDetail";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";


export default function DepositRequestDetail() {

    const [data, setData] = useState();
    const [pending, setPending] = useState(true);
    const {state , setState} = useContext(RootContext)
    const history = useHistory()
    const params = useParams()

    useEffect(() => {

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"));

    const getData = () => {

        const data = {
            token: token,
            group: group.wbid,
            date: params.date,
        }

        if (!token) {

            history.push("/login");
            //console.log("no valid token");

        } else {
            try {

                console.log("Get data axio");

                axiosInstance("get_deposit_mutation_detail", {
                    method: "POST",
                    data,
                })
                    .then((res) => {

                        console.log("Respon dari server");

                        const array = res.data.data.list;

                        setData(array);

                        setState({
                            ...state,
                            group: res.data.data.additional_data.group,
                            menu: refactorStructureData(res.data.data.additional_data.menu),
                            
                          });

                    })
                    .then(() => {

                        setPending(false);
                    })
                    .catch((err) => {

                        //localStorage.removeItem("access_token");
                        //history.push("/login");

                        console.log("error get deposit history data");

                    });
            } catch (error) {

                console.log("ERROR: ", error);
            }
        }
    };

    return (
        <>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableDepositRequestHistoryDetail
                            data={data}
                            pending={pending}
                            date={params.date}
                        />
                       
                    </div>
                </div>
            </div>
        </>
    );
}

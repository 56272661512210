/*
* komponen ini menggunakan material tailwind, jika ingin mengubah atau menambah
* lihat halaman ini sebagai referensi
* https://www.material-tailwind.com/docs/react/installation
*
*/

import { RootContext } from '../../App';
import axiosInstance from '../../config/axios';
import refactorStructureData from '../../helpers/refactorDataMenu';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import TableUnit from '../../components/TableUnit';

export default function LotteryUnit() {

    const [optionValue, setOptionValue] = useState();
    const [listData, setListData] = useState();
    const [saving, setSaving] = useState(true);
    const { state, setState } = useContext(RootContext);
    const history = useHistory();
    const group = JSON.parse(localStorage.getItem("group"));

    const handleRollback = (rollid) => {

        console.log("Handle Rollback Id: " + rollid);
    }

    const handleSettings = (settingsid) => {

        console.log("Handle Settings Id: " + settingsid);
        history.push('/lottery/unit/' + settingsid);
    }

    const getData = () => {

        const token = localStorage.getItem("access_token");

        const data = {

            token: token,
            group: group.wbid,

        }

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            try {

                axiosInstance('/lottery/get-unit', {

                    method: "POST",

                    data,

                }).then((res) => {

                    const data = res.data.data.list;

                    setListData(data);

                    setState({

                        ...state,

                        group: res.data.data.additional_data.group,

                        menu: refactorStructureData(res.data.data.additional_data.menu),

                    });

                    setSaving(false);

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        )

                        setSaving(false);

                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                )

            }

        }

    };

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>

            <Card>

                <CardHeader color="blueGray" contentPosition="none">

                    <div className="w-full flex items-center justify-between">

                        <h2 className="text-white text-2xl">Lottery Unit</h2>

                    </div>
                </CardHeader>
            </Card>

            <TableUnit
                data={listData}
                pending={saving}
                rollback={handleRollback}
                settings={handleSettings}
            />

        </>

    );

}

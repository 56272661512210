import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
export default function TablePromotionalGroup({ data, pending, onChangeRowsPerPage, createPromoGroup, updatePromoGroup }) {


    const columns = [
        {
            name: "#",
            selector: (row) => row.idp,
        },
        {
            name: "Promo Group Name",
            selector: (row) => row.gnam
        },
        {
            name: "",
            selector: (row) => (
                <div className="flex gap-4">
                    <Button
                        color="green"
                        buttonType="filled"
                        size="sm"
                        rounded={false}
                        block={false}
                        iconOnly={false}
                        ripple="light"
                        onClick={(event) => {
                            event.preventDefault();
                            Swal.fire({
                                title: 'Update your Name Promo Group',
                                input: 'text',
                                inputAttributes: {
                                    autocapitalize: 'off'
                                },
                                inputValue: row.gnam,
                                showCancelButton: true,
                                confirmButtonText: 'Save',
                                showLoaderOnConfirm: true,
                                preConfirm: (value) => {
                                    updatePromoGroup(value, row.idp)
                                },
                                allowOutsideClick: () => !Swal.isLoading()
                            })
                        }}

                    >
                        Update
                    </Button>
                </div>
            )
        }
    ];




    return (
        <Card>
            <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Promotional Group</h2>
            </CardHeader>
            <CardBody>
                <div className="flex justify-between">
                    <div className="mb-6 flex gap-4">
                        <Link to="/web_management/cms/promotional_banner_new">
                            <Button
                                color="lightBlue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                                onClick={(event) => {
                                    event.preventDefault();
                                    Swal.fire({
                                        title: 'Input your Name Promo Group',
                                        input: 'text',
                                        inputAttributes: {
                                            autocapitalize: 'off'
                                        },
                                        showCancelButton: true,
                                        confirmButtonText: 'Save',
                                        showLoaderOnConfirm: true,
                                        preConfirm: (value) => {
                                            createPromoGroup(value)
                                        },
                                        allowOutsideClick: () => !Swal.isLoading()
                                    })
                                }}
                            >
                                Add Promotinal Group
                            </Button>
                        </Link>
                        <Link to="/web_management/cms/promotional_group">
                            <Button
                                color="lime"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                            >
                                List Promotinal Banner
                            </Button>
                        </Link>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={pending}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    pagination
                />
            </CardBody>
        </Card>
    );
}

/*
* komponen ini menggunakan material tailwind, jika ingin mengubah atau menambah
* lihat halaman ini sebagai referensi
* https://www.material-tailwind.com/docs/react/installation
*
*/

import { RootContext } from '../../../App';
import axiosInstance from '../../../config/axios';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import Image from "@material-tailwind/react/Image";
import { Textarea } from '@material-tailwind/react';

export default function SiteConfig() {

    const [listData, setListData] = useState();
    const [newData, setNewData] = useState([]);
    const { state, setState } = useContext(RootContext);
    const history = useHistory();
    const [saving, setSaving] = useState();
    const group = JSON.parse(localStorage.getItem("group"));

    const handleChangeImage = () => {

        history.push('/web_management/cms/update_web_logo');
    }

    const handleSave = () => {

        const token = localStorage.getItem("access_token");

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            try {

                axiosInstance('update_site_config', {

                    method: "POST",

                    data: newData,

                }).then((res) => {

                    localStorage.setItem("access_token", res.data.data.token);

                    if(res.data.code === 0) {

                        Swal.fire(
                            'Saved',
                            'Data Saved',
                            'success',
                        );

                        setSaving(false);
                    }else{

                        Swal.fire(
                            'Failed',
                            'Failed To save',
                            'error',
                        );

                        setSaving(false);
                        console.log('Error Mesage: '+res.data.message);
                    }

                }).catch((err) => {

                    let errstatus = err.response.status;
    
                    let errmesage = err.response.data.message;
        
                    if(errstatus === 401){
        
                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {
        
                            console.log("Token Expire 401");
                            localStorage.removeItem("access_token");
        
                            history.push("/login");
                        });
        
                    }else{
        
                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        ).then(() => {
        
                            setSaving(false);
        
                        })
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                ).then(() => {

                    setSaving(false);

                })

            }

        }
    }

    const getData = () => {

        const token = localStorage.getItem("access_token");

        const data = {

            token: token,
            group: group.wbid,
        }

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            try {

                axiosInstance('get_site_config', {

                    method: "POST",

                    data,

                }).then((res) => {

                    const data = res.data.data.list;

                    localStorage.setItem("access_token", res.data.data.token);

                    setListData(data);

                    setNewData({...newData, token: res.data.data.token, group: group.wbid})

                    setState({

                        ...state,

                        group: res.data.data.additional_data.group,

                        menu: refactorStructureData(res.data.data.additional_data.menu),

                    });

                }).catch((err) => {

                    let errstatus = err.response.status;
    
                    let errmesage = err.response.data.message;
        
                    if(errstatus === 401){
        
                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {
        
                            localStorage.removeItem("access_token");
        
                            history.push("/login");

                            console.log("2Token Expire 401");
                        });
        
                    }else{
        
                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        ).then(() => {
        
                            setSaving(false);
        
                        })
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                ).then(() => {

                    setSaving(false);

                })

            }

        }

    };

    useEffect(() => {

        getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!listData ? (

            <div className="flex justify-center" style={{ marginTop: 150 }}>

                <ReactLoading
                    type="spinningBubbles"
                    color="rgb(53, 126, 221)"
                    height={100}
                    width={100}
                />

            </div>

            ) : (

            <Card>

                <CardHeader color="blueGray" contentPosition="none">

                    <div className="w-full flex items-center justify-between">

                        <h2 className="text-white text-2xl">Site Config</h2>

                        <Button
                            className="h-10
                                    px-5 m-2
                                    text-green-100 
                                    transition-colors 
                                    duration-150 
                                    bg-green-700 
                                    rounded-lg 
                                    focus:shadow-outline 
                                    hover:bg-green-800"
                            onClick={() => {

                                setSaving(true);

                                handleSave();

                            }}
                            
                        >
                            {!saving ? 'Save' : 'Saving'}
                        
                        </Button>

                    </div>

                </CardHeader>

                <CardBody>

                    <div className="flex justify-center items-center">

                        <div className="h-64 w-64 rounded-lg" >

                            <Image
                                src={listData['weblogo']}
                                alt="logo image" 
                                onClick={handleChangeImage}
                            />

                            <p>[Click Image To Change]</p>

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Web Name

                    </h6>

                    <div className="flex flex-wrap mt-10">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input
                                type="text"
                                color="purple"
                                placeholder=" "
                                defaultValue={listData['webname']}
                                onChange={(e) => {

                                    setNewData({...newData, webname: e.target.value})
                                }}
                            />

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Contact

                    </h6>

                    <div className="flex flex-wrap mt-10">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input
                                type="text"
                                color="purple"
                                placeholder=" "
                                defaultValue={listData['contact']}
                                onChange={(e) => {

                                    setNewData({...newData, contact: e.target.value})
                                }}
                            />

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Web Livechat

                    </h6>

                    <div className="flex flex-wrap mt-10">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input
                                type="text"
                                color="purple"
                                placeholder= " "
                                defaultValue={listData['weblivechat']}
                                onChange={(e) => {

                                    setNewData({...newData, weblivechat: e.target.value})
                                }}
                            />

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Web Livechat Link

                    </h6>

                    <div className="flex flex-wrap mt-10">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input
                                type="text"
                                color="purple"
                                placeholder= " "
                                defaultValue={listData['weblivechatlink']}
                                onChange={(e) => {

                                    setNewData({...newData, weblivechatlink: e.target.value})
                                }}
                            />

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Global Meta Tag

                    </h6>

                    <div className="flex flex-wrap mt-10 font-light">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Textarea 
                                color="purple" 
                                placeholder=" "
                                defaultValue={listData['globalmetatag']}
                                onChange={(e) => {

                                    setNewData({...newData, globalmetatag: e.target.value})
                                }}
                            />

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Web Running Text

                    </h6>

                    <div className="flex flex-wrap mt-10 font-light">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input 
                                color="purple" 
                                placeholder=" "
                                defaultValue={listData['webruningtext']}
                                onChange={(e) => {

                                    setNewData({...newData, webruningtext: e.target.value})
                                }}
                            />

                        </div>

                    </div>

                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Google Console Code

                    </h6>

                    <div className="flex flex-wrap mt-10 font-light">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Textarea 
                                color="purple" 
                                placeholder=" "
                                defaultValue={listData['googleconsolecode']}
                                onChange={(e) => {

                                    setNewData({...newData, googleconsolecode: e.target.value})
                                }}
                            />

                        </div>

                    </div>

                </CardBody>

            </Card>

            )}

        </>

    );

}

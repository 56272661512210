import { useRef } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function CardTable({ playerReferral, pending, usernameEvent, onChangeRowsPerPage }) {

  const columns = [
    {
      name: "Username",
      selector: (row, idx) => row.name,
      sortable: true,
    },
    {
      name: "Total refferal",
      selector: (row) => row.tref,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => {
        return (
          <Link
            to={"/player_management/referral_detail/"+row.name}>
            <Button
              color="lightBlue"
              type="button"
              ripple="light"
              size="regular"
            >
              Cek Referal
            </Button>
          </Link>

        )
      }
    }

  ];

  return (
    <div>
      <div>
        <Card>
          <h5>Advanced Search</h5>
          <CardBody>
            <div className="flex gap-4">
              <InputIcon
                type="text"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="Username"
                iconName="search"
                onChange={usernameEvent}
              />
            </div>
          </CardBody>
        </Card>
        <Card className="mt-12">
          <CardHeader color="blueGray" contentPosition="left">
            <h2 className="text-white text-2xl">Player Referral</h2>
          </CardHeader>
          <CardBody >
            <DataTable
              columns={columns}
              data={playerReferral}
              progressPending={pending}
              onChangeRowsPerPage={onChangeRowsPerPage}
              paginationRowsPerPageOptions={[10, 50, 100, 500]}
              pagination
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

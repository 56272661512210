import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from "use-debounce";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
import TableCashbackHistoryDetail from "../../components/TableCashbackHistoryDetail";

export default function CashbackHistoryDetail() {

  const history = useHistory();
  const [data, setData] = useState();
  const [pending, setPending] = useState(true);
  const [username, setUsername] = useState();
  const { state, setState } = useContext(RootContext);

  const param = useParams();

  const debounced = useDebouncedCallback(

    (value) => {

      setUsername(value);
    },
    200
  );

  const getData = () => {

    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"));

    const data = {
      token: token,
      group: group.wbid,
      date: param.date,
      username: username,
    }

    if (!token) {

      history.push("/login");
    } else {

      try {

        axiosInstance("get_cashback_history_detail", {
          method: "POST",
          data,
        })
          .then((res) => {

            const data = res.data.data.list;

            setData(data);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

          })
          .then(() => {

            setPending(false);
          })
          .catch((err) => {

            localStorage.removeItem("access_token");
            history.push("/login");
          });
      } catch (error) {

        console.log("ERROR: ", error);
      }
    }
  };

  useEffect(() => {

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableCashbackHistoryDetail
              data={data}
              pending={pending}
              date={param.date}
              usernameEvent={(event) => {

                debounced(event.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
import { Label, Button } from "@material-tailwind/react";
// import { IconButton } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
export default function CardTable({ playerMutation, pending, usernameEvent, fromCreateAtEvent, toCreateAtEvent, onChangeRowsPerPage }) {
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: '80px',
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.user,
      sortable: true,
    },
    {
      name: "Shorcut",
      center: true,
      selector: (row) => {
        return (
          <Link to={"/player_detail/" + row.user} >
            <Button data-tip data-for="registerTip">
              <i className=" fas fa-eye" />
            </Button>

            <ReactTooltip id="registerTip" place="bottom" effect="solid">
              Detail Player
            </ReactTooltip>
          </Link >

        );

      }
    },
    {
      name: "Description",
      selector: (row) => row.desc,
      sortable: true,
    },
    {
      name: "Tipe",
      selector: (row) => {
        return Number(row.type) === 1 ? (
          <Label color="red">Debit</Label>
        ) : Number(row.type) === 0 && (
          <Label color="green">Credit</Label>
        )
      },
      center: true,
      sortable: true,
    },


    {
      name: "Amount",
      selector: (row) => <tt>{row.amnt}</tt>,
      sortable: true,
      right: true,
    },
    {
      name: "New Balance",
      selector: (row) => <tt>{row.nbal}</tt>,
      sortable: true,
      right: true,
    },

  ];


  return (
    <>
      <Card>
        <h5>Advanced Search</h5>
        <CardBody>
          <div className="flex gap-4">
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Username"
              iconName="search"
              onChange={usernameEvent}
            />
          </div>
          <div className="flex mt-4">
            <div style={{ width: '100%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '20px' }}>
              <p>Created Date</p>
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="From"
                iconName=""
                onChange={fromCreateAtEvent}
              />
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="To"
                iconName=""
                onChange={toCreateAtEvent}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card className="mt-12">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Player Mutation</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={playerMutation}
            progressPending={pending}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            pagination
          />
        </CardBody>
      </Card>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../config/axios";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import TableWithdrawRequestDetailUser from "../../components/TableWithdrawRequestDetailUser";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";

export default function WithdrawRequestDetailUser() {

    const params = useParams();
    const [data, setData] = useState();
    const [pending, setPending] = useState(true);
    const { state, setState } = useContext(RootContext);
    const history = useHistory();
    const username = params.user;

    const getData = () => {

        const token = localStorage.getItem("access_token");

        const group = JSON.parse(localStorage.getItem("group"));

        const data = {
            token: token,
            group: group.wbid,
            user: username,
        }
        if (!token) {

            history.push("/login");
        } else {
            try {

                axiosInstance("get_withdraw_detail_user", {
                    method: "POST",
                    data,
                })
                    .then((res) => {

                        const array = res.data.data.list;

                        setData(array);

                        setState({
                            ...state,
                            group: res.data.data.additional_data.group,
                            menu: refactorStructureData(res.data.data.additional_data.menu),
                        });
                    })
                    .then(() => {

                        setPending(false);
                    })
                    .catch((err) => {

                        localStorage.removeItem("access_token");
                        history.push("/login");
                    });
            } catch (error) {

                console.log("ERROR: ", error);
            }
        }
    };

    useEffect(() => {

        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableWithdrawRequestDetailUser
                            data={data}
                            pending={pending}
                            user={username}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useContext, useState, useEffect } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
// import Textarea from "@material-tailwind/react/Textarea";
import axiosInstance from '../../config/axios'
import Swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
import SelectRequired from "../../components/RequiredSelect";
// import { InputIcon } from "@material-tailwind/react";
import Select from "react-select";



const DepositRequestNew = () => {
    const [newDescription, setNewDescription] = useState('');
    const [isSubmit, setIsSubmit] = useState(false)
    const history = useHistory()
    const { state, setState } = useContext(RootContext)
    const [optionBank, setOptionBank] = useState([])
    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"))
    const [bankAccountSearch, setBankAccountSearch] = useState()
    const optionsNewDescription = [

        { 
            value: 'Bonus Roling',
            label: 'Bonus Roling',
        },

        { 
            value: 'Manual Deposit',

            label: 'Manual Deposit',
        },

        { 
            value: 'Promo New Member',

            label: 'Promo New Member',
        },

        { 
            value: 'Cashback Kekalahan',

            label: 'Cashback Kekalahan',
        },

      ];
    const [data, setData] = useState({
        user: null,
        amount: null,
        description: null,
        percentage: null,
        bid: null,
        receiver_bank: null,
        receiver_name: null,
        bnam: null,
        receiver_num: null,
    })

    const verifyToken = () => {
        const token = localStorage.getItem("access_token");
        if (!token) {
            history.push("/login");
        } else {
            try {
                axiosInstance("verify_token", {
                    method: "POST",
                    data: {
                        token: token,
                    },
                })
                    .then((res) => {
                        setState({
                            ...state,
                            group: res.data.data.group,
                            menu: refactorStructureData(res.data.data.menu),
                        });
                        if (!localStorage.getItem("group")) {
                            localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
                        }
                    })
                    .catch((err) => {

                        localStorage.removeItem("access_token");
                        history.push("/login");
                    });

            } catch (error) {
                console.log("ERROR: ", error);
            }
        }
    };

    const handleNewDescriptionChange = (selectedOption) => {

        const state = { ...data, description:selectedOption.value };

        setData(state);

        setNewDescription(selectedOption.value);

      };

    const listBankAccount = () => {
        axiosInstance.post('get_bank_account', {
            token: token,
            group: group.wbid,
            bacc: bankAccountSearch,
            lgth: 50
        }).then((res) => {
            setOptionBank(res.data.data.list.map((data) => {
                return {
                    label: data.bnam + " " + data.bnum + " A/N " + data.bacc,
                    value: data.bacc,
                    ...data
                }
            }))
        }).catch((err) => {

        })
    }






    const submit = (event) => {

        setIsSubmit(true)

        axiosInstance.post('create_deposit_request', {
            token: token,
            group: group.wbid,
            ...data
        }).then((res) => {
            setIsSubmit(false)
            switch (res.data.code) {
                case 203:
                    Swal.fire(res.data.message, 'Player not found', 'error')
                    break
                case 402:
                    Swal.fire(res.data.message, 'Player has pending', 'warning')
                    break
                case 200:
                    Swal.fire('Request deposit is created', 'Success', 'success')
                    history.push('/money_management/deposit_request')
                    break
                default:
                    Swal.fire('Request deposit is created', 'Success', 'success')
                    history.push('/money_management/deposit_request')
            }



        }).catch((err) => {
            setIsSubmit(false)
            console.log(err.response)
            Swal.fire(err.response.data.data)
        })

        event.preventDefault()


    }

    useEffect(() => {
        verifyToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    useEffect(() => {
        listBankAccount()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankAccountSearch])






    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <Card>
                            <CardHeader color="blueGray" contentPosition="left">
                                <div className='flex justify-between ' style={{ width: '900px' }}>
                                    <h2 className="text-white text-2xl">Manual Deposit</h2>
                                    <Link to={"/money_management/deposit_request"}>
                                        <Button
                                        >Back</Button>
                                    </Link>

                                </div>
                            </CardHeader>
                            <CardBody  >
                                <form onSubmit={submit} className="flex flex-col gap-4">
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Username"
                                        required
                                        onChange={(event) => {
                                            const state = { ...data, user: event.target.value }
                                            setData(state)
                                        }}
                                    />
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="amount"
                                        required
                                        onChange={(event) => {
                                            const state = { ...data, amount: event.target.value }
                                            setData(state)
                                        }}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}

                                    />
                                    {/* <Textarea
                                        type="textarea"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Description"
                                        onChange={(event) => {
                                            const state = { ...data, description: event.target.value }
                                            setData(state)
                                        }}

                                    /> */}

                                    <Select
                                        options={optionsNewDescription}
                                        onChange={handleNewDescriptionChange}
                                        value={optionsNewDescription.find((option) => option.value === newDescription)}
                                        placeholder="Select Type Depo"
                                        />
                                    <SelectRequired placeholder="Receiver Bank" isSearchable required options={optionBank}
                                        onChange={(event) => {
                                            const state = {
                                                ...data,
                                                receiver_bank: event.bnam,
                                                bid: event.id,
                                                receiver_name: event.value,
                                                receiver_num: event.bnum,
                                                percentage: event.perc,
                                                ...event

                                            }
                                            setData(state)
                                        }}
                                        onInputChange={(value) => {
                                            setBankAccountSearch(value)
                                        }}
                                    />

                                    <Button
                                        color="lightBlue"
                                        buttonType="filled"
                                        size="regular"
                                        rounded={false}
                                        block={false}
                                        iconOnly={false}
                                        ripple="light"
                                        disabled={isSubmit}
                                    >
                                        {isSubmit ? "Loading" : "Save"}r
                                    </Button>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DepositRequestNew;

import React, { useContext, useState } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
// import TableRegistrationBankList from "../../components/TableRegistrationBankList";
// import Button from "@material-tailwind/react/Button";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useEffect } from "react";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
import TableGroupBank from "../../components/TableGroupBank";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "@material-tailwind/react";

const BankGroup = () => {

  const formUpdateMinMaxInit = {
    id: '',
    min_depo: 0,
    max_depo: 0,
  }

  const [data, setData] = useState()
  const [pending, setPending] = useState(true)
  const history = useHistory()
  const token = localStorage.getItem("access_token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formUpdateMinMax, setformUpdateMinMax] = useState(formUpdateMinMaxInit);
  const [loading, setLoading] = useState(false);
  const { state, setState } = useContext(RootContext)

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = () => {

    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"))
    const data = {
      token: token,
      group: group.wbid,
    }
    if (!token) {
      history.push("/login");
    } else {
      try {

        axiosInstance("get_bank_group", {
          method: "POST",
          data,
        })
          .then((res) => {
            const data = res.data.data.list
            setData(data)
            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });
          })
          .then(() => {
            setPending(false);
          })
          .catch((err) => {
            localStorage.removeItem("access_token");
            history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };


  const formatNum = (str) => {
    return +str.replace(/\./g, '').replace(/,/g, '.')
  }

  const handleModal = (isOpen, data) => {
    setIsModalOpen(isOpen);
    setformUpdateMinMax((state) => ({
      ...state,
      id: data.id,
      min_depo: formatNum(data.mide),
      max_depo: formatNum(data.made),
    }))
  }

  
    const handleOnChange = (e) => {
      const { name, value } = e.target;
      setformUpdateMinMax({
        ...formUpdateMinMax,
        [name]: value,
        // amount: formUpdateMinMax.current_coin + formatNum(value)
      });
    };
    
  const group = JSON.parse(localStorage.getItem("group"))
  const handleOnSubmit = () => {
    setLoading(true);
    try {
      axiosInstance("update_minmax_depo", {
        method: "POST",
        data: {
          token: token,
          group: group.wbid,
          bg_id: formUpdateMinMax.id,
          min_depo: formUpdateMinMax.min_depo,
          max_depo: formUpdateMinMax.max_depo,
        },
      })
        .then((res) => {
          getData();
          setLoading(false);
          setIsModalOpen(false);
          setformUpdateMinMax(formUpdateMinMaxInit)
        })
        .catch((err) => {
          // console.log('Error', err)
          setLoading(false);
          setIsModalOpen(false);
          setformUpdateMinMax(formUpdateMinMaxInit)
        });
    } catch (error) {
      setLoading(false);
      setIsModalOpen(false);
      setformUpdateMinMax(formUpdateMinMaxInit)
      // console.log('error => ', error);
    }
  };


  return (
    <div>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Bank Group List</h2>
              </CardHeader>
              <CardBody>
                <TableGroupBank 
                pending={pending} 
                data={data}
                handleModal={handleModal} 
                />
                <Modal
                  size="sm"
                  active={isModalOpen}
                  toggler={() => setIsModalOpen(false)}
                >
                  <ModalHeader toggler={() => setIsModalOpen(false)}>
                    Update Bank Group
                  </ModalHeader>
                  <ModalBody>
                    <div className='flex flex-col space-y-4 w-72'>
                      <Input
                        type="number"
                        name="min_depo"
                        className="block w-full"
                        color="lightBlue"
                        size="regular"
                        outline={true}
                        placeholder="Minimal Deposit"
                        value={formUpdateMinMax.min_depo}
                        onChange={(e) => handleOnChange(e)}
                      />
                      <Input
                        type="number"
                        name="max_depo"
                        className="block w-full"
                        color="lightBlue"
                        size="regular"
                        outline={true}
                        placeholder="Maximal Deposit"
                        value={formUpdateMinMax.max_depo}
                        onChange={(e) => handleOnChange(e)}
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="red"
                      buttonType="link"
                      onClick={() => setIsModalOpen(false)}
                      ripple="dark"
                    >
                      Cancel
                    </Button>

                    <Button
                      color="green"
                      onClick={() => handleOnSubmit()}
                      ripple="light"
                    >
                      {loading ? 'Loading...' : 'Update'}
                    </Button>
                  </ModalFooter>
                </Modal>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankGroup;

import { Label } from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
export default function TableBankAccount({ data, pending, onChangeRowsPerPage, handleModalDelete }) {
    const columns = [
        {
            name: "ID",
            width: "60px",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Group Bank",
            selector: (row) => row.grup+" - " +row.main,
            sortable: true,
            width: '200px'
        },
       
        {
            name: "Bank Name",
            selector: (row) => <div className="d-flex flex-column text-center">
                <p>{row.bnam}</p>  {row.btyp === 1 ? <Label color={"green"}>Deposit</Label> : <Label color={"blue"}>Withdraw</Label>}
            </div>,
            sortable: true,
        },

        {
            name: "Active",
            selector: (row) => (row.actv === 1 ? <Label color={"green"}>Active</Label> : <Label color={"red"}>Non Active</Label>),
            sortable: true,
            width: '120px'
        },
        {
            name: "Percentage",
            selector: (row) => row.perc + "%",
            sortable: true
        },
        {
            name: "Limit Current",
            selector: (row) => row.lcur,
            sortable: true
        },
        {
            name: "Limit Receive",
            selector: (row) => row.lrec,
            sortable: true
        },
        {
            name: "",
            selector: (row) => {
                return (
                    <Link to={`/banking/update_bank_account/${row.id}`}>
                        <Button
                            color="yellow"
                            buttonType="filled"
                            size="sm"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="light"
                        >Update</Button>
                    </Link>
                )
            },
            sortable: true,
            width: '120px'

        },
        {
            name: 'Action',
            selector: (row) => (
              <Button
                color="red"
                buttonType="filled"
                size="sm"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="light"
                onClick={() => {
                  handleModalDelete(true, row);
                }}
              >
                Delete
              </Button>
            ),
            sortable: true,
          },
    ];


    return (
        <>

            <DataTable
                columns={columns}
                data={data}
                progressPending={pending}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationRowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
                pagination
            />
        </>
    );
}

import React from "react";

import Card from "@material-tailwind/react/Card";

import CardHeader from "@material-tailwind/react/CardHeader";

import CardBody from "@material-tailwind/react/CardBody";

import { Button } from "@material-tailwind/react";

import LogReport from "../../components/LogReport";

const TestPage = () => {

    const data = {

        nama: "zukino",
        desc: "test"
    };

    const respon = {

        status: 200,
        data: "respon success",
    };

  return (
    <div>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Test Page</h2>
              </CardHeader>
              <CardBody>
                <div className="flex flex-wrap -mx-3 mb-20 mt-10">
                    <div className="w-1/2 xl:w-1/4 px-2">
                        <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
                            <div className="text-gray-700">
                                <Button
                                    onClick={() => {

                                        LogReport(TestPage.name, data, respon, "accept deposit failed");
                                    }}
                                    >
                                    Test Log
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestPage;

import React, { useContext, useEffect, useState } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
// import { Button, Input, Textarea } from '@material-tailwind/react';
import { Button, Input } from '@material-tailwind/react';
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import axiosInstance from '../../../config/axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import SelectRequired from '../../../components/RequiredSelect';
const ContactUsNew = () => {
    const [isSubmit, setIsSubmit] = useState(false)
    const [value, setValue] = useState({

    })
    const group = JSON.parse(localStorage.getItem("group"))
    const history = useHistory()
    const token = localStorage.getItem("access_token");
    const { state, setState } = useContext(RootContext)


    const verifyToken = () => {
        if (!token) {
            history.push("/login");
        } else {
            try {
                axiosInstance("verify_token", {
                    method: "POST",
                    data: {
                        token: token,
                        group: group.wbid
                    },
                })
                    .then((res) => {
                        setState({
                            ...state,
                            group: res.data.data.group,
                            menu: refactorStructureData(res.data.data.menu),
                        });
                        if (!localStorage.getItem("group")) {
                            localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
                        }
                    })
                    .catch((err) => {

                        localStorage.removeItem("access_token");
                        history.push("/login");
                    });

            } catch (error) {
                console.log("ERROR: ", error);
            }
        }
    };

    useEffect(() => {
        verifyToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleSubmit = (event) => {
        setIsSubmit(true)
        var bodyFormData = new FormData()
        bodyFormData.append("token", token)
        bodyFormData.append('group', group.wbid)
        bodyFormData.append('app_name', value.app_name)
        bodyFormData.append('icon_url', value.icon_url)
        bodyFormData.append('isactive', value.isactive)
        bodyFormData.append('value', value.value)
        axiosInstance.post('create_site_contact', bodyFormData).then((res) => {
            history.push('/web_management/cms/contact_us')
        }).catch((err) => {
            console.log(err.response)
            Swal.fire("Your format image is wrong", 'Please choose .jpg .png file', 'error')
        })
        event.preventDefault()
    }

    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <Card>
                            <CardHeader color="blueGray" contentPosition="left">
                                <h2 className="text-white text-2xl">Create Contact</h2>
                            </CardHeader>
                            <CardBody >
                                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="App Name"
                                        onChange={(event) => setValue({ ...value, app_name: event.target.value })}
                                        required
                                    />
                                    <Input
                                        type="file"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Icon URL"
                                        onChange={(event) => {
                                            setValue({ ...value, icon_url: event.target.files[0] })
                                        }}
                                        required
                                    />
                                    <SelectRequired
                                        onChange={(event) => { setValue({ ...value, isactive: event.value }) }}
                                        options={[{ label: 'active', value: 1 }, { label: 'disactive', value: 0 }]} required />

                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Value"
                                        onChange={(event) => {
                                            setValue({ ...value, value: event.target.value })
                                        }}
                                        required
                                    />
                                    <Button disabled={isSubmit}>Save</Button>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsNew;
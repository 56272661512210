import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { Label, Button } from "@material-tailwind/react";

export default function TableDepositRequestDetail({ data, pending, date }) {

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            width: '80px',
            sortable: true
        },
        {
            name: "Username",
            selector: (row) => row.unam,
            sortable: true,
        },
        {
            name: "Akun",
            selector: (row) => <div className="d-flex flex-column gap-2">
                <p>{row.rban + " " + row.rnam + "-" + row.rnum}</p>
                <p>Note : {row.desc}</p>
            </div>,
            sortable: true,
            width: '200px'
        },
        {
            name: "Receiver Bank",
            selector: (row) => row.rban + " " + row.rnam + "-" + row.rnum,
            sortable: true,
            width: '180px'
        },
        {
            name: "Sender Bank",
            selector: (row) => row.sban + " " + row.snam + "-" + row.snum,
            sortable: true,
            width: '180px'
        },
        {
            name: "Amount",
            selector: (row) => row.amou,
            sortable: true,
            right: true,
        },

        {
            name: "Status",
            selector: (row) => (row.isap === 0 ? <Label color={"red"}>Decline</Label> : row.isap === 1 ? <Label color={"green"}>Approved</Label> : <Label color={"amber"}>Decline</Label>),
            sortable: true,
        },
        
        {
            name: "By",
            selector: (row) => <tt>{row.agen}</tt>,
            sortable: true,
            right: true,
        },
        {
            name: "Request date",
            selector: (row) => row.rdat,
            sortable: true,
            width: "180px"
        },
        {
            name: "Proccess Date",
            selector: (row) => row.pdat,
            sortable: true,
            width: '180px'

        },
    ];

    const handleExport = (dataJson) => {
    const header = '"username","sender_bank","sender_name","sender_rek","amount","date_and_time","status","agent","receiver_bank","receiver_name","receiver_rek",\n';
    const csvData = dataJson.map(d => `"${d.unam}","${d.sban}","${d.snam}","${d.snum}","${d.amou}","${d.rdat}","${d.isap === 1 ? 'approve' : 'decline'}","${d.agen}","${d.rban}","${d.rnam}","${d.rnum}",\n`).join('');
    const blob = new Blob([header + csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'deposit-'+date+'.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    };

    return (
        <>
            <Card className="mt-12">
                <CardHeader color="blueGray" contentPosition="left">
                    <h2 className="text-white text-2xl">Deposit Transaction</h2>
                    <p>{date}</p>
                </CardHeader>
                <CardBody>
                <Button onClick={() => handleExport(data)}>Export To CSV</Button>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={pending}
                    />
                </CardBody>
            </Card>
        </>
    );
}


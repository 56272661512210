import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";


export default function CardTable({ listPLProvider,
  pending,
  providerEvent,
  onChangeRowsPerPage,
}) {
  const filteredItems = listPLProvider;


  const columns = [
    {
      name: "#",
      width: "60px",
      selector: (row, index) => index+1,
      sortable: true,
    },
    {
        name: "provider",
        width: '150px',
        selector: (row, index) => row.vendor,
        sortable: true,
      },
      {
        name: "TurnOver",
        width: '150px',
        selector: (row, index) => row.turnover.toLocaleString(),
        sortable: true,
      },
      {
        name: "Provider Menang / Kalah",
        width: '180px',
        selector: (row, index) => <tt><font color={row.menang_kalah < 0 ? "red" : "black"}>{row.menang_kalah.toLocaleString()}</font></tt>,
        sortable: true,
        right: true
      },
  ];

  return (
    <>
      <Card>
        <h5>Advanced Search</h5>
        <CardBody>
          <div className="flex gap-2">
          <div style={{ width: '25%' }}>
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Provider"
              iconName="search"
              onChange={providerEvent}
            />
            </div>
             {/* <div style={{ width: '25%' }}>
              <Select options={optionsLevel.map((option) => {
                return {
                  value: option.leid,
                  label: option.name
                }
              })
              }
                onChange={levelEvent}
                placeholder="Level" />
            </div> */}
            
          </div>
        
        </CardBody>
      </Card>
      <Card className="mt-10">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">PL Provider</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={filteredItems}
            progressPending={pending}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination
          />
        </CardBody>
      </Card >
    </>
  );
}

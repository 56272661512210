import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import axiosInstance from "../config/axios";
import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";

function PlayerTotalWinlose({ username }) {

    const [dataWinlose, setDataWinlose] = useState([]);
    const [pending, setPending] = useState(false);
    const [to, setTo] = useState(0);
    const [wl, setWl] = useState(0);
    const [newData, setNewData] = useState([]);
    const [fromdate, setFromDate] = useState(null);
    const [todate, setToDate] = useState(null);
    const group = JSON.parse(localStorage.getItem("group"));
    const token = localStorage.getItem("access_token");

    useEffect(() => {

        //getWinlose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getWinlose() {

        const data = {
            token: token,
            group: group.wbid,
            username: username
        }

        try {

            axiosInstance('winlose_player_detail', {

                method: "POST",

                data,

            }).then((res) => {

            setNewData({...newData, data})
            setDataWinlose(res.data.data.list);
            setTo(res.data.data.add_data.total_to);
            setWl(res.data.data.add_data.total_wl);
            setPending(false);
            })

        } catch (error) {

            console.error(error);
        }
    };

    function getwinloseFromDate() {

        const data = {
            token: token,
            group: group.wbid,
            username: username,
            fromdate: fromdate,
            todate: todate
        }

        try {

            axiosInstance('winlose_player_detail', {

                method: "POST",

                data

            }).then((res) => {

            setDataWinlose(res.data.data.list);
            setTo(res.data.data.add_data.total_to);
            setWl(res.data.data.add_data.total_wl);
            setPending(false);
            })

        } catch (error) {

            console.error(error);
        }
    }

    const columns = [
        {
            name: 'Provider',
            selector: row => row.provider,
            width: '120px',
            sortable: true,
        },
        {
            name: 'Turnover',
            selector: row => row.turnover_formated,
            sortable: true,
        },
        {
            name: 'Winlose',
            selector: row => row.winlose_formated,
            sortable: true,
        },
    ];

    return (
        <>
            <Card className="mt-12">
                <CardHeader size="sm" color="blueGray" contentPosition="left">
                    <h2 className="text-white text-1xl">Winlose</h2>
                </CardHeader>
                <CardBody>
                    <div className="flex flex-row mb-3">
                        <InputIcon
                            type="date"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="From Date"
                            iconName=""
                            onChange={(event) => {
                                setFromDate(event.target.value);
                            }}
                        />
                        <InputIcon
                            type="date"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="To"
                            iconName=""
                            onChange={(event) => {
                                setToDate(event.target.value);
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <Button
                            color="lightBlue"
                            buttonType="filled"
                            size="sm"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="light"
                            onClick={() => {

                                setPending(true);
                                getwinloseFromDate();
                            }}
                            >
                            Filter
                        </Button>
                    </div>
                    <div>
                        <div className="font-bold mb-1">
                            Total Turnover
                        </div>
                        <div className="pl-2 mb-2">
                            {to}
                        </div>
                    </div>
                    <div>
                        <div className="font-bold mb-1">
                            Total Winlose
                        </div>
                        <div className="pl-2 mb-2">
                            {wl}
                        </div>
                    </div>
                    <DataTable columns={columns} data={dataWinlose} progressPending={pending}/>
                </CardBody>
            </Card>
        </>
    )
}

export default PlayerTotalWinlose
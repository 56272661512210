import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import PlayerInformationForm from "../../components/PlayerInformationForm";
import PlayerTransactionForm from "../../components/PlayerTransactionForm";
import PlayerTotalTransactionForm from "../../components/PlayerTotalTransactionForm";
import PlayerBankInformationForm from "../../components/PlayerBankInformationForm";
import PlayerLastLoginForm from "../../components/PlayerLastLoginForm";
import PlayerWalletForm from "../../components/PlayerWalletForm";
import PlayerSchorcutForm from "../../components/PlayerShorcutForm";
import PlayerStatus from "../../components/PlayerStatusForm";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
import { useDebouncedCallback } from 'use-debounce';
import InputIcon from "@material-tailwind/react/InputIcon";
import { useHistory } from "react-router-dom";
import PlayerTotalWinlose from "../../components/PlayerTotalWinlose";

const DetailPlayer = () => {

  const history = useHistory();
  const params = useParams();
  const [data, setData] = useState();
  const [datawallet, setDatawallet] = useState();
  const { state, setState } = useContext(RootContext);

  const debounced = useDebouncedCallback(
    (action) => {
      action()
    },
    800
  );

  function userEvent(event){

    let url = '/player_detail/'+event.target.value;

    debounced(async () => {

      history.push(url);
      window.location.reload(false);
    });
  }

  function getDataUser() {

    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"));

    axiosInstance("get_user_det", {

      method: "POST",
      data: {
        token: token,
        group: group.wbid,
        user: params.id,
      },

    }).then((res) => {

      setState({
        ...state,
        group: res.data.data.additional_data.group,
        menu: refactorStructureData(res.data.data.additional_data.menu),
      });

      setData(res.data.data.list[0]);
      setDatawallet(res.data.data.list);

    });
  }

  useEffect(() => {

    getDataUser();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!data ? (
        <Loading />
      ) : (
        <div>
          <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
          <div className="px-3 md:px-8 h-auto -mt-24">
          <div className="mb-12 bg-white rounded-lg p-2 max-w-xs ml-4">
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Username"
              iconName="search"
              onChange={userEvent}
            />
            </div>
            <div className="container">
              <div className="gap-4 mt-8" style={{ 'display': 'flex' }}>
                <div style={{ width: '52%' }}>
                  <PlayerInformationForm data={data} />
                  <PlayerBankInformationForm data={data}  />
                  <PlayerLastLoginForm data={data} />
                </div>
                <div style={{ width: '50%', height: '100vh' }}>
                  <PlayerWalletForm data={datawallet} />
                  <PlayerTotalTransactionForm data={data} />
                  <PlayerTotalWinlose username={params.id} />
                </div>
                <div style={{ width: '35%' }} >
                  <PlayerSchorcutForm dataPlayer={data} />
                  <PlayerTransactionForm data={data} />
                  <PlayerStatus data={data} />
                </div>
              </div>
              <div className="gap-4 mt-8" style={{ 'display': 'flex' }}>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailPlayer;

import React, { useContext, useEffect, useState } from 'react'
import { RootContext } from '../../../App'
import TablePromotionalBanner from '../../../components/TablePromotionalBanner'
import axiosInstance from '../../../config/axios'
import refactorStructureData from '../../../helpers/refactorDataMenu'
import Swal from 'sweetalert2'
function PromotinalBanner() {
  const token = localStorage.getItem("access_token")
  const group = JSON.parse(localStorage.getItem("group"))
  const [data, setData] = useState([])
  const { state, setState } = useContext(RootContext)
  const [isLoading, setIsLoading] = useState(true)
  const [length, setLength] = useState(10)

  const getAllPromotionalBanner = () => {
    axiosInstance.post('get_site_promo_content', {
      lgth: length,
      token: token,
      group: group.wbid,
    }).then((res) => {
      const list_data = []
      setIsLoading(false)
      res.data.data.list.forEach((groupbanner) => {
        groupbanner.lico.forEach((promo) => {
          console.log()
          list_data.push({ ...promo, ...groupbanner })
        })
      })
      setData(list_data)

      setState({
        ...state,
        group: res.data.data.additional_data.group,
        menu: refactorStructureData(res.data.data.additional_data.menu),
      })
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    getAllPromotionalBanner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length])

  const deleteAction = (id) => {
    const formData = new FormData()
    formData.append("token", token)
    formData.append("group", group.wbid)
    formData.append("id", id)

    axiosInstance.post("delete_site_promo_content", formData).then((res) => {
      Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
      getAllPromotionalBanner()
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TablePromotionalBanner
              data={data} pending={isLoading}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setLength(currentRowsPerPage)
              }}
              updateAction={() => {
                getAllPromotionalBanner()
              }}
              deleteAction={deleteAction}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PromotinalBanner
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
// import axiosInstance from "../config/axios";
// import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Label } from "@material-tailwind/react";
export default function TableContactUs({ data, pending }) {


    const columns = [
        {
            name: "#",
            selector: (row) => row.id
        },
        {
            name: "Name",
            selector: (row) => row.name
        },
        {
            name: "Icon URL",
            selector: (row) => row.iurl
        },
        {
            name: "Active",
            selector: (row) => row.actv === 1 ? <Label color={"green"} >Active</Label> : <Label color={"red"}>Non active</Label>
        },
        {
            name: "User u",
            selector: (row) => row.usru
        },
        {
            name: "User i",
            selector: (row) => row.usri
        },
        {
            name: '',
            selector: (row) => {
                return (
                    <Link to={"/web_management/cms/contact_us_update/" + row.id}>
                        <Button color="amber">Update</Button>
                    </Link>
                )
            }
        }
    ];




    return (
        <Card>
            <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Site Config</h2>
            </CardHeader>
            <CardBody>
                <div className="flex justify-between">
                    <div className="mb-6 flex gap-4">
                        <Link to="/web_management/cms/contact_us_new">
                            <Button
                                color="lightBlue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                            >
                                Add Contact
                            </Button>
                        </Link>
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={data}
                    progressPending={pending}
                    pagination
                />
            </CardBody>
        </Card>
    );
}

import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import Label from "@material-tailwind/react/Label";
import InputIcon from "@material-tailwind/react/InputIcon";
import { RootContext } from "../App";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom'
import Select from 'react-select'


export default function CardTable({ listPlayer,
  pending,
  contactEvent,
  usernameEvent,
  activeEvent,
  bankAccountEvent,
  bankNumberEvent,
  maksimalBalanceEvent,
  minimalBalanceEvent,
  fromRegisterDateEvent,
  toRegisterDateEvent,
  fromLoginDateEvent,
  toLoginDateEvent,
  onChangeRowsPerPage,
  optionsLevel,
  levelEvent,
  uplineReferral,
  lastIP,
}) {
  const history = useHistory();
  const filteredItems = listPlayer;
  const username_login = localStorage.getItem("username")
  const columns = [
    {
      name: "#",
      width: "60px",
      selector: (row) => row.uid,
      sortable: true,
    },
    {
      name: "Username",
      width: '150px',
      selector: (row, index) => row.user,
      sortable: true,
    },
    {
      name: "Bank Number",
      width: '150px',
      selector: (row, index) => row.bnum,
      sortable: true,
    },
    {
      name: "Upline",
      width: '150px',
      selector: (row, index) => row.upus,
      sortable: true,
      omit: (username_login === 'master' || username_login === 'aakaudit') ? false : true,
    },
    {
      name: "Balance",
      width: '120px',
      selector: (row, index) => <tt>{row.bala}</tt>,
      sortable: true,
      right: true
    },
    {
      name: "Bank Account",
      width: '180px',
      selector: (row) => row.bacc,
      sortable: true,
      center: true
    },
    {
      name: "Bank Name",
      width: '150px',
      selector: (row, index) => row.bnam,
      sortable: true,
    },
    {
      name: "Last Login",
      width: '170px',
      selector: (row) => {
        const date = new Date(row.lalo);
        const dateOptions = { day: '2-digit', month: 'short', year: 'numeric'};
        const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
        return `${formattedDate} ${formattedTime}`;
      },
      sortable: true,
      right: 'center'
    },
    {
      name: "Last IP",
      width: '170px',
      selector: (row, index) => row.laip,
      sortable: true,
      right: 'center'
    },
    {
      name: "status",
      center: true,
      selector: (row) => {

        return Number(row.stat) === 1 ? (
          <Label color="green">Active</Label>
        ) : Number(row.stat) === 2 ? (
          <Label color="red">Locked</Label>
        ) : Number(row.stat) === 3 ? <Label color={"blueGray"}>Banned</Label>:
        <Label color={"red"}>Inactive</Label>
      }
      ,
      sortable: true,
    },

    {
      name: "",
      selector: (row) => (
        <RootContext.Consumer>
          {({ state }) => {

            return (
              <Link to={"/player_detail/" + row.user}>
                <Button
                  color="lightBlue"
                  buttonType="filled"
                  size="sm"
                  rounded={false}
                  block={false}
                  iconOnly={false}
                  ripple="light"
                  onClick={async () => {
                    history.push(`/player_detail/${row.user}`)
                  }}
                >
                  Detail
                </Button>
              </Link>

            )
          }}
        </RootContext.Consumer>
      ),
      sortable: true,
      width: '150px',
    },
  ];

  const handleExport = (dataJson) => {
    const header = '"username","bank_num","balance","bank_account","bank_name", "phone",\n';
    const csvData = dataJson.map(d => `"${d.user}","${d.bnum}","${d.bala}","${d.bacc}","${d.bnam}",'${d.phon},\n`).join('');
    const blob = new Blob([header + csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'player-list.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <Card>
        <h5>Advanced Search</h5>
        <CardBody>
          <div className="flex gap-4">
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Username"
              iconName="search"
              onChange={usernameEvent}
            />
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Contact"
              iconName="search"
              onChange={contactEvent}
            />
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Bank Account"
              iconName="search"
              onChange={bankAccountEvent}
            />
          </div>
          <div className="flex gap-4 mt-4 " >
            <div style={{ width: '50%' }}>
              <InputIcon
                type="string"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="Bank Number"
                iconName="search"
                onChange={bankNumberEvent}
              />
            </div>
            <div style={{ width: '50%' }}>
              <Select options={optionsLevel.map((option) => {
                return {
                  value: option.leid,
                  label: option.name
                }
              })
              }
                onChange={levelEvent}
                placeholder="Level" />
            </div>
            <div style={{ width: '50%' }}>
              <Select options={[
                {
                  value: null, label: "all",
                }, {
                  value: "1", label: "active",
                }, {
                  value: "00", label: "non active",
                }]}
                placeholder="active"
                onChange={activeEvent}
              />
            </div>
          </div>
          <div className="flex mt-4 gap-4 ">
            <div style={{ width: '50%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '5px' }}>
              <p>Balance</p>
              <InputIcon
                type="number"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="Minimal Balance"
                iconName=""
                onChange={minimalBalanceEvent}
              />
              <InputIcon
                type="number"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="Maksimal Balance"
                iconName=""
                onChange={maksimalBalanceEvent}
              />
            </div>
            <div style={{ width: '50%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '5px' }}>
              <p>Register Date</p>
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="From Date"
                iconName=""
                onChange={fromRegisterDateEvent}
              />
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="To"
                iconName=""
                onChange={toRegisterDateEvent}
              />
            </div>
            <div style={{ width: '50%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '5px' }}>
              <p>Login Date</p>
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="From date"
                iconName=""
                onChange={fromLoginDateEvent}
              />
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="To"
                iconName=""
                onChange={toLoginDateEvent}
              />
            </div>
          </div>
          <div className="flex mt-4 gap-4">
            <div className="flex" style={{ width: '80%'}}>
            {
              ( localStorage.getItem('username') === 'master' || localStorage.getItem('username') === 'aakaudit' )&& 
            <InputIcon
                type="text"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="Referral"
                iconName="search"
                onChange={uplineReferral}
              />
            }
            </div>
            <div className="flex" style={{ width: '80%'}}>
            {
              ( localStorage.getItem('username') === 'master' || localStorage.getItem('username') === 'aakaudit' )&& 
            <InputIcon
                type="text"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="IP"
                iconName="search"
                onChange={lastIP}
              />
            }
            </div>
            <Button onClick={() => handleExport(listPlayer)}>Export To CSV</Button>
          </div>
        </CardBody>
      </Card>
      <Card className="mt-10">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Player List</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={filteredItems}
            progressPending={pending}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination
          />
        </CardBody>
      </Card >
    </>
  );
}

import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
import { Label } from "@material-tailwind/react";
export default function CardTable({ data, pending, onChangeRowsPerPage , senderEvent , receiverEvent , dateFromEvent , dateToEvent }) {
  const columns = [
    {
      name: "No",
      width: "80px",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Sender",
      selector: (row) => row.suse,
      sortable: true,
      width: '150px'
    },
    {
      name: "Receiver",
      selector: (row) => row.ruse,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amou,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => {
        return Number(row.mtyp) === 1 ? (
          <Label color="red">Debit</Label>
        ) : Number(row.mtyp) === 0 && (
          <Label color="green">Credit</Label>
        )
      },
      sortable: true,
    },
    {
      name: "New Balance",
      selector: (row) => row.nbal,
      sortable: true,
    },

    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.desc,
      sortable: true,
      width: '200px',
    },
  ];


  return (
    <>
     <Card>
        <h5>Advanced Search</h5>
        <CardBody>
          <div className="flex gap-4">
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Sender"
              iconName="search"
              onChange={senderEvent}
            />
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Receiver"
              iconName="search"
              onChange={receiverEvent}
            />
      
          </div>
     
          <div className="flex mt-4 gap-4 ">
          
            <div style={{ width: '100%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '5px' }}>
              <p>Date</p>
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="From"
                iconName=""
                onChange={dateToEvent}
              />
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="To"
                iconName=""
                onChange={dateFromEvent}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    <Card className="mt-12">
      <CardHeader color="blueGray" contentPosition="left">
        <h2 className="text-white text-2xl">Coin History</h2>
      </CardHeader>
      <CardBody>
        <div className="flex justify-end">
          <div style={{ width: 250 }}>
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Search"
              iconName="search"
            />
          </div>
        </div>
        <DataTable
          columns={columns}
          data={data}
          progressPending={pending}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationRowsPerPageOptions={[10, 50, 100, 500]}
          pagination
        />
      </CardBody>
    </Card>
    </>
  );
}

import TableAnnoucement from "components/TableAnnoucement";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { RootContext } from "../App";
import refactorStructureData from "../helpers/refactorDataMenu";
import axiosInstance from '../config/axios'
export default function Dashboard() {
  const group = JSON.parse(localStorage.getItem("group"))
  const history = useHistory()
  const token = localStorage.getItem("access_token");
  const { state, setState } = useContext(RootContext)


  const verifyToken = () => {
      if (!token) {
          history.push("/login");
      } else {
          try {
              axiosInstance("verify_token", {
                  method: "POST",
                  data: {
                      token: token,
                      group: group.wbid
                  },
              })
                  .then((res) => {
                      setState({
                          ...state,
                          group: res.data.data.group,
                          menu: refactorStructureData(res.data.data.menu),
                      });
                      if (!localStorage.getItem("group")) {
                          localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
                      }
                  })
                  .catch((err) => {

                      localStorage.removeItem("access_token");
                      history.push("/login");
                  });

          } catch (error) {
              console.log("ERROR: ", error);
          }
      }
  };

  useEffect(() => {
      verifyToken()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableAnnoucement />
          </div>
        </div>
      </div>
    </>
  );
}

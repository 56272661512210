import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";

export default function TableCashbackHistory({
  data,
  pending,
  detail
}) {

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Total Cashback",
      selector: (row) => row.total_amount,
      sortable: true,
    },
    {
      name: "Total Player Lose",
      selector: (row) => row.total_lose,
      sortable: true,
    },
    {
      name: "Detail",
      selector: (row) => (
          <Button
            onClick={(event) => {

              detail(row.date);
            }}
          >
            Detail
          </Button>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      <Card className="mt-12">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Cashback History</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={data}
            progressPending={pending}
            pagination
          />
        </CardBody>
      </Card>
    </>
  );
}

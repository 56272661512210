import { Label } from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import { Button } from "@material-tailwind/react";
export default function TableGroupBank({ data, pending, handleModal }) {
  const columns = [
    {
      name: "ID",
      width: "60px",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => row.actv === 1 ? <Label color={"green"}>Active</Label> : <Label color={"red"}>Non Active</Label>,
      sortable: true,
    },
    {
      name: "Minimal deposit",
      selector: (row) => row.mide,
      sortable: true,
    }, {
      name: "Maximal deposit",
      selector: (row) => row.made,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => {
        return (<Button
          color="lightBlue"
          buttonType="filled"
          size="sm"
          rounded={false}
          block={false}
          iconOnly={false}
          ripple="light"
          onClick={() => {
            handleModal(true, row);
          }}
        >Update</Button>)
      },
      sortable: true,
    },
  ];


  return (
    <DataTable
      columns={columns}
      data={data}
      progressPending={pending}
      pagination
    />
  );
}

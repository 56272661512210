
import DataTable from "react-data-table-component";
// import Button from "@material-tailwind/react/Button";
// import { Link } from "react-router-dom";
// import { Label } from "@material-tailwind/react";
export default function TableUserLog({ data, pending, onChangeRowsPerPage }) {


    const columns = [
        {
            name: "#",
            selector: (row) => row.id,
            width: '80px'
        },
       
        {
            name: "Name",
            selector: (row) => row.name
        },
          
        {
            name: "Activity",
            selector: (row) => row.atvy
        },
        {
            name: "Ip address",
            selector: (row) => row.ip
        },
        {
            name: "Created at",
            selector: (row) => row.crea
        },
    ];




    return (

        <DataTable
            columns={columns}
            data={data}
            progressPending={pending}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination
        />
    );
}

/*
* komponen ini menggunakan material tailwind, jika ingin mengubah atau menambah
* lihat halaman ini sebagai referensi
* https://www.material-tailwind.com/docs/react/installation
*
*/

import { RootContext } from '../../App';
import axiosInstance from '../../config/axios';
import refactorStructureData from '../../helpers/refactorDataMenu';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import TableDrawResult from '../../components/TableDrawResult';
import Select from "react-select";

export default function LotteryCalculate() {

    const [pool1, setPool1] = useState();
    const [optionValue, setOptionValue] = useState();
    const [listData, setListData] = useState([]);
    const [saving, setSaving] = useState(false);
    const { state, setState } = useContext(RootContext);
    const history = useHistory();
    const group = JSON.parse(localStorage.getItem("group"));

    const optionselect = [
        { value: 'singapore', label: 'Singapore' },
        { value: 'hongkong', label: 'Hongkong' },
        { value: 'sydney', label: 'Sydney' },
        { value: 'taiwan', label: 'Taiwan' },
        { value: 'cambodia', label: 'Cambodia' }
    ];

    const handleSave = () => {

        setSaving(true);

        const token = localStorage.getItem("access_token");
        const data = {

            token: token,
            group: group.wbid,
            unit: optionValue,
            number: pool1
        }

        if (!token) {

            history.push("/login");

        } else {

            try {

                axiosInstance('/lottery/calculate', {

                    method: "POST",

                    data,

                }).then((res) => {

                    const rescode = res.data.code;

                    if (rescode === 0) {

                        Swal.fire(
                            'Saved',
                            'Result Saved',
                            'success',
                        ).then(() => {

                            const data = res.data.data.list;

                            setListData(data);

                            setState({

                                ...state,

                                group: res.data.data.additional_data.group,

                                menu: refactorStructureData(res.data.data.additional_data.menu),

                            });

                            setSaving(false);
                        })
                    } else if (rescode === 400) {

                        const resmessage = res.data.message;

                        Swal.fire(
                            'Warning',
                            resmessage,
                            'warning',
                        ).then(() => {

                            setState({

                                ...state,

                                group: res.data.data.additional_data.group,

                                menu: refactorStructureData(res.data.data.additional_data.menu),

                            });

                            setSaving(false);
                        })
                    } else {

                        Swal.fire(
                            'Internal Error',
                            'Failed To save',
                            'error',
                        ).then(() => {

                            setSaving(false);
                        })

                        console.log('Error Mesage: ' + res.data.message);
                    }

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        )
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                ).then(() => {

                    setSaving(false);
                })

            }

        }
    }

    const getData = () => {

        setSaving(true);

        const token = localStorage.getItem("access_token");

        const data = {

            token: token,
            group: group.wbid,
        }

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            try {

                console.log('send request get result');

                axiosInstance('/lottery/get-result', {

                    method: "POST",

                    data,

                }).then((res) => {

                    console.log('get respon result');
                    const data = res.data.data.list;

                    setListData(data);

                    setState({

                        ...state,

                        group: res.data.data.additional_data.group,

                        menu: refactorStructureData(res.data.data.additional_data.menu),

                    });

                    setSaving(false);

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        )
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                )

            }

        }

    };

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>

            <Card>

                <CardHeader color="blueGray" contentPosition="none">

                    <div className="w-full flex items-center justify-between">

                        <h2 className="text-white text-2xl">Calculate</h2>

                    </div>

                </CardHeader>

                <CardBody>
                    <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                        Select a Unit

                    </h6>

                    <div className="flex flex-wrap mt-10 font-light">

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Select
                                onChange={(e) => {

                                    setOptionValue(e.value)
                                }}
                                options={optionselect}

                            />

                        </div>

                        <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                            <Input
                                color="purple"
                                placeholder="Result Number"
                                value={pool1}
                                onChange={(e) => {

                                    setPool1(e.target.value)
                                }}
                            />

                        </div>

                    </div>

                    <div className="w-full flex items-center justify-between">

                        <h2></h2>

                        <Button
                            className="h-10
                                    px-5 m-2
                                    text-green-100 
                                    transition-colors 
                                    duration-150 
                                    bg-green-700 
                                    rounded-lg 
                                    focus:shadow-outline 
                                    hover:bg-green-800"
                            onClick={() => {

                                setSaving(true);
                                handleSave();

                            }}

                        >
                            {!saving ? 'Calculate' : 'Processing'}

                        </Button>

                    </div>

                </CardBody>

            </Card>

            <TableDrawResult
                data={listData}
                pending={saving}
            />

        </>

    );

}

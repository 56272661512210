import React, { useContext, useEffect, useState } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import refactorStructureData from "../../helpers/refactorDataMenu";
import { RootContext } from '../../App'

const PlayerGrowth = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const { state, setState } = useContext(RootContext)

  const getData = () => {
    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"))
    const data = {
      token: token,
      group: group.wbid,
      // user: username,      
      // lgth: length,
    }
    if (!token) {
      history.push("/login");
    } else {
      try {

        axiosInstance("player_grow", {
          method: "POST",
          data,
        })
          .then((res) => {
            // console.log(res);
            setData(res.data.data.list)

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

          })
          .then(() => {
          })
          .catch((err) => {
            // localStorage.removeItem("access_token");
            // history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Player Growth</h2>
              </CardHeader>
              <CardBody>
                <div className="flex flex-wrap -mx-3 mb-20 mt-10">
                <div className="w-1/2 xl:w-1/4 px-2">
                  <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
                  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" id="user-add" className="w-12 h-12 mr-4 text-cool-gray-800 dark:text-cool-gray-200 group-hover:text-purple-600 group-focus:text-purple-600 dark:group-hover:text-purple-50 dark:group-focus:text-purple-50">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path>
                    </svg>
                    <div className="text-gray-700">
                      <p className="font-semibold text-2xl">{data.new_register}</p>
                      <p>New Register</p>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 xl:w-1/4 px-2">
                  <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
                  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" id="user-group" className="w-12 h-12 mr-4 text-cool-gray-800 dark:text-cool-gray-200 group-hover:text-purple-600 group-focus:text-purple-600 dark:group-hover:text-purple-50 dark:group-focus:text-purple-50">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                  </svg>
                    <div className="text-gray-700">
                      <p className="font-semibold text-2xl">{data.active_player}</p>
                      <p>Active Player</p>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 xl:w-1/4 px-2">
                  <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6">
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" id="currency-dollar" className="w-12 h-12 mr-4 text-cool-gray-800 dark:text-cool-gray-200 group-hover:text-purple-600 group-focus:text-purple-600 dark:group-hover:text-purple-50 dark:group-focus:text-purple-50">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <div className="text-gray-700">
                      <p className="font-semibold text-2xl">{data.daily_depo}</p>
                      <p>Daily Depo</p>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 xl:w-1/4 px-2">
                  <div className="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6">
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" id="currency-dollar" className="w-12 h-12 mr-4 text-cool-gray-800 dark:text-cool-gray-200 group-hover:text-purple-600 group-focus:text-purple-600 dark:group-hover:text-purple-50 dark:group-focus:text-purple-50">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <div className="text-gray-700">
                      <p className="font-semibold text-2xl">{data.daily_wd}</p>
                      <p>Daily WD</p>
                    </div>
                  </div>
                </div></div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerGrowth;


import DataTable from "react-data-table-component";
// import { Button, Card, CardBody, CardHeader, Input, Label } from "@material-tailwind/react";
import { Button, Card, CardBody, CardHeader, Input } from "@material-tailwind/react";
// import { useEffect, useState } from "react";
import { useState } from "react";
import axiosInstance from '../config/axios'
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
export default function TableAccessRight({ data, pending }) {
  const [access , setAccess] = useState([])
  const [acceessName , setAccessName] = useState()
  const columns = [
    {
      name: "#",
      selector: (row) => row.meid,
      sortable: true,
      width:"80px"
    },
    {
      name: "Permission",
      selector: (row) => row.mename,
      sortable: true,
    },
    {
      name:"Create",
      selector:(row , index)=>{
        return (
          <input type={"checkbox"} 
          onClick={(event)=>{
            let permission 
            if(!event.target.checked && !access[index]?.add){
              permission = 0
            }else{
              permission = 1
            }
            
            const value_before = access[index]
            if(!value_before?.hasOwnProperty('view') ){
              access[index] = {...value_before , 
                menu_id:row.meid ,
                add:permission , 
                view : 0,
                edit : 0,
                delete : 0

              }
            }else{
              access[index] = {...value_before , menu_id:row.meid ,add:permission}
            }
          
            setAccess(access)
          }} />
        )
      },
      width:"70px",
      center:true
    },
    {
      name:"View",
      selector:(row , index)=>{
        return (
          <input type={"checkbox"} 
          onClick={(event)=>{
            let permission 
            if(!event.target.checked && !access[index]?.view){
              permission = 0
            }else{
              permission = 1
            }
            
            const value_before = access[index]
            if(!value_before?.hasOwnProperty('add') ){
              access[index] = {...value_before , 
                menu_id:row.meid ,
                view:permission , 
                add : 0,
                edit : 0,
                delete : 0

              }
            }else{
              access[index] = {...value_before , menu_id:row.meid ,view:permission}
            }
            setAccess(access)
          }} 
          />
        )
      },
      width:"70px",
      center:true
    },
    {
      name:"Update",
      selector:(row , index)=>{
        return (
          <input type={"checkbox"} 
          onClick={(event)=>{
            let permission 
            if(!event.target.checked && !access[index]?.edit){
              permission = 0
            }else{
              permission = 1
            }
            
            const value_before = access[index]
             if(!value_before?.hasOwnProperty('delete') ){
              access[index] = {...value_before , 
                menu_id:row.meid ,
                edit:permission , 
                view : 0,
                add : 0,
                delete : 0

              }
            }else{
              access[index] = {...value_before , menu_id:row.meid ,edit:permission}
            }
            setAccess(access)
          }} 
          />
        )
      },
      width:"70px",
      center:true
    },
    {
      name:"Delete",
      selector:(row , index)=>{
        return (
          <input type={"checkbox"} 
          checked={row.delete}
          onClick={(event)=>{
            let permission 
            if(!event.target.checked && !access[index]?.delete){
              permission = 0
            }else{
              permission = 1
            }
            
            const value_before = access[index]
            if(!value_before?.hasOwnProperty('edit') ){
              access[index] = {...value_before , 
                menu_id:row.meid ,
                delete:permission , 
                view : 0,
                edit : 0,
                add : 0

              }
            }else{
              access[index] = {...value_before  ,menu_id:row.meid ,delete:permission}
            }
            setAccess(access)
          }} 
          />
        )
      },
      width:"70px",
      center:true
    },
    {
      name:"All ",
      selector:(row , index)=>{
        
        return (
          <input type={"checkbox"} 
          
          
          onClick={(event)=>{
            let permission 
            if(event.target.checked){
              permission = 1
            }else{
              permission = 0
            }
            const value_before = access[index]
            access[index] = { ...value_before , menu_id:row.meid ,delete:permission ,add : permission , edit : permission  , view : permission }
            setAccess(access)
          }} 
          />
        )
      },
      width:"70px",
      center:true
    },
    {
        name: "",
        width: '150px',
        selector: (row) => (
          <Link to={"/web_management/cms/detail_access_right/"+row.meid}>
            <Button buttonType="outline" >
                 <i className="fa-solid fa-pen-to-square"></i>
            </Button>
          </Link>
        )
    }
    ]

  return (
    <Card>
    <CardHeader color="blueGray" contentPosition="left">
      <h2 className="text-white text-2xl">Access Rights</h2>
    </CardHeader>
    <CardBody >
      <div className='flex gap-4 align-items-center'>
        <form className='py-2 flex flex-col gap-4 ' onSubmit={(event)=>{
           axiosInstance.post('create_access_right' ,{
            token : localStorage.getItem("access_token"),
            group : JSON.parse(localStorage.getItem("group")).wbid,
            access_name :acceessName,
            access : access
          }).then((res)=>{
            // console.log(res)
            Swal.fire('Created' , 'Access was created success' ,'success')
          }).catch((err)=>{
            console.log(err.response)
            Swal.fire('Error' , 'Please choose access' ,'error')
          })
          event.preventDefault()
        }} >
          <div>
              <label>Access Name</label>
              <Input  type="text"
                color="lightBlue"
                size="regular"
                outline={true}placeholder='Name' 
                required
                onChange={(event)=>setAccessName(event.target.value)}
              />
          </div>
          <div className='flex  justify-center'>
            <Button className="w-32" >Save</Button>
          </div>
        </form>
        <div className='w-full'>
        <DataTable
                columns={columns}
                data={data}
                progressPending={pending}
                pagination
            />
         
        </div>
      </div>
    </CardBody>
    </Card>
  );
}

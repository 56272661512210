import React, { useContext, useEffect, useState } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { Button, Input, Textarea } from '@material-tailwind/react';
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import axiosInstance from '../../../config/axios'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import SelectRequired from '../../../components/RequiredSelect';
const PromotionalBannerUpdate = () => {
    const { state, setState } = useContext(RootContext)
    const token = localStorage.getItem("access_token");
    const [isSubmit, setIsSubmit] = useState(false)
    const history = useHistory()
    const group = JSON.parse(localStorage.getItem("group"))
    const [value, setValue] = useState({
    })
    const [promoGroup, setPromoGroup] = useState()


    const { id } = useParams()


    const getPromoGroup = () => {
        if (!token) {
            history.push("/login");
        } else {
            try {
                axiosInstance("get_site_promo", {
                    method: "POST",
                    data: {
                        token: token,
                        group: group.wbid
                    },
                })
                    .then((res) => {
                        setPromoGroup(res.data.data.list.map((data) => {
                            return {
                                label: data.gnam,
                                value: data.idp
                            }
                        }))
                    })
                    .catch((err) => {


                    });

            } catch (error) {
                console.log("ERROR: ", error);
            }
        }
    };

    const getPromotionalBanner = () => {
        if (!token) {
            history.push("/login");
        } else {
            try {
                axiosInstance("get_site_promo_content_detail", {
                    method: "POST",
                    data: {
                        token: token,
                        group: group.wbid,
                        id: id
                    },
                })
                    .then((res) => {
                        // console.log(res)
                        setState({
                            ...state,
                            group: res.data.data.additional_data.group,
                            menu: refactorStructureData(res.data.data.additional_data.menu),
                        });

                        if (!localStorage.getItem("group")) {
                            localStorage.setItem("group", JSON.stringify(res.data.data.additional_data.group[0]))
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    });

            } catch (error) {
                console.log("ERROR: ", error);
            }
        }
    };

    useEffect(() => {
        getPromotionalBanner()
        getPromoGroup()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const submit = (event) => {
    //     axiosInstance.post('create_set_config',
    //         {
    //             token: token,
    //             group: 1
    //         }
    //     ).then((res) => {

    //     })
    // }

    const handleSubmit = (event) => {
        setIsSubmit(true)
        var bodyFormData = new FormData()
        bodyFormData.append("token", token)
        bodyFormData.append('group', group.wbid)
        bodyFormData.append('idp', value.idp)
        bodyFormData.append('id', id)
        bodyFormData.append('ordering', value.ordr)
        bodyFormData.append('title', value.titl)
        bodyFormData.append('description', value.desc)
        bodyFormData.append('url_to', value.url)
        if (value.banner_img) {
            bodyFormData.append('banner_img', value.banner_img)
        }
        axiosInstance.post('update_site_promo_content', bodyFormData).then((res) => {
            history.push('/web_management/cms/promotional_banner')
            Swal.fire('Success', 'data is saved', 'success')
        }).catch((err) => {
            console.log(err.response)
            Swal.fire('Failed your input its wrong', 'Please choose .jpg .png file', 'error')
        })
        event.preventDefault()
    }


    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <Card>
                            <CardHeader color="blueGray" contentPosition="left">
                                <h2 className="text-white text-2xl">Update Promotinal Banner</h2>
                            </CardHeader>
                            <CardBody >
                                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                                    <SelectRequired options={promoGroup}
                                        onChange={(event) => {
                                            setValue({ ...value, idp: event.value })
                                        }}
                                        value={promoGroup?.find((data) => data.value === value.idp)}
                                        placeholder="Promo Group"
                                    />
                                    <Input
                                        type="number"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Ordering banner"
                                        defaultValue={value.ordr}
                                        onChange={(event) => setValue({ ...value, ordr: event.target.value })}
                                        required
                                    />
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Title"
                                        defaultValue={value.titl}
                                        onChange={(event) => setValue({ ...value, titl: event.target.value })}
                                        required
                                    />
                                    <Textarea
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Description"
                                        onChange={(event) => setValue({ ...value, desc: event.target.value })}
                                        defaultValue={value.desc}
                                        required
                                    />
                                    <Input
                                        type="file"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Banner Image"
                                        onChange={(event) => setValue({ ...value, banner_img: event.target.files[0] })}
                                    />
                                    <img width={200} src={value.banner_img ? URL.createObjectURL(value.banner_img) : value.img} alt="" />
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Another Link"
                                        onChange={(event) => {
                                            setValue({ ...value, url: event.target.value })
                                        }}
                                        defaultValue={value.url}
                                        required
                                    />
                                    <Button disabled={isSubmit}>{
                                        isSubmit ? "Loading" : "Save"
                                    }</Button>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromotionalBannerUpdate;
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import TableWithdrawRequset from "../../components/TableWithdrawRequest";
import axiosInstance from "../../config/axios";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";

export default function WithdrawRequest() {
  const history = useHistory()
  const [data, setData] = useState();
  const [pending, setPending] = useState(true);
  const { state, setState } = useContext(RootContext);
  const [bank, setBank] = useState(null);

  const filterBank = (bankvalue) => {

    setBank(bankvalue);
    setPending(true);
  }

  const getData = () => {

    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"));

    const data = {
      token: token,
      group: group.wbid,
      bank: bank
    }

    if (!token) {

      history.push("/login");
    } else {

      try {

        axiosInstance("get_withdraw_request", {
          method: "POST",
          data,
        })
          .then((res) => {

            const data = res.data.data.list;

            setData(data);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });
          })
          .then(() => {

            setPending(false);
          })
          .catch((err) => {

            localStorage.removeItem("access_token");
            history.push("/login");
          });
      } catch (error) {

        console.log("ERROR: ", error);
      }
    }
  };

  useEffect(() => {

    getData();

  }, [bank])

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableWithdrawRequset
              data={data}
              pending={pending}
              acceptEvent={() => {

                getData();
              }}
              declineEvent={() => {

                getData();
              }}
              filterBank={(value) => {

                filterBank(value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react'
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import { Link } from 'react-router-dom';
import axiosInstance from "../config/axios";
import Swal from "sweetalert2";

function PlayerSchorcutForm({ dataPlayer }) {

    const excludePlayerFromBonusRoling = () => {

        Swal.fire({

            title: 'Exclude This Player From Bonus Roling',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            customClass: {
              cancelButton: 'order-confirm-cancel',
              confirmButton: 'order-confirm-ok',
            },
          }).then((result) => {

            if (result.isConfirmed) {

                excludePlayer();
            }
            
          });
    }

    const excludePlayer = () => {

        const token = localStorage.getItem("access_token");
        const group = JSON.parse(localStorage.getItem("group"))
    
        const data = {
    
          token: token,
          group: group.wbid,
          uid: dataPlayer.uid
        }
    
        if (!token) {
    
            Swal.fire('Token Expire', 'please relogin.', 'warning');
        } else {
    
          try {
    
            axiosInstance("add_user_exclude", {
    
              method: "POST",
              data,
            })
              .then((res) => {
    
                const datarespon = res.data;

                if(datarespon.code === 0){

                    Swal.fire('Confirmed', 'exclude player from roling bonus are confirmed.', 'success');
                }

                if(datarespon.code !== 0){

                    Swal.fire('Erorr', datarespon.message, 'error');
                }
              })
    
          } catch (error) {
    
            console.log("ERROR: ", error);
            Swal.fire('Error', 'please check log.', 'error');
          }
        }
      };

    return (
        <>
            <Card>
                <CardHeader size="sm" color="blueGray" contentPosition="left">
                    <h2 className="text-white text-2xl">Player Shorcut</h2>
                </CardHeader>
                <CardBody>
                    <div className='flex gap-4'>
                        <Button color="lightBlue" size="sm">Mutation</Button>
                        <Link to="/money_management/deposit_request">
                        <Button color="blue" size="sm">Deposit</Button>
                        </Link>
                    </div>
                    <div className='flex gap-4 mt-2' >
                        <Link to="/money_management/withdraw_request">
                            <Button color="lightGreen" size="sm">withdraw</Button>
                        </Link>
                        <Link to={"/money_management/adjustment_request"} >
                            <Button color="blueGray" size="sm">Adjust</Button>
                        </Link>
                    </div>
                    <Button 
                        color="red" 
                        className="mt-2" 
                        size="sm" 
                        onClick={excludePlayerFromBonusRoling}>
                            Exclude Player From Bonus Roling
                    </Button>
                </CardBody>
            </Card>
        </>
    )
}

export default PlayerSchorcutForm
import { Label } from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
export default function CardTable({ data, pending ,onChangeRowsPerPage }) {
  const columns = [
    {
      name: "ID",
      width: "60px",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => row.actv === 1 ? <Label color={"green"}>Active</Label> : <Label color={"red"}>Non Active</Label>,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => {
        return (
          <Link to={"/banking/register_bank_edit/" + row.id} >
            <Button
              color="yellow"
              buttonType="filled"
              size="sm"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="light"
            >Update</Button>
          </Link>
        )
      },
      sortable: true,
    },
  ];


  return (
    <DataTable
      columns={columns}
      data={data}
      progressPending={pending}
      onChangeRowsPerPage={onChangeRowsPerPage}
      pagination
    />
  );
}

import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";

export default function TableLotteryTransactionHistory({ data, pending }) {

    const columns = [
        {
            name: "Username",
            selector: (row) => row.username,
            width: "180px",
            sortable: true,
        },
        {
            name: "Market",
            selector: (row) => row.market,
            sortable: true,
        },
        {
            name: "Periode",
            selector: (row) => row.priode,
            sortable: true,
        },
        {
            name: "Bet",
            selector: (row) => row.bet,
            sortable: true,
        },
        {
            name: "Number",
            selector: (row) => row.number,
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => row.type,
            sortable: true,
        },
        {
            name: "Pay",
            selector: (row) => row.bayar,
            sortable: true,
        },

        {
            name: "Winlose",
            selector: (row) => row.winlose,
            sortable: true,
        }
    ];

    return (
        <>
            <Card>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={pending}
                        pagination
                    />
                </CardBody>
            </Card>
        </>
    );
}


import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import TablePlayerReferral from '../../components/TablePlayerReferral'
import { RootContext } from '../../App';
import refactorStructureData from '../../helpers/refactorDataMenu';
import Swal from 'sweetalert2';

export default function PlayerReferral() {

  const history = useHistory();

  const [playerReferral, setPlayerReferral] = useState();

  const [pending, setPending] = useState(true);

  const [username, setUsername] = useState(null);

  const [fromCreateAt, setFromCreateAt] = useState(null);

  const [toCreateAt, setToCreateAt] = useState(null);

  const [length, setLength] = useState(10);

  const { state, setState } = useContext(RootContext);

  const debounced = useDebouncedCallback(
    (action) => {

      action();
    },
    200
  );


  useEffect(() => {

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, toCreateAt, fromCreateAt, length])

  const getData = () => {
    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"))

    if (!token) {
      history.push("/login");
    } else {
      try {
        axiosInstance("get_user_referral", {

          method: "POST",
          data: {
            token: token,
            group: group.wbid,
            user: username,
            crea: fromCreateAt,
            cre2: toCreateAt,
            lgth: length
          },
        }).then((res) => {

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

            setPending(false);
            setPlayerReferral(res.data.data.list);
          })
          .catch((err) => {

            console.log("error: "+err);

            Swal.fire(

              'Error',
              'get data failed',
              'warning'
            );

            setPending(false);

            //localStorage.removeItem("access_token");
            //history.push("/login");
          });

      } catch (error) {

        console.log("ERROR: ", error);
      }
    }
  };


  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto">
      </div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16 ">
            <div>
              <TablePlayerReferral 
                title="Player List" 
                playerReferral={playerReferral} 
                pending={pending}
                usernameEvent={(event) => {
                  debounced(() => setUsername(event.target.value))
                }}

                fromCreateAtEvent={(event) => {
                  debounced(() => setFromCreateAt(event.target.value))
                }}

                toCreateAtEvent={(event) => {
                  debounced(() => setToCreateAt(event.target.value))
                }}

                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                  setLength(currentRowsPerPage)
                }}

              />
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Checkbox from "@material-tailwind/react/Checkbox";
// import Small from "@material-tailwind/react/Small";
import Button from "@material-tailwind/react/Button";
import axiosInstance from "../../config/axios";
import Swal from "sweetalert2";
import refactorStructureData from "../../helpers/refactorDataMenu";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { RootContext } from "../../App";
import SelectRequired from "../../components/RequiredSelect";
const BankAccountNew = () => {
  const history = useHistory()
  const { state, setState } = useContext(RootContext)
  const token = localStorage.getItem("access_token")
  const group = JSON.parse(localStorage.getItem("group"))
  const [check, setCheck] = useState(true)
  const [data, setData] = useState({
    token: token,
    group: group.wbid,
    bg_id: null,
    bm_id: null,
    bank_name: null,
    bank_acc: null,
    bank_num: null,
    bank_type: null,
    isactive: 1,
    percentage: null,
    limit_receive: null
  })
  const [optionBankGroup, setOptionBankGroup] = useState([])
  const [optionBankMain, setOptionBankMain] = useState([])
  const [file, setFile] = useState(null)


  const submit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    if (file) { formData.append('gambar', file); }

    axiosInstance.post('create_bank_account', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      history.push('/banking/bank_account')
      Swal.fire(
        'Saved!',
        'Your data is saved!',
        'success'
      )
    }).catch((err) => {
      Swal.fire('Oops', err, 'error')
    })
    event.preventDefault()
  }

  const listBankGroup = () => {
    axiosInstance.post('get_bank_group', {
      token: token,
      group: group.wbid
    }).then((res) => {
      setOptionBankGroup(res.data.data.list.map((data) => {
        return { label: data.name, value: data.id }
      }))
    })
  }
  const listBankMain = () => {
    axiosInstance.post('get_bank_main', {
      token: token,
      group: group.wbid
    }).then((res) => {
      setOptionBankMain(res.data.data.list.map((data) => {
        return { label: data.name, value: data.id }
      }))
    })
  }

  const verifyToken = () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      history.push("/login");
    } else {
      try {
        axiosInstance("verify_token", {
          method: "POST",
          data: {
            token: token,
          },
        })
          .then((res) => {
            setState({
              ...state,
              group: res.data.data.group,
              menu: refactorStructureData(res.data.data.menu),
            });
            if (!localStorage.getItem("group")) {
              localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
            }
          })
          .catch((err) => {

            localStorage.removeItem("access_token");
            history.push("/login");
          });

      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };



  useEffect(() => {
    verifyToken()
    listBankGroup()
    listBankMain()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Bank Account</h2>
              </CardHeader>
              <form onSubmit={submit}>
                <CardBody>
                  <SelectRequired required placeholder="BankGroup" options={optionBankGroup}
                    onChange={(event) => {
                      setData({ ...data, bg_id: event.value })
                    }}
                  />
                  <br />
                  <SelectRequired required placeholder="Bank Main" options={optionBankMain}
                    onChange={(event) => {
                      setData({ ...data, bm_id: event.value })
                    }}
                  />
                  <br />
                  <SelectRequired required placeholder="Bank Name" options={optionBankMain}
                    onChange={(event) => {
                      setData({ ...data, bank_name: event.label })
                    }}
                  />
                  <br />
                  <Input
                    type="text"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Bank Account"
                    onChange={(event) => setData({ ...data, bank_acc: event.target.value })}
                    required
                  />
                  <br />
                  <Input
                    type="number"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Bank number"
                    onChange={(event) => setData({ ...data, bank_num: event.target.value })}
                    required
                  />
                  <br />
                  <SelectRequired options={[{ label: 'Deposit', value: 1 }, { label: 'Withdraw', value: 2 }]} required
                    placeholder="Bank Type"
                    onChange={(event) => setData({ ...data, bank_type: event.value })}
                  />
                  <br />
                  <Input
                    type="text"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Percentage"
                    onChange={(event) => setData({ ...data, percentage: event.target.value })}
                    required
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />

                  <br />
                  <Input
                    type="text"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Limit Receive"
                    onChange={(event) => setData({ ...data, limit_receive: event.target.value })}
                    required
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <br />
                  <Input
                    type="file"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Image Qris"
                    onChange={(event) => setFile(event.target.files[0])}
                    required
                  />
                  <br />
                  <Checkbox checked={check} onChange={(event) => {
                    setCheck(!check)
                    if (check) {
                      setData({ ...data, isactive: 0 })
                    } else {
                      setData({ ...data, isactive: 1 })
                    }
                  }} color="lightBlue" text="Active" id="checkbox" />

                </CardBody>

                <Button
                  color="green"
                  buttonType="filled"
                  size="regular"
                  rounded={false}
                  block={false}
                  iconOnly={false}
                  ripple="light"
                >
                  Save
                </Button>
              </form>

            </Card>
          </div>
        </div>
      </div>
    </div >
  );
};

export default BankAccountNew;

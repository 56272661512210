import React, { useContext, useState, useEffect } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { RootContext } from '../../../App';
import axiosInstance from '../../../config/axios'
import refactorStructureData from '../../../helpers/refactorDataMenu';
import { useDebouncedCallback } from 'use-debounce/lib';
import TableUserLog from '../../../components/TableUserLog';
import InputIcon from "@material-tailwind/react/InputIcon";
const UserList = () => {

  const token = localStorage.getItem("access_token")
  const group = JSON.parse(localStorage.getItem("group"))
  const [data, setData] = useState([])
  const { state, setState } = useContext(RootContext)
  const [isLoading, setIsLoading] = useState(true)
  const [length, setLength] = useState(10)
  const [admin, setAdmin] = useState(null)
  const [activity, setActivity] = useState(null)
  const [ip, setIp] = useState(null)
  const [startDate , setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [username, setUsername] = useState(null)


  const debounced = useDebouncedCallback(
    (action) => {
      action()
    },
    100
  );
  useEffect(() => {
    setIsLoading(true)
    axiosInstance.post('get_admin_log', {
      token: token,
      group: group.wbid,
      lgth: length,
      adm_id: admin,
      ip : ip,
      activity : activity,
      date1 : startDate,
      date2 : endDate,
      username:username,
    }).then((res) => {
      setIsLoading(false)
      setData(res.data.data.list)
      setState({
        ...state,
        group: res.data.data.additional_data.group,
        menu: refactorStructureData(res.data.data.additional_data.menu),
      })
    }).catch((err) => {
      console.log(err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, admin , activity , ip  , startDate , endDate , username])
  return (
    <div>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardBody>
                <h5>Advanced search</h5>
                <div className='flex gap-4 mt-2'>
                  <InputIcon
                    onChange={(event) => debounced(() => setUsername(event.target.value))}
                    iconName="search" outline={true} placeholder="Username" />
                     <InputIcon
                    onChange={(event) => debounced(() => setAdmin(event.target.value))}
                    iconName="search" outline={true} placeholder="Admin id" />
                </div>
                <div className='flex gap-4 mt-2'>
                  <InputIcon
                    onChange={(event) => debounced(() => setActivity(event.target.value))}
                    iconName="search" outline={true} placeholder="Activity" />
                     <InputIcon
                    onChange={(event) => debounced(() => setIp(event.target.value))}
                    iconName="search" outline={true} placeholder="Ip address" />
                </div>
                <div className='flex gap-4 mt-2'>
                  <InputIcon
                    type="date"
                    onChange={(event) =>  setStartDate(event.target.value)}
                    iconName="search" outline={true} placeholder="Start date" />
                     <InputIcon
                    type="date"
                    onChange={(event) => setEndDate(event.target.value)}
                    iconName="search" outline={true} placeholder="End date" />
                </div>
              </CardBody>
            </Card>
            <Card className="mt-10">
              <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">User Log</h2>
              </CardHeader>
              <CardBody>
                <TableUserLog
                  data={data} pending={isLoading}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                    setLength(currentRowsPerPage)
                  }} />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserList;
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";

export default function TableDrawResult({ data, pending }) {

    const columns = [
        {
            name: "Market",
            selector: (row) => row.pasaran,
            width: "180px",
            sortable: true,
        },
        {
            name: "Result",
            selector: (row) => row.result,
            sortable: true,
        },
        {
            name: "Lottery Periode",
            selector: (row) => row.priode,
            sortable: true,
        },
        {
            name: "Day",
            selector: (row) => row.days,
            sortable: true,
        },
        {
            name: "Settle Time",
            selector: (row) => {

                let d = new Date(row.updated_at);
                var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((parseInt(d.getMinutes() / 5) * 5).toString().length == 2 ? (parseInt(d.getMinutes() / 5) * 5).toString() : "0" + (parseInt(d.getMinutes() / 5) * 5).toString()) + ":00";
                return date_format_str;
            },
            sortable: true,
        },
        {
            name: "Calculated",
            selector: (row) => {

                if (row.is_calculated === 1) {

                    return "Yes";
                } else {

                    return "No";
                }
            },
            sortable: true,
        }
    ];

    return (
        <>
            <Card>
                <CardBody>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={pending}
                    />
                </CardBody>
            </Card>
        </>
    );
}


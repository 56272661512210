import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
// import { Input, Textarea } from "@material-tailwind/react";
import { Input } from "@material-tailwind/react";
import { useState } from "react";
import axiosInstance from '../config/axios'
import ReactQuill from 'react-quill';
export default function TablePromotionalBanner({ data, pending, onChangeRowsPerPage, deleteAction, updateAction }) {
    const [value, setValue] = useState()
    const [description, setDescription] = useState(null)
    const [isSubmit, setIsSubmit] = useState()
    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"))
    const columns = [
        {
            name: "#",
            selector: (row) => row.id,
            width: '80px'
        },
        {
            name: "Title",
            selector: (row) => row.titl,
            width: '80px'
        },
        {
            name: "Promo group",
            selector: (row) => row.gnam,
        },
        {
            name: "",
            width: '190px',
            selector: (row) => (
                <div className="flex gap-4">
                    <Button
                        color="green"
                        buttonType="outline"
                        size="sm"
                        rounded={false}
                        block={false}
                        iconOnly={false}
                        ripple="light"
                        onClick={(event) => {
                            event.preventDefault();
                            setValue({
                                idp: row.idp,
                                id: row.id,
                                ordering: row.ordr,
                                title: row.titl,
                                url_to: row.url
                            })
                            setDescription(row.desc)

                        }}

                    >
                        <i class="fa-solid fa-pen-to-square"></i>
                    </Button>
                    <Button
                        color="deepOrange"
                        buttonType="outline"
                        size="sm"
                        outline={false}
                        rounded={false}
                        block={false}
                        iconOnly={false}
                        ripple="light"
                        onClick={(event) => {
                            Swal.fire({
                                title: 'Are you sure?',
                                text: "You won't be able to revert this!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes, delete it!'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    deleteAction(row.id)
                                }
                            })
                        }}
                    >
                        <i class="fa-solid fa-trash-can"></i>
                    </Button>
                </div>
            )
        }
    ];




    return (
        <Card>
            <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Promotional Banner Content</h2>
            </CardHeader>
            <CardBody>
                <div className="flex justify-between">
                    <div className="mb-6 flex gap-4">
                        <Link to="/web_management/cms/promotional_banner_new">
                            <Button
                                color="lightBlue"
                                buttonType="filled"
                                size="regular"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                            >
                                Add Promotinal Banner
                            </Button>
                        </Link>
                       
                    </div>
                </div>
                <div className="flex gap-4">
                    <div style={{ width: '50%' }}>
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={pending}
                            onChangeRowsPerPage={onChangeRowsPerPage}
                            pagination
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault()
                                setIsSubmit(true)
                                var bodyFormData = new FormData()
                                bodyFormData.append("token", token)
                                bodyFormData.append('group', group.wbid)
                                bodyFormData.append('idp', value.idp)
                                bodyFormData.append('id', value.id)
                                bodyFormData.append('ordering', value.ordering)
                                bodyFormData.append('title', value.title)
                                bodyFormData.append('description', description)
                                bodyFormData.append('url_to', value.url_to)

                                if (value.banner_img) {
                                    bodyFormData.append('banner_img', value.banner_img)
                                }
                                axiosInstance.post('update_site_promo_content', bodyFormData).then((res) => {
                                    setIsSubmit(false)
                                    updateAction()
                                    Swal.fire('Success', 'data is saved', 'success')
                                }).catch((err) => {
                                    setIsSubmit(false)
                                    console.log(err.response)
                                    if (err.response.data.data.ordering) {
                                        return Swal.fire('Ordering is already taken', 'Please change to another ordering', 'error')
                                    }
                                    Swal.fire('Failed your input its wrong', 'Please choose .jpg .png file', 'error')
                                })
                            }}
                            className="flex flex-col gap-4">

                            <Input
                                type="number"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="Ordering banner"
                                defaultValue={value?.ordering}
                                onChange={(event) => setValue({ ...value, ordering: event.target.value })}
                                required
                            />
                            <Input
                                type="text"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="Title"
                                defaultValue={value?.title}
                                onChange={(event) => setValue({ ...value, title: event.target.value })}
                                required
                            />
                            <ReactQuill
                                type="text"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="Description"
                                value={description}
                                onChange={(event) => {
                                    setDescription(event)
                                }
                                }
                                required
                            />
                            <Input
                                type="file"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="Banner Image"
                                onChange={(event) => setValue({ ...value, banner_img: event.target.files[0] })}
                            />
                            <img width={200} alt="" />
                            <Input
                                type="text"
                                color="lightBlue"
                                size="regular"
                                outline={true}
                                placeholder="Another Link"
                                defaultValue={value?.url_to}
                                onChange={(event) => {
                                    setValue({ ...value, url_to: event.target.value })
                                }}
                                required
                            />
                            <Button disabled={isSubmit}>{
                                isSubmit ? "Loading" : "Save"
                            }</Button>
                        </form>
                    </div>

                </div>


            </CardBody>
        </Card>
    );
}

import React from 'react'
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
function PlayerLastLoginForm({ data }) {
    return (
        <> <Card className="mt-12">
            <CardHeader size="sm" color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Login Information</h2>
            </CardHeader>
            <CardBody>
                <div className="grid grid-cols-1 gap-4">
                    <div className="">
                        <Input
                            type="text"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="Terakhir Login"
                            value={data.lalo}
                            disabled
                        />
                    </div>
                    <div className="">
                        <Input
                            type="text"
                            color="lightBlue"
                            size="sm"
                            outline={true}
                            placeholder="IP terakhir login"
                            value={data.laip}
                            disabled
                        />
                    </div>
                </div>

            </CardBody>
        </Card></>
    )
}

export default PlayerLastLoginForm
import React, { useContext, useEffect, useState } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import axiosInstance from "../../config/axios";
import Swal from "sweetalert2";
import refactorStructureData from "../../helpers/refactorDataMenu";
import { RootContext } from "../../App";
import { useHistory } from "react-router-dom";

const UpdateQris = () => {

  const history = useHistory()
  const { state, setState } = useContext(RootContext)
  const token = localStorage.getItem("access_token")
  const group = JSON.parse(localStorage.getItem("group"))
  const [file, setFile] = useState(null)

  const getData = () => {

    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"));

    const data = {
      token: token,
      group: group.wbid,
    }

    if (!token) {

      history.push("/login");
    } else {

      try {

        axiosInstance("get_menu", {
          method: "POST",
          data,
        })
          .then((res) => {

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });
          })
          .catch((err) => {

            localStorage.removeItem("access_token");
            history.push("/login");
          });
      } catch (error) {

        console.log("ERROR: ", error);
      }
    }
  }

  const submit = (event) => {

    const formData = new FormData();
    formData.append('token', token);
    formData.append('group', group.wbid);

    if (file) {

      formData.append('gambar', file);
    }

    axiosInstance.post('update-qris', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {

      if (res.data.code == 0) {

        Swal.fire(
          'Saved!',
          'Your data is saved!',
          'success'
        );

        setState({
          ...state,
          group: res.data.data.group,
          menu: refactorStructureData(res.data.data.menu),
        });
      } else if (res.data.code == 400) {

        Swal.fire(
          'Warning',
          res.data.message,
          'warning'
        );
      } else {

        Swal.fire(
          'Error',
          "Internal server Error",
          'error'
        );
      }
    }).catch((err) => {

      console.log(err.response)
    })

    event.preventDefault()
  }

  useEffect(() => {

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className="container mx-auto max-w-full">
        <div className="grid grid-cols-1 px-4 mb-16">
          <Card>
            <CardHeader color="blueGray" contentPosition="left">
              <h2 className="text-white text-2xl">Update Qris Image</h2>
            </CardHeader>
            <form onSubmit={submit}>
              <CardBody>
                <Input
                  type="file"
                  color="lightBlue"
                  size="regular"
                  outline={true}
                  placeholder="Image Qris"
                  onChange={(event) => setFile(event.target.files[0])}
                />
              </CardBody>

              <Button
                color="green"
                buttonType="filled"
                size="regular"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="light"
              >
                Update
              </Button>
            </form>

          </Card>
        </div>
      </div>
    </div >
  );
};

export default UpdateQris;

const refactorStructureData = (data) => {
  let newStructure;
  let root = [];
  let rootObj = {};
  let lastRoot;

  // get all data root
  for (const key of data) {
    if (root.length === 0) {
      root.push(key.root);
    } else {
      // for (const key2 of root) {
      //   if (lastRoot !== key.root) {
      //     lastRoot = key.root;
      //     root.push(key.root);
      //   }
      // }
      if (lastRoot !== key.root) {
        lastRoot = key.root;
        root.push(key.root);
      }
    }
  }

  // convert root to object
  for (const iterator of root) {
    rootObj = { ...rootObj, [iterator]: [] };
  }

  // add group inside root
  for (const key2 of root) {
    let group = [];
    let lastGroup;
    for (const key of data) {
      if (key2 === key.root) {
        if (group.length === 0 && key.group !== "") {
          rootObj[key2] = { ...rootObj[key2], [key.group]: [] };
          lastGroup = key.group;
        } else {
          if (lastGroup !== key.group && key.group !== "") {
            rootObj[key2] = { ...rootObj[key2], [key.group]: [] };
            lastGroup = key.group;
          }
        }
      }
    }
  }

  // add name inside group or root
  for (const iterator of root) {
    let isNotGroup = false;
    if (Object.keys(rootObj[iterator]).length === 0) {
      isNotGroup = true;
    }
    for (const iterData of data) {
      if (isNotGroup) {
        if (iterData.root === iterator) {
          rootObj[iterator].push({
            name: iterData.name,
            route: iterData.route,
            icon: iterData.icon,
          });
        }
      } else {
        // isGroup
        // for (const iterGroup of Object.keys(rootObj[iterator])) {
        //   if (iterData.root === iterator && iterData.group === iterGroup) {
        //     rootObj[iterator][iterGroup].push({
        //       name: iterData.name,
        //       route: iterData.route,
        //       icon: iterData.icon,
        //     });
        //   }
        // }
        for (const iterGroup of Object.keys(rootObj[iterator])) {
          if (iterData.root === iterator && iterData.group === iterGroup) {
            const foundItem = rootObj[iterator][iterGroup].find((item) => item.name === iterData.name);
            if (!foundItem) {
              rootObj[iterator][iterGroup].push({
                name: iterData.name,
                route: iterData.route,
                icon: iterData.icon,
              });
            }
          }
        }
        
      }
    }
  }

  newStructure = rootObj;
  return newStructure;
};

export default refactorStructureData;
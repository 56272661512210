import axios from 'axios';

const LogReport = (functionname, data, respon, message )=> {

  const variabledata = JSON.stringify(data);

  const logtext = JSON.stringify(respon);

  var currentdate = new Date();

  var datetime = "Time: " + currentdate.getDate() + "/"
                  + (currentdate.getMonth()+1)  + "/" 
                  + currentdate.getFullYear() + " @ "  
                  + currentdate.getHours() + ":"  
                  + currentdate.getMinutes() + ":" 
                  + currentdate.getSeconds();

  const errordetail = datetime+" => Function: "+functionname+" => Variable: "+variabledata+" => Respon: "+logtext+" => Message: "+message;

  const dataerror = {

    content: errordetail
  };

  const discrdhookurl = "https://discord.com/api/webhooks/1138697772367888485/cKEpa7mPlxHRE7Dg1tm45VFGKbP62TrvnJcaV71lYRrjmUS9CgMM9md7V8-7n_ueuhDA";

  axios.post(discrdhookurl, dataerror);

};

export default LogReport;

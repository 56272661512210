import React, { useContext, useEffect, useState } from 'react';
import TableAccessRight from '../../../components/TableAccessRight';
import axiosIntance from '../../../config/axios'
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
const AccessRights = () => {

  const [data , setData] = useState([])
  const [pending , setPending] = useState(true)
  const token = localStorage.getItem("access_token")
  const group = JSON.parse(localStorage.getItem("group"))
  const {state , setState} = useContext(RootContext)
  useEffect(()=>{
    axiosIntance.post('get_access_list' , {
      token :token ,
      group :group.wbid
    }).then((res)=>{
      // console.log(res)
      setPending(false)
      setData(res.data.data.list)
      setState({
        ...state,
        group: res.data.data.additional_data.group,
        menu: refactorStructureData(res.data.data.additional_data.menu),
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div>
    <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
    <div className="px-3 md:px-8 h-auto -mt-24">
      <div className="container mx-auto max-w-full">
        <div className="grid grid-cols-1 px-4 mb-16">
         <TableAccessRight data={data} pending={pending} />
        </div>
      </div>
    </div>
  </div>
  )
}

export default AccessRights;
import React, { useContext, useEffect, useState } from 'react'
import { RootContext } from '../../../App'
import axiosInstance from '../../../config/axios'
import refactorStructureData from '../../../helpers/refactorDataMenu'
import TablePromotionalGroup from '../../../components/TablePromotionalGroup'
function PromoGroup() {
    const token = localStorage.getItem("access_token")
    const group = JSON.parse(localStorage.getItem("group"))
    const [data, setData] = useState([])
    const { state, setState } = useContext(RootContext)
    const [isLoading, setIsLoading] = useState(true)
    const [length, setLength] = useState(10)

    const getAllPromotionalBanner = () => {
        axiosInstance.post('get_site_promo', {
            lgth: length,
            token: token,
            group: group.wbid,
        }).then((res) => {
            setIsLoading(false)
            setData(res.data.data.list)
            setState({
                ...state,
                group: res.data.data.additional_data.group,
                menu: refactorStructureData(res.data.data.additional_data.menu),
            })
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        getAllPromotionalBanner()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [length])


    const createPromoGroup = (value) => {
        axiosInstance.post('create_site_promo', {
            token: token,
            group: group.wbid,
            group_name: value
        }).then((res) => {
            getAllPromotionalBanner()
        }).catch((err) => {
            console.log({ err })
        })

    }

    const updatePromoGroup = (value, id) => {

        axiosInstance.post('update_site_promo', {
            token: token,
            group: group.wbid,
            group_name: value,
            id: id
        }).then((res) => {
            getAllPromotionalBanner()
        }).catch((err) => {
            console.log({ err })
        })

    }



    return (
        <>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TablePromotionalGroup data={data} pending={isLoading}
                            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                                setLength(currentRowsPerPage)
                            }}
                            createPromoGroup={createPromoGroup}
                            updatePromoGroup={updatePromoGroup}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PromoGroup
/*
* komponen ini menggunakan material tailwind, jika ingin mengubah atau menambah
* lihat halaman ini sebagai referensi
* https://www.material-tailwind.com/docs/react/installation
*
*/

import { RootContext } from '../../App';
import axiosInstance from '../../config/axios';
import refactorStructureData from '../../helpers/refactorDataMenu';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import { Checkbox } from "@material-tailwind/react";

export default function LotteryPrize() {

    const [listData, setListData] = useState();

    const [openmarket, setOpenmarket] = useState();
    const [closemarket, setClosemarket] = useState();
    const [fiture4d, setFiture4d] = useState();
    const [fiturecb, setFiturecb] = useState();
    const [fitureshio, setFitureshio] = useState();
    const [senin, setSenin] = useState();
    const [selasa, setSelasa] = useState();
    const [rabu, setRabu] = useState();
    const [kamis, setKamis] = useState();
    const [jumat, setJumat] = useState();
    const [sabtu, setSabtu] = useState();
    const [minggu, setMinggu] = useState();

    const param = useParams();
    const { state, setState } = useContext(RootContext);
    const history = useHistory();
    const [saving, setSaving] = useState();

    const group = JSON.parse(localStorage.getItem("group"));

    const handleSave = () => {

        const token = localStorage.getItem("access_token");
        const data = {

            token: token,
            group: group.wbid,
            market: param.market,
            open: openmarket,
            close: closemarket,
            senin: senin,
            selasa: selasa,
            rabu: rabu,
            kamis: kamis,
            jumat: jumat,
            sabtu: sabtu,
            minggu: minggu,
            fourd: fiture4d,
            colokbebas: fiturecb,
            shio: fitureshio,
        }

        if (!token) {

            history.push("/login");

        } else {

            try {

                axiosInstance('/lottery/set-unit', {

                    method: "POST",

                    data,

                }).then((res) => {

                    if (res.data.code === 0) {

                        Swal.fire(
                            'Saved',
                            'Unit Saved',
                            'success',
                        ).then(() => {

                            setListData(res.data.data.list);
                            setSaving(false);
                        })

                    } else {

                        Swal.fire(
                            'Failed',
                            'Failed To save',
                            'error',
                        );

                        setSaving(false);
                        console.log('Error Mesage: ' + res.data.message);
                    }

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        ).then(() => {

                            setSaving(false);

                        })
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                ).then(() => {

                    setSaving(false);

                })

            }

        }
    }

    const getData = () => {

        const token = localStorage.getItem("access_token");

        const data = {

            token: token,
            group: group.wbid,
            market: param.market,

        }

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            try {

                axiosInstance('/lottery/get-unit', {

                    method: "POST",

                    data,

                }).then((res) => {

                    if (res.data.code === 0) {

                        const data = res.data.data.list;

                        setListData(data);

                        setState({

                            ...state,

                            group: res.data.data.additional_data.group,

                            menu: refactorStructureData(res.data.data.additional_data.menu),

                        });
                    } else {

                        Swal.fire(
                            'Internal Server Error',
                            res.data.message,
                            'error'
                        )
                    }

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        ).then(() => {

                            setSaving(false);

                        })
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                ).then(() => {

                    setSaving(false);

                })

            }

        }

    };

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //console.log("List Data: "+JSON.stringify(listData));

    return (
        <>
            {!listData ? (

                <div className="flex justify-center" style={{ marginTop: 150 }}>

                    <ReactLoading
                        type="spinningBubbles"
                        color="rgb(53, 126, 221)"
                        height={100}
                        width={100}
                    />

                </div>

            ) : (

                <Card>

                    <CardHeader color="blueGray" contentPosition="none">

                        <div className="w-full flex items-center justify-between">

                            <h2 className="text-white text-2xl">Unit Settings {listData.name}</h2>

                            <Button
                                className="h-10
                                    px-5 m-2
                                    text-green-100 
                                    transition-colors 
                                    duration-150 
                                    bg-green-700 
                                    rounded-lg 
                                    focus:shadow-outline 
                                    hover:bg-green-800"
                                onClick={() => {

                                    setSaving(true);

                                    handleSave();

                                }}

                            >
                                {!saving ? 'Save' : 'Saving'}

                            </Button>

                        </div>

                    </CardHeader>

                    <CardBody>
                        <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                            Open Close Market

                        </h6>

                        <div className="flex flex-wrap mt-10 font-light">

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Input
                                    color="purple"
                                    placeholder="Open Market Time"
                                    defaultValue={listData['open']}
                                    onChange={(e) => {

                                        setOpenmarket(e.target.value);
                                    }}
                                />

                            </div>

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Input
                                    color="purple"
                                    placeholder="Close Market Time"
                                    defaultValue={listData['close']}
                                    onChange={(e) => {

                                        setClosemarket(e.target.value);
                                    }}
                                />

                            </div>

                        </div>

                        <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                            Active Days

                        </h6>

                        <div className="flex flex-wrap mt-10 font-light">

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Checkbox
                                    text={"Monday"}
                                    color="lightBlue"
                                    id="Monday"
                                    checked={listData['senin']}
                                    onChange={(event) => {

                                        listData['senin'] = event.target.checked;
                                        setSenin(event.target.checked);
                                        setListData(listData);
                                    }
                                    }
                                />

                            </div>

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Checkbox
                                    text={"Tuesday"}
                                    color="lightBlue"
                                    id="Tuesday"
                                    checked={listData['selasa']}
                                    onChange={(event) => {

                                        listData['selasa'] = event.target.checked;
                                        setSelasa(event.target.checked);
                                        setListData(listData);
                                    }
                                    }
                                />

                            </div>

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Checkbox
                                    text={"Wednesday"}
                                    color="lightBlue"
                                    id="Wednesday"
                                    checked={listData['rabu']}
                                    onChange={(event) => {

                                        listData['rabu'] = event.target.checked;
                                        setRabu(event.target.checked);
                                        setListData(listData);
                                    }
                                    }
                                />

                            </div>

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Checkbox
                                    text={"Thursday"}
                                    color="lightBlue"
                                    id="Thursday"
                                    checked={listData['kamis']}
                                    onChange={(event) => {

                                        listData['kamis'] = event.target.checked;
                                        setKamis(event.target.checked);
                                        setListData(listData);
                                    }
                                    }
                                />

                            </div>

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Checkbox
                                    text={"Friday"}
                                    color="lightBlue"
                                    id="Friday"
                                    checked={listData['jumat']}
                                    onChange={(event) => {

                                        listData['jumat'] = event.target.checked;
                                        setJumat(event.target.checked);
                                        setListData(listData);
                                    }
                                    }
                                />

                            </div>

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Checkbox
                                    text={"Saturday"}
                                    color="lightBlue"
                                    id="Saturday"
                                    checked={listData['sabtu']}
                                    onChange={(event) => {

                                        listData['sabtu'] = event.target.checked;
                                        setSabtu(event.target.checked);
                                        setListData(listData);
                                    }
                                    }
                                />

                            </div>

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Checkbox
                                    text={"Sunday"}
                                    color="lightBlue"
                                    id="Sunday"
                                    checked={listData['minggu']}
                                    onChange={(event) => {

                                        listData['minggu'] = event.target.checked;
                                        setMinggu(event.target.checked);
                                        setListData(listData);
                                    }
                                    }
                                />

                            </div>

                        </div>

                        <h6 className="text-purple-500 text-sm my-6 font-light uppercase">

                            Enable/Disble Fiture

                        </h6>

                        <div className="flex flex-wrap mt-10 font-light">

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Checkbox
                                    text={"4D/3D/2D"}
                                    color="lightBlue"
                                    id="4d"
                                    checked={listData['fourd']}
                                    onChange={(event) => {

                                        listData['fourd'] = event.target.checked;
                                        setFiture4d(event.target.checked);
                                        setListData(listData);
                                    }
                                    }
                                />

                            </div>

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Checkbox
                                    text={"Colok Bebas"}
                                    color="lightBlue"
                                    id="cb"
                                    checked={listData['colokbebas']}
                                    onChange={(event) => {

                                        listData['colokbebas'] = event.target.checked;
                                        setFiturecb(event.target.checked);
                                        setListData(listData);
                                    }
                                    }
                                />

                            </div>

                            <div className="w-50 lg:w-6/12 pr-4 mb-10 font-light">

                                <Checkbox
                                    text={"Shio"}
                                    color="lightBlue"
                                    id="shio"
                                    checked={listData['shio']}
                                    onChange={(event) => {

                                        listData['shio'] = event.target.checked;
                                        setFitureshio(event.target.checked);
                                        setListData(listData);
                                    }
                                    }
                                />

                            </div>

                        </div>

                    </CardBody>

                </Card>

            )}

        </>

    );

}

import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from "react-data-table-component";
import { Label, Button } from "@material-tailwind/react";

export default function TableWithdrawRequestDetailUser({ data, pending, user }) {

    function formatRupiah(angka) {
        return "Rp " + angka.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }

    const columns = [
        {
            name: "#",
            width: "80px",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Username",
            selector: (row) => row.unam,
            sortable: true,
        },
        {
            name: "Sender Bank",
            selector: (row) => row.sban + " " + row.snum + " - " + row.snam,
            width: '200px',
            sortable: true,
        },
        {
            name: "Receiver Bank",
            selector: (row) => row.rban + " " + row.rnum + " - " + row.rnam,
            width: '200px',
            sortable: true,
        },
        {
            name: "Amount",
            selector: (row) => formatRupiah(row.amou),
            sortable: true,
        },
        {
            name: "Description",
            selector: (row) => row.desc,
            sortable: true,
        },

        {
            name: "Status",
            selector: (row) => (row.isap === 0 ? <Label color={"red"}>Decline</Label> : row.isap === 1 ? <Label color={"green"}>Approved</Label> : <Label color={"amber"}>Pending</Label>),
            sortable: true,
        },
        {
            name: "By",
            selector: (row) => row.agen,
            sortable: true,
        },
        {
            name: "Request date",
            selector: (row) => row.rdat,
            sortable: true,
            width: "180px"
        },
        {
            name: "Process date",
            selector: (row) => row.pdat,
            sortable: true,
            width: "180px"
        },

    ];

    const handleExport = () => {
        const header = '"username","bank","account","number","amount","date","status","agent",\n';
        const csvData = data.map(d => `"${d.unam}","${d.rban}","${d.rnam}","${d.rnum}","${d.amou}","${d.rdat}","${d.isap === 1 ? 'approve' : 'decline'}","${d.agen}",\n`).join('');
        const blob = new Blob([header + csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'withdraw-'+user+'.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <>

            <Card className="mt-12">
                <CardHeader color="blueGray" contentPosition="left">
                    <h2 className="text-white text-2xl">Withdraw User </h2>
                    <p>{user}</p>
                </CardHeader>
                <CardBody>
                <Button
                        color="blue"
                        buttonType="filled"
                        size="sm"
                        rounded={false}
                        block={false}
                        iconOnly={false}
                        ripple="light"
                        onClick={handleExport}
                        >Export To CSV
                    </Button>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={pending}
                    />
                </CardBody>
            </Card>
        </>
    );
}

// import { useEffect, useState } from "react";
import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import Icon from "@material-tailwind/react/Icon";
import H6 from "@material-tailwind/react/Heading6";
import { RootContext } from "../App";
import Swal from "sweetalert2";
import {Fragment} from 'react';

export default function Sidebar() {
  const history = useHistory();
  const [showSidebar, setShowSidebar] = useState("-left-64");
  const [rootActive, setRootActive] = useState("");
  const [groupActive, setGroupActive] = useState("");
  const [groupHeightPlus, setGroupHeightPlus] = useState(0);
  // const [loading, setLoading] = useState()

  const changeRoot = (value) => {
    if (rootActive === value) {
      setRootActive("");
    } else {
      setRootActive(value);
    }
  };

  const changeGroup = (value, heightPlus) => {
    if (groupActive === value) {
      setGroupActive("");
      setGroupHeightPlus(0);
    } else {
      setGroupActive(value);
      if (heightPlus) {
        setGroupHeightPlus(heightPlus);
      }
    }
  };

  const logout = () => {
    Swal.fire({
      icon: "warning",
      title: "Logout",
      text: "Apakah kamu yakin ingin keluar ?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    })
      .then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("group");
          localStorage.clear()
          history.push("/login")
        }
      })
  };




  return (
    <RootContext.Consumer>
      {({ state }) => {
        return (
          <>
            <AdminNavbar
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
            <div
              className={`h-screen bg-blue-900 fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl w-64 z-10 py-4 transition-all duration-300`}
            >
              <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                <div className="px-6  text-white">
                  <div className="flex flex-row">
                    <div className="text-xl">
                      Agent 
                    </div>
                    <div className="text-sm m-2">
                      Backoffice
                    </div>
                  </div>
                  <hr className="my-4 min-w-full" />
                </div>
                <div className="flex flex-col">
                  <ul className="flex-col min-w-full flex list-none">
                    {Object.keys(state.menu).map((root,index) => {
                      return (
                        <li key={root}>
                          <div
                            onClick={() => changeRoot(root)}
                            className="justify-between cursor-pointer bg-blue-900 flex items-center gap-4 text-sm text-white font-light px-4 py-3"
                          >
                            {root}
                            <Icon
                              name={
                                rootActive === root
                                  ? "arrow_drop_up"
                                  : "arrow_drop_down"
                              }
                              size="2xl"
                            />
                          </div>
                          {Array.isArray(state.menu[root]) ? (
                            <div
                              
                              className={
                                rootActive === root
                                  ? "menu-drop-down-open"
                                  : "menu-drop-down-close"
                              }
                              style={{
                                height:
                                  rootActive === root
                                    ? state.menu[root].length * 48
                                    : 0,
                              }}
                            >
                              {state.menu[root].map((menu) => {
                                return (
                                  <NavLink
                                    key={menu.name}
                                    to={menu.route}
                                    className="cursor-pointer flex items-center gap-4 text-sm text-white font-light px-4 py-3"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                  >
                                    <Icon name={menu.icon} size="2xl" />
                                    {menu.name}
                                  </NavLink>
                                );
                              })}
                            </div>
                          ) : (
                            <div
                              className={
                                rootActive === root
                                  ? "menu-drop-down-open"
                                  : "menu-drop-down-close"
                              }
                              style={{
                                height:
                                  rootActive === root
                                    ? Object.keys(state.menu[root]).length *
                                    48 +
                                    groupHeightPlus
                                    : 0,
                              }}
                            >
                              {Object.keys(state.menu[root]).map((group,index) => {
                                return (
                                  
                                    <Fragment key={index} >
                                      <div
                                    key={group + (index++).toString()}
                                    onClick={() => changeGroup(
                                      group,
                                      state.menu[root][group].length * 48
                                    )}
                                    className=" justify-between cursor-pointer bg-blue-700 pl-5 flex items-center gap-4 text-sm text-white font-light px-4 py-3"
                                  >
                                    {group}
                                    <Icon
                                      name={groupActive === group
                                        ? "arrow_drop_up"
                                        : "arrow_drop_down"}
                                      size="2xl" />
                                  </div><div key={group + "__" + (index++).toString()}
                                    className={groupActive === group
                                      ? "menu-drop-down-open"
                                      : "menu-drop-down-close"}
                                    style={{
                                      height: groupActive === group
                                        ? state.menu[root][group].length *
                                        48
                                        : 0
                                    }}
                                  >
                                      {state.menu[root][group].map((menu) => {
                                        return (
                                          <NavLink
                                            key={menu.name}
                                            to={menu.route}
                                            className="cursor-pointer flex items-center gap-4 text-sm text-white font-light px-4 py-3"
                                            activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                          >
                                            <Icon name={menu.icon} size="2xl" />
                                            {menu.name}
                                          </NavLink>
                                        );
                                      })}
                                    </div>
                                    </Fragment >
                                 
                                );
                              })}
                            </div>
                          )}
                        </li>
                      );
                    })}
                    <li>
                      <div
                        className="cursor-pointer flex items-center gap-4 text-sm text-white font-light px-4 py-3 rounded-lg"
                        onClick={logout}
                      >
                        <Icon name="logout" size="2xl" />
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </RootContext.Consumer>
  );
}

import React from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";

const MemberMenu = () => {
  return (
    <div>
    <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
    <div className="px-3 md:px-8 h-auto -mt-24">
      <div className="container mx-auto max-w-full">
        <div className="grid grid-cols-1 px-4 mb-16">
          <Card>
            <CardHeader color="blueGray" contentPosition="left">
              <h2 className="text-white text-2xl">Member Menu</h2>
            </CardHeader>
            <CardBody>
              <p>Member Menu</p>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  </div>
  )
}

export default MemberMenu;
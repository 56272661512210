import React, { useContext, useState, useEffect } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import Textarea from "@material-tailwind/react/Textarea";
import axiosInstance from '../../config/axios'
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
// import SelectRequired from "../../components/RequiredSelect";
// import { InputIcon } from "@material-tailwind/react";



const DepositRequestNew = () => {

    const [isSubmit, setIsSubmit] = useState(false)
    const history = useHistory()
    const { state, setState } = useContext(RootContext)
    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"))
    const [data, setData] = useState({
        username: null,
        amount: null,
        description: null,
        receiver_bank: null,
        receiver_name: null,
        receiver_num: null,
    })

    const verifyToken = () => {
        const token = localStorage.getItem("access_token");
        if (!token) {
            history.push("/login");
        } else {
            try {
                axiosInstance("verify_token", {
                    method: "POST",
                    data: {
                        token: token,
                    },
                })
                    .then((res) => {
                        setState({
                            ...state,
                            group: res.data.data.group,
                            menu: refactorStructureData(res.data.data.menu),
                        });
                        if (!localStorage.getItem("group")) {
                            localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
                        }
                    })
                    .catch((err) => {

                        localStorage.removeItem("access_token");
                        history.push("/login");
                    });

            } catch (error) {
                console.log("ERROR: ", error);
            }
        }
    };

    const submit = (event) => {

        setIsSubmit(true)



        axiosInstance.post('create_withdraw_request', {
            token: token,
            group: group.wbid,
            ...data,
            admin_note: 'withdraw'
        }).then((res) => {
            setIsSubmit(false)
            switch (res.data.code) {
                case 203:
                    Swal.fire(res.data.message, 'Player not found', 'error')
                    break
                case 402:
                    Swal.fire(res.data.message, 'Player has pending', 'warning')
                    break
                case 200:
                    Swal.fire(res.data.message, 'Success', 'success')
                    history.push('/money_management/withdraw_request')
                    break
                default:
                    Swal.fire(res.data.message, 'Success', 'success')
                    history.push('/money_management/withdraw_request')
            }

        }).catch((err) => {
            setIsSubmit(false)
            Swal.fire("Failed", 'Withdraw manual is failed', 'error')
            console.log(err.response)
        })


        event.preventDefault()
    }

    useEffect(() => {
        verifyToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <Card>
                            <CardHeader color="blueGray" contentPosition="left">
                                <h2 className="text-white text-2xl">Manual Withdraw</h2>
                            </CardHeader>
                            <CardBody  >
                                <form onSubmit={submit} className="flex flex-col gap-4">
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Username"
                                        required
                                        onChange={(event) => {
                                            const state = { ...data, username: event.target.value }
                                            setData(state)
                                        }}

                                    />
                                    <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Amount"
                                        required
                                        onChange={(event) => {
                                            const state = { ...data, amount: event.target.value }
                                            setData(state)
                                        }}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}

                                    />
                                    <Textarea
                                        type="textarea"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Description"
                                        onChange={(event) => {
                                            const state = { ...data, description: event.target.value }
                                            setData(state)
                                        }}

                                    />
                                    {/* <Input
                                        type="text"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Receiver Name"
                                        disabled
                                        required
                                        defaultValue={data.receiver_name} */}

                                    {/* // />
                                    // <Input */}
                                    {/* //     type="number"
                                    //     color="lightBlue"
                                    //     size="regular"
                                    //     outline={true}
                                    //     placeholder="Receiver Number"
                                    //     required
                                    //     disabled
                                    //     defaultValue={data.receiver_num}
                                    // /> */}
                                    {/* <InputIcon
                                        type="number"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Percentage"
                                        required
                                        disabled
                                        defaultValue={data.percentage}
                                        iconName={"%"}
                                    /> */}

                                    <Button
                                        color="lightBlue"
                                        buttonType="filled"
                                        size="regular"
                                        rounded={false}
                                        block={false}
                                        iconOnly={false}
                                        ripple="light"
                                        disabled={isSubmit}
                                    >
                                        {isSubmit ? "Loading" : "Save"}
                                    </Button>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DepositRequestNew;

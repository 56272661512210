import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
import { Label } from "@material-tailwind/react";
import Select from 'react-select'
export default function TableAdjustmentDetail({ data, pending, usernameEvent, dateEvent, providerEvent ,listProvider }) {
  const columns = [
    {
      name: "Username",
      selector: (row) => row.unam,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.desc,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amou,
      sortable: true,
    },
    {
      name: "Wallet to",
      selector: (row) => row.wato,
      sortable: true,
    },
    {
      name: "Mutation",
      selector: (row) => row.mtype === 0 ? <Label color={"green"}>Debit</Label> : <Label color={"red"}>Credit</Label>,
      sortable: true,
    },
    {
      name: "Agent Records",
      selector: (row) => row.arec,
      sortable: true,
      width: '240px'
    },
    {
      name: "Manual",
      selector: (row) => row.isma === 1 ? <Label color={"green"}>Admin</Label> : <Label color={"blue"}>Member</Label>,
      sortable: true,
    },
    {
      name: "Approved",
      selector: (row) => row.isap === 1 ? <Label color={"green"}>Approved</Label> : <Label color={"yellow"}>Pending</Label>,
      sortable: true,
    },
    {
      name: "Done",
      selector: (row) => row.isdo ? <Label color={"green"}>Done</Label> : <Label color={"yellow"}>Pending</Label>,
      sortable: true,
    },

    {
      name: "Agent",
      selector: (row) => row.agen === 1 ? <Label color={"green"}>Admin</Label> : <Label color={"blue"}>Member</Label>,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row.rdat,
      sortable: true,
    },
    {
      name: "Process Date",
      selector: (row) => row.pdat,
      sortable: true,
    },
  ];


  return (
    <>
      <Card className="">
        <h5>Advanced search</h5>
        <div className="flex gap-4">
          <InputIcon
            type="text"
            color="lightBlue"
            size="sm"
            outline={true}
            placeholder="Username"
            iconName="search"
            onChange={usernameEvent}
          ></InputIcon>
          <InputIcon
            type="date"
            color="lightBlue"
            size="sm"
            outline={true}
            placeholder="Date"
            iconName=""
            onChange={dateEvent}
          ></InputIcon>
        </div>
        <div className="flex gap-4 mt-4">
          <div style={{ width: '100%' }}>
            <Select options={listProvider} placeholder="Wallet" onChange={providerEvent} />
          </div>
       
        </div>
      </Card>
      <Card className="mt-12">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Adjustment Transaction</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={data}
            progressPending={pending}
            pagination
          />
        </CardBody>
      </Card>
    </>
  );
}

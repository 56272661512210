import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";
import { Label } from "@material-tailwind/react";
import axiosInstance from "../config/axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function TableAdjustmentRequset({ data, pending, usernameEvent, acceptEvent, dateEvent, onChangeRowsPerPage }) {
  const token = localStorage.getItem("access_token");

  const group = JSON.parse(localStorage.getItem("group"))


  const accept = (event, id) => {
    const data = {
      token: token,
      group: group.wbid,
      req_id: id
    }

    axiosInstance.post('adjustment_accept', data).then((res) => {
      Swal.fire(
        res.data.message,
        res.data.message,
        'success'
      )
    }).catch((err) => {
      console.log(err)
    })
    event.preventDefault()
  }

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.unam,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.desc,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amou,
      sortable: true,
    },
    {
      name: "Wallet To",
      selector: (row) => row.wato,
      sortable: true,
    },
    {
      name: "Mutation Type",
      selector: (row) => (row.mtyp === 1 ? <Label color={"red"}>Debit</Label> : <Label color={"green"}>Credit</Label>),
      sortable: true,
    },
    {
      name: "Manual",
      selector: (row) => (row.isma === 1 ? <Label color={"green"}>Admin</Label> : <Label color={"blue"}>Member</Label>),
      sortable: true,
    },
    {
      name: "Approved",
      selector: (row) => (row.isap === 1 ? <Label color={"green"}>Approved</Label> : <Label color={"yellow"}>Pending</Label>),
      sortable: true,
    },
    {
      name: "Done",
      selector: (row) => (row.isdo === 1 ? <Label color={"green"}>Done</Label> : <Label color={"yellow"}>Pending</Label>),
      sortable: true,
    },
    {
      name: "Register date",
      selector: (row) => row.rdat,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => (
        <div className="flex gap-4">
          <Button
            color="green"
            buttonType="filled"
            size="sm"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
            onClick={(event) => {
              accept(event, row.id)
              acceptEvent()
            }}
          >
            Accept
          </Button>
        </div>

      ),
      sortable: true,
      width: '250px'
    },
  ];


  return (
    <>
      <Card>
        <h5>Advanced Search</h5>
        <div className="flex gap-4">
          <InputIcon
            type="text"
            color="lightBlue"
            size="sm"
            outline={true}
            placeholder="Username"
            iconName="search"
            onChange={usernameEvent}
          />
          <InputIcon
            type="date"
            color="lightBlue"
            size="sm"
            outline={true}
            placeholder="Date"
            iconName=""
            onChange={dateEvent}
          />
        </div>
      </Card>
      <Card className="mt-12">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Adjustment Request</h2>
        </CardHeader>
        <CardBody>
          <div className="flex justify-between">
            <div>
              <Link to={"/money_management/create_adjustment_request"}>
                <Button
                  color="lightBlue"
                  buttonType="filled"
                  size="regular"
                  rounded={false}
                  block={false}
                  iconOnly={false}
                  ripple="light"
                >
                  Manual Adjustment
                </Button>
              </Link>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={data}
            progressPending={pending}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            pagination
          />
        </CardBody>
      </Card>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import TableBonusDepositRequest from "../../components/TableBonusDepositRequest";
import axiosInstance from "../../config/axios";
import { useHistory } from "react-router-dom";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";

export default function DepositRequest() {
  const [data, setData] = useState();
  const [pending, setPending] = useState(true);
  const [accept, setAccept] = useState(0)
  const [decline, setDecline] = useState(0)
  const history = useHistory();
  const { state, setState } = useContext(RootContext)

  useEffect(() => {
    // console.log(decline)
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accept, decline])

  const getData = () => {

    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"))
    const data = {
      token: token,
      group: group.wbid,
    }
    if (!token) {
      history.push("/login");
    } else {
      try {

        axiosInstance("get_bonus_req", {
          method: "POST",
          data,
        })
          .then((res) => {
            const data = res.data.data.list
            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            })
            setData(data)
          })
          .then(() => {
            setPending(false);
          })
          .catch((err) => {
            localStorage.removeItem("access_token");
            history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
        localStorage.removeItem("access_token");
        history.push("/login");
      }
    }
  };




  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableBonusDepositRequest
              title="Player List"
              data={data}
              pending={pending}
              declineEvent={() => {
                setTimeout(() => {
                  setDecline(decline + 1)
                }, 500);
              }}
              acceptEvent={() => {
                setTimeout(() => {
                  setAccept(accept + 1)
                }, 500);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

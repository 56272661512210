import React, { useContext, useState, useEffect } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import TableUserList from '../../../components/TableUserList';
import { RootContext } from '../../../App';
import axiosInstance from '../../../config/axios'
import refactorStructureData from '../../../helpers/refactorDataMenu';
import { Button} from '@material-tailwind/react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Link } from 'react-router-dom';
import InputIcon from "@material-tailwind/react/InputIcon";

const UserList = () => {

  const token = localStorage.getItem("access_token")
  const group = JSON.parse(localStorage.getItem("group"))
  const [data, setData] = useState([])
  const { state, setState } = useContext(RootContext)
  const [isLoading, setIsLoading] = useState(true)
  const [length, setLength] = useState(10)
  const [username, setUsername] = useState(null)

  const debounced = useDebouncedCallback(
    (action) => {
      action()
    },
    200
  );

  useEffect(() => {

    let datatosend = {
      token: token,
      group: group.wbid,
      lgth: length,
      username: username
    }

    //console.log("data to send: "+JSON.stringify(datatosend));

    axiosInstance.post('get_admin_list', datatosend).then((res) => {
      //console.log("respon: "+JSON.stringify(res.data.data) )
      setIsLoading(false)
      setData(res.data.data.list)
      setState({
        ...state,
        group: res.data.data.additional_data.group,
        menu: refactorStructureData(res.data.data.additional_data.menu),
      })

    }).catch((err) => {
      //console.log(err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, username])

  return (
    <div>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardBody>
                <h5>Advanced search</h5>
                <div className='flex gap-4 mt-2'>
                  <InputIcon
                    onChange={(event) => debounced(() => setUsername(event.target.value))}
                    iconName="search"  outline={true} placeholder="Username" />
                </div>
              </CardBody>
            </Card>
            <Card className="mt-10">
              <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">User List</h2>
              </CardHeader>
              <CardBody>
                <Link to="/web_management/users/user_list_new">
                  <Button>New User</Button>
                </Link>
                <TableUserList
                  data={data} pending={isLoading}
                  onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                    setLength(currentRowsPerPage)
                  }} />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserList;
import React, { useContext, useEffect, useState } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
// import { Button, Input, Textarea } from '@material-tailwind/react';
import { Button, Input } from '@material-tailwind/react';
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import axiosInstance from '../../../config/axios'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import SelectRequired from '../../../components/RequiredSelect';
import Loading from '../../../components/Loading';
const MetaUpdate = () => {
    const { state, setState } = useContext(RootContext)
    const token = localStorage.getItem("access_token");
    const [isSubmit, setIsSubmit] = useState(false)
    const history = useHistory()
    const group = JSON.parse(localStorage.getItem("group"))
    const [value, setValue] = useState(null)
    const { id } = useParams()
    const getMeta = () => {

        axiosInstance("get_site_meta_detail", {
            method: "POST",
            data: {
                token: token,
                group: group.wbid,
                id: id
            },
        })
            .then((res) => {
                setValue(res.data.data.list[0])
                setState({
                    ...state,
                    group: res.data.data.additional_data.group,
                    menu: refactorStructureData(res.data.data.additional_data.menu),
                })
                if (!localStorage.getItem("group")) {
                    localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
                }
            })
            .catch((err) => {
                console.log(err)
            });


    };

    useEffect(() => {
        getMeta()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleSubmit = (event) => {
        event.preventDefault()
        setIsSubmit(true)
        var bodyFormData = new FormData()
        bodyFormData.append("token", token)
        bodyFormData.append('group', group.wbid)
        bodyFormData.append('id', id)
        bodyFormData.append('url', value.url)
        bodyFormData.append('metadata', value.mdat)
        bodyFormData.append('isactive', value.actv)
        axiosInstance.post('update_site_meta', bodyFormData).then((res) => {
            history.push('/web_management/cms/meta')
            Swal.fire("Success", "Meta data is update", 'success')
        }).catch((err) => {
            console.log(err.response)
        })
    }



    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            {value ? (
                <div className="px-3 md:px-8 h-auto -mt-24">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 px-4 mb-16">
                            <Card>
                                <CardHeader color="blueGray" contentPosition="left">
                                    <h2 className="text-white text-2xl">Update Meta</h2>
                                </CardHeader>
                                <CardBody >
                                    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                                        <Input
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Url"
                                            defaultValue={value?.url}
                                            onChange={(event) => setValue({ ...value, url: event.target.value })}
                                            required
                                        />
                                        <Input
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Metadata site"
                                            defaultValue={value?.mdat}
                                            onChange={(event) => setValue({ ...value, mdat: event.target.value })}
                                            required
                                        />

                                        <SelectRequired
                                            onChange={(event) => { setValue({ ...value, actv: event.value }) }}
                                            value={[{ label: 'active', value: 1 }, { label: 'disactive', value: 0 }].find((data) => data.value === value.actv)}
                                            options={[{ label: 'active', value: 1 }, { label: 'disactive', value: 0 }]}
                                        />
                                        <Button disabled={isSubmit}>Save</Button>
                                    </form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            ) : <Loading />}
        </div>
    )
}

export default MetaUpdate;
import { useState, createContext} from "react";
import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Sidebar from "components/Sidebar";
import Footer from "components/Footer";
import Error404 from "./pages/Error404";
import Login from "./pages/Login";
import routes from "./routes";

// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";
import "assets/styles/custom.css";

export const RootContext = createContext();
const Provider = RootContext.Provider;

function App() {
  const location = useLocation();
 

  const [state, setState] = useState({
    group: [],
    menu: [],
    selectedGroup: 1,
    detailPlayer: {}
  });



  return (
    <>
      <Provider value={{ state, setState }}>
        {location.pathname === "/login" ? (
          <Switch>
            <Route exact path="/login" component={Login} />
          </Switch>
        ) : (
          <>
            <Sidebar />
            <div className="md:ml-64">
              <Switch>
                {routes.map((route) => (
                  <Route
                    key={route.id}
                    exact={route.exact}
                    path={route.path}
                    render={() => {
                      return route.component;
                    }}
                  />
                ))}
                <Route path="*" exact={true} component={Error404} />
                <Footer />
              </Switch>
            </div>
          </>
        )}
      </Provider>
    </>
  );
}

export default App;

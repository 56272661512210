import React, { useContext, useEffect, useState } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import axiosInstance from '../../../config/axios';
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@material-tailwind/react";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReactLoading from "react-loading";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';

/*
* page ini menggunakan icon mui material, untuk mengubah atau menambah icon
* bisa dilihat di page ini sebagai referensi
* https://mui.com/material-ui/material-icons/
*
*/

const SlideshowBanner = () => {

  const { state, setState } = useContext(RootContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const[listData, setListData] = useState();
  const history = useHistory();
  const [delImageId, setDelImageId] = useState();
  const group = JSON.parse(localStorage.getItem("group"));

  const handleDelete = () => {

    const token = localStorage.getItem("access_token");

    axiosInstance.post('delete_baner', {

      token: token,
      group: group.wbid,
      id: delImageId,

    }).then((res) => {

      setIsModalOpen(false);

      setLoading(false);

      if(res.data.code === 0){

        Swal.fire(
          'Deleted!',
          'Your data is deleted!',
          'success'
        ).then( () => {

          getSlideShowBanner();

        });
      }else{

        Swal.fire(
          'Failed!',
          'Failed Upload!',
          'Warning'
        );

        console.log(res.data.message);
      }

    }).catch((err) => {

      console.log(err);

    })

  }

  const getSlideShowBanner = () => {

    const token = localStorage.getItem("access_token");

    axiosInstance.post('get_baner', {

      token: token,
      group: group.wbid,

    }).then((res) => {

      const data = res.data.data.list;

      setListData(data);

      setState({

        ...state,

        group: res.data.data.additional_data.group,

        menu: refactorStructureData(res.data.data.additional_data.menu),

      });

    }).catch((err) => {

      console.log(err);

    })

  }

  useEffect(() => {

    getSlideShowBanner();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>

    {!listData ? (

      <div className="flex justify-center" style={{ marginTop: 150 }}>

        <ReactLoading
          type="spinningBubbles"
          color="rgb(53, 126, 221)"
          height={100}
          width={100}
        />

      </div>

    ) : (

    <div>

      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>

      <div className="px-3 md:px-8 h-auto -mt-24">

        <div className="container mx-auto max-w-full">

          <div className="grid grid-cols-1 px-4 mb-16">

            <Card>

              <CardHeader color="blueGray" contentPosition="left">

                <div className='flex justify-between ' style={{ width: '900px' }}>

                  <h2 className="text-white text-2xl">Slideshow Banner</h2>

                  <Link to="/web_management/cms/create_baner">

                    <Button >

                      Add image
                      
                    </Button>

                  </Link>

                </div>

              </CardHeader>

              <CardBody>
                <ImageList>

                  {listData.map((item) => (

                    <ImageListItem key={item.image_url}>

                      <img
                        src={`${item.image_url}?w=248&fit=crop&auto=format`}
                        srcSet={`${item.image_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                        onClick={() => {

                          history.push('/web_management/cms/update_baner/'+item.id);

                        }}
                      />

                      <ImageListItemBar
                        title={item.title+' [Click image to change]'}
                        actionIcon={
                          <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            onClick={() => {

                              setIsModalOpen(true);

                              setDelImageId(item.id);

                            }}
                          >
                            <DeleteForeverIcon
                              color='error' 
                            />
                          </IconButton>
                        }
                      />

                    </ImageListItem>

                  ))}

                </ImageList>

              </CardBody>

            </Card>

            <Modal
              size="sm"
              active={isModalOpen}
              toggler={() => setIsModalOpen(false)}
            >

              <ModalHeader toggler={() => setIsModalOpen(false)}>

                Delete Image

              </ModalHeader>

              <ModalBody>

                  <div className='flex flex-col space-y-4 w-72'>

                      <p className="text-xs">Are you sure to delete this image ?</p>
                  
                  </div>

              </ModalBody>

              <ModalFooter>

                <Button
                    color="red"
                    buttonType="link"
                    onClick={() => setIsModalOpen(false)}
                    ripple="dark"
                >
                  Cancel

                </Button>

                <Button
                  color="red"
                  ripple="light"
                  onClick={() => {

                    handleDelete();

                    setLoading(true);

                  }}
                    
                >

                  {loading ? 'Loading...' : 'Delete'}
                  
                </Button>

              </ModalFooter>

            </Modal>

          </div>

        </div >

      </div >

    </div >
    )}
    </>

  )

}

export default SlideshowBanner;
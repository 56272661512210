import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import TablePlayerReferralDetail from '../../components/TablePlayerReferralDetail'
import { RootContext } from '../../App';
import refactorStructureData from '../../helpers/refactorDataMenu';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
export default function PlayerReferralDetail() {

  const history = useHistory();

  const param = useParams();

  const [playerReferral, setPlayerReferral] = useState();

  const [pending, setPending] = useState(true);

  const [dwluser, setDwluser] = useState(null);

  const [MinimalWL, setMinimalWL] = useState(null);

  const [MaximalWL, setMaximalWL] = useState(null);

  const [MinimalDeposit, setMinimalDeposit] = useState(null);

  const [MaximalDeposit, setMaximalDeposit] = useState(null);

  const [length, setLength] = useState(10);

  const { state, setState } = useContext(RootContext);

  const originTime = new Date();
  const nowDate = originTime.toISOString().slice(0, 10);
  originTime.setDate( originTime.getDate() - 30 );
  const fromDate = originTime.toISOString().slice(0, 10);

  const [fromCreateAt, setFromCreateAt] = useState(fromDate);

  const [toCreateAt, setToCreateAt] = useState(nowDate);

  const debounced = useDebouncedCallback(
    (action) => {

      action();
    },
    200
  );


  useEffect(() => {

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dwluser, toCreateAt, fromCreateAt, length, MinimalWL, MaximalWL, MinimalDeposit, MaximalDeposit])

  const getData = () => {
    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"))

    if (!token) {
      history.push("/login");
    } else {
      try {
        axiosInstance("get_user_referral_detail", {

          method: "POST",
          data: {
            token: token,
            group: group.wbid,
            username: param.user,
            dwluser: dwluser,
            fromdate: fromCreateAt,
            todate: toCreateAt,
            maxwl: MaximalWL,
            minwl: MinimalWL,
            maxdp: MaximalDeposit,
            mindp: MinimalDeposit,
          },
        }).then((res) => {

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

            setPending(false);
            setPlayerReferral(res.data.data.list);
          })
          .catch((err) => {

            console.log("error: "+err);

            Swal.fire(

              'Error',
              'get data failed',
              'warning'
            );

            setPending(false);

            //localStorage.removeItem("access_token");
            //history.push("/login");
          });

      } catch (error) {

        console.log("ERROR: ", error);
      }
    }
  };


  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto">
      </div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16 ">
            <div>
              <TablePlayerReferralDetail
                title="Referal Detail" 
                playerReferral={playerReferral}
                detailName={param.user}
                fromDate={fromCreateAt}
                toDate={toCreateAt}
                pending={pending}
                usernameEvent={(event) => {
                  debounced(() => setDwluser(event.target.value))
                }}

                fromCreateAtEvent={(event) => {
                  debounced(() => setFromCreateAt(event.target.value))
                }}

                toCreateAtEvent={(event) => {
                  debounced(() => setToCreateAt(event.target.value))
                }}

                MinimalWLEvent={(event) => {
                  debounced(() => setMinimalWL(event.target.value))
                }}

                MaximalWLEvent={(event) => {
                  debounced(() => setMaximalWL(event.target.value))
                }}

                MinimalDepositEvent={(event) => {
                  debounced(() => setMinimalDeposit(event.target.value))
                }}

                MaximalDepositEvent={(event) => {
                  debounced(() => setMaximalDeposit(event.target.value))
                }}

                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                  setLength(currentRowsPerPage)
                }}

              />
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import { Button, Input, Textarea } from '@material-tailwind/react';
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import axiosInstance from '../../../config/axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
const SiteConfigNew = () => {
  const { state, setState } = useContext(RootContext)
  const token = localStorage.getItem("access_token");
  const [isSubmit, setIsSubmit] = useState(false)
  const history = useHistory()
  const group = JSON.parse(localStorage.getItem("group"))
  const [value, setValue] = useState({
    web_name: "test",
    web_livechat: "a",
  })

  const verifyToken = () => {
    if (!token) {
      history.push("/login");
    } else {
      try {
        axiosInstance("verify_token", {
          method: "POST",
          data: {
            token: token,
            group: group.wbid
          },
        })
          .then((res) => {
            setState({
              ...state,
              group: res.data.data.group,
              menu: refactorStructureData(res.data.data.menu),
            });
            if (!localStorage.getItem("group")) {
              localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
            }
          })
          .catch((err) => {

            localStorage.removeItem("access_token");
            history.push("/login");
          });

      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  useEffect(() => {
    verifyToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const handleSubmit = (event) => {
    setIsSubmit(true)
    var bodyFormData = new FormData()
    bodyFormData.append("token", token)
    bodyFormData.append('group', group.wbid)
    bodyFormData.append('web_logo', value.web_logo)
    bodyFormData.append('web_name', value.web_name)
    bodyFormData.append('web_livechat', value.web_livechat)
    bodyFormData.append('web_livechat_link', value.web_livechat_link)
    bodyFormData.append('global_meta_tag', value.global_meta_tag)
    bodyFormData.append('web_running_text', value.web_running_text)
    axiosInstance.post('create_set_config', bodyFormData).then((res) => {
      Swal.fire('Success', 'Data is saved', 'success')
      history.push('/web_management/cms/site_config')
    }).catch((err) => {
      console.log(err)
      Swal.fire(err.response.data.data.web_logo[0], 'Please choose .jpg .png file', 'error')
    })
    event.preventDefault()
  }

  return (
    <div>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="blueGray" contentPosition="left">
                <h2 className="text-white text-2xl">Create Site Config</h2>
              </CardHeader>
              <CardBody >
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                  <Input
                    type="text"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Website Name"
                    onChange={(event) => setValue({ ...value, web_name: event.target.value })}
                    required
                  />
                  <Input
                    type="text"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Running Text"
                    onChange={(event) => setValue({ ...value, web_running_text: event.target.value })}
                    required
                  />
                  <Textarea
                    type="text"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Global Meta Tag"
                    onChange={(event) => setValue({ ...value, global_meta_tag: event.target.value })}

                    required
                  />
                  <Textarea
                    type="text"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Live Chat Script "
                    onChange={(event) => setValue({ ...value, web_livechat: event.target.value })}

                    required
                  />
                  <Input
                    type="text"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Livechat Link"
                    onChange={(event) => setValue({ ...value, web_livechat_link: event.target.value })}

                    required
                  />
                  <Input
                    type="file"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Website Logo"
                    onChange={(event) => {
                      setValue({ ...value, web_logo: event.target.files[0] })
                    }}
                    required
                  />
                  <div>
                    <Button disabled={isSubmit}>
                      {isSubmit ? "Loading" : "Save"}
                    </Button>
                  </div>


                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteConfigNew;
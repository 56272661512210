/*
* komponen ini menggunakan material tailwind, jika ingin mengubah atau menambah
* lihat halaman ini sebagai referensi
* https://www.material-tailwind.com/docs/react/installation
*
*/

import { RootContext } from '../../App';
import axiosInstance from '../../config/axios';
import refactorStructureData from '../../helpers/refactorDataMenu';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import TableDrawResult from '../../components/TableDrawResult';
import Select from "react-select";

export default function LotteryResult() {

    const [listData, setListData] = useState();
    const [saving, setSaving] = useState(false);
    const { state, setState } = useContext(RootContext);
    const history = useHistory();
    const group = JSON.parse(localStorage.getItem("group"));
    const [optionValue, setOptionValue] = useState();

    const optionselect = [
        { value: 'singapore', label: 'Singapore' },
        { value: 'hongkong', label: 'Hongkong' },
        { value: 'sydney', label: 'Sydney' },
        { value: 'taiwan', label: 'Taiwan' },
        { value: 'cambodia', label: 'Cambodia' }
    ];

    const getData = () => {

        setSaving(true);

        const token = localStorage.getItem("access_token");

        const data = {

            token: token,
            group: group.wbid,
            market: optionValue,

        }

        if (!token) {

            history.push("/login");
            console.log('Not Valid Token');

        } else {

            try {

                console.log('send request get result');

                axiosInstance('/lottery/get-result', {

                    method: "POST",

                    data,

                }).then((res) => {

                    console.log('get respon result');
                    setSaving(false);
                    const data = res.data.data.list;

                    setListData(data);

                    setState({

                        ...state,

                        group: res.data.data.additional_data.group,

                        menu: refactorStructureData(res.data.data.additional_data.menu),

                    });

                }).catch((err) => {

                    let errstatus = err.response.status;

                    let errmesage = err.response.data.message;

                    if (errstatus === 401) {

                        Swal.fire(
                            'Expire Token',
                            'Your Token Has Expire',
                            'warning'
                        ).then(() => {

                            localStorage.removeItem("access_token");

                            history.push("/login");
                        });

                    } else {

                        Swal.fire(
                            'Warning',
                            errmesage,
                            'warning'
                        )
                    }

                });

            } catch (error) {

                Swal.fire(
                    'Warning',
                    error,
                    'warning'
                )

            }

        }

    };

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionValue]);

    return (
        <>
            <Card>
                <CardHeader color="blueGray" contentPosition="none">
                    <div className="w-full flex items-center justify-between">
                        <h2 className="text-white text-2xl">Result</h2>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-3 bg-gray-100 px-2 py-4">
                            <label for="testSelect">Select a Market</label>
                            <Select
                                className='block w-full'
                                onChange={(e) => {

                                    setOptionValue(e.value)
                                }}
                                options={optionselect}

                            />
                        </div>
                    </div>
                    <TableDrawResult
                        data={listData}
                        pending={saving}
                    />
                </CardBody>
            </Card>
        </>
    );
}

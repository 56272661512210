import React, { useContext, useEffect, useState } from "react";
import TableAgentReport from "components/TableAgentReport";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import refactorStructureData from "../../helpers/refactorDataMenu";
import { RootContext } from '../../App';

export default function BettingDetail() {
    const history = useHistory();

    const [period, setPeriod] = useState(null);
    const [month, setMonth] = useState(null);
    const [monthName, setMonthName] = useState(null);
    const [reportData, setreportData] = useState();
    const [pending, setPending] = useState(true);

    const debounced = useDebouncedCallback( (action) => { action() }, 200 );
    const token     = localStorage.getItem("access_token");
    const group     = JSON.parse(localStorage.getItem("group"));
    
    const { state, setState } = useContext(RootContext);
    
    useEffect(() => { if (month != null) { convertMonth(); setreportData([]); } }, [ month ]);
    useEffect(() => { getDataReport() }, [ period ]);

    const convertMonth = () => {
        const currentYear   = new Date().getFullYear();
        const startOfMonth  = new Date(currentYear, month, 1);

        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        setPeriod( formatDate(startOfMonth) );
    }

    const getDataReport = () => {
        const date      = period ? new Date(period) : new Date();
        const fromDate  = new Date(date.getFullYear(), date.getMonth(), 1);
        const todate    = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        const chgDate   = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            
            const getMonthName = (date) => {
                const monthNames = [
                    'January', 'February', 'March', 'April', 'May', 'June',
                    'July', 'August', 'September', 'October', 'November', 'December'
                ];

                return monthNames[date.getMonth()];
            };
    
            setMonthName( getMonthName(date) )
            
            return `${year}-${month}-${day}`;
        };

        const data = {
            token: token,
            group: group.wbid,
            fromdate: chgDate(fromDate),
            todate: chgDate(todate),
        };

        if (!token) { history.push("/login") };
        
        try {
            axiosInstance("agent_report", {
                method: "POST",
                data,
            })
            .then((res) => { 
                setState({
                    ...state,
                    group: res.data.data.additional_data.group,
                    menu: refactorStructureData(res.data.data.additional_data.menu),
                  });

                setreportData(res.data.data.list); 
                setPending(false); 
            }).catch((err) => {  });
        } catch (error) {
            
        }
    }

    return (<>
        <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
        <div className="px-3 md:px-8 h-auto -mt-24">
            <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-1 px-4 mb-16">
                    <TableAgentReport
                        title="Agent report"
                        reportData={reportData}
                        monthName={monthName}
                        pending={pending}
                        periodEvent={(event) => {
                            debounced(() => { setMonth(event.value); })
                        }}

                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => { }}
                    />
                </div>
            </div>
        </div>
    </>);
}
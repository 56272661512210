import { useState, useEffect } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import InputIcon from "@material-tailwind/react/InputIcon";
import Checkbox from "@material-tailwind/react/Checkbox";
import Button from "@material-tailwind/react/Button";
import Page from "../components/login/Page";
import Container from "../components/login/Container";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { RootContext } from "../App";

import axios from "axios";

export default function Login() {
  let history = useHistory();
  // const { t, i18n } = useTranslation();
  const { t } = useTranslation();
  const [dataLogin, setDataLogin] = useState({
    username: "",
    password: "",
  });

  const changeValueLogin = (key, value) => {
    if (key === "username") {
      setDataLogin({
        username: value,
        password: dataLogin.password,
      });
    } else if (key === "password") {
      setDataLogin({
        username: dataLogin.username,
        password: value,
      });
    }
  };

  // const changeLanguage = lng => {
  //   i18n.changeLanguage(lng);
  // };

  const login = async (dispatch) => {

    try {

      axios
        .post(`${process.env.REACT_APP_URL_BACKEND}/api/bo/do_login`, dataLogin)
        .then((res) => {

          localStorage.setItem("access_token", res.data.data.token);
          localStorage.setItem("username", dataLogin.username);
          window.location.href = "/"
        })
        .then(() => {

          dispatch("VERIFY_TOKEN")
        })
        .catch((error) => {

          if(error.response){

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
            });
          }else{
           
          }
        });
    } catch (error) {

      console.log("ERROR: ", error);
    }
  };

  const handleKeyPress = (event, dispatch) => {

    if (event.key === "Enter") {
      login(dispatch);
    }
  };

  const checkIsLogin = () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      
      history.push("/");
    }
  };

  useEffect(() => {
    checkIsLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootContext.Consumer>
      {(ctx) => (
        <Page>
          <Container>
            <Card>
              <CardHeader color="lightBlue">
                <H5 color="white" style={{ marginBottom: 0 }}>
                  {t("login")}
                </H5>
              </CardHeader>

              <CardBody>
                <div className="mb-12 px-4 bg-bb">
                  <InputIcon
                    type="email"
                    color="lightBlue"
                    placeholder="username"
                    iconName="person"
                    onKeyPress={(e) => handleKeyPress(e, ctx.dispatch)}
                    onChange={(input) =>
                      changeValueLogin("username", input.target.value)
                    }
                  />
                </div>
                <div className="mb-8 px-4">
                  <InputIcon
                    type="password"
                    color="lightBlue"
                    placeholder="Password"
                    iconName="lock"
                    onKeyPress={(e) => handleKeyPress(e, ctx.dispatch)}
                    onChange={(input) =>
                      changeValueLogin("password", input.target.value)
                    }
                  />
                </div>
                <div className="mb-4 px-4">
                  <Checkbox
                    color="lightBlue"
                    text="Remember Me"
                    id="remember"
                  />
                </div>
              </CardBody>
              <CardFooter>
                <div className="flex justify-center bg-bb">
                  <Button
                    color="lightBlue"
                    buttonType="link"
                    size="lg"
                    ripple="dark"
                    onClick={() => login(ctx.dispatch)}
                  >
                    {t('login')}
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Container>
        </Page>
      )}
    </RootContext.Consumer>
  );
}

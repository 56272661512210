import React, { useEffect, useState } from 'react'
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import axiosInstance from "../config/axios";
import Swal from 'sweetalert2';
import Select from 'react-select';
// import SelectRequired from './RequiredSelect';

function PlayerBankInformationForm({ data }) {


    const [isChanged, setIsChanged] = useState(true)
    const [bankName, setBankName] = useState(data.bnam)
    const [bankAccount, setBankAccount] = useState(data.bacc)
    const [bankNumber, setBankNumber] = useState(data.bnum)
    const [optionBank, setOptionBank] = useState([])

    const listBankName = () => {
        axiosInstance.post('get_bank_main', {
            token: localStorage.getItem("access_token"),
            group: JSON.parse(localStorage.getItem("group")).wbid
        }).then((res) => {
            setOptionBank(res.data.data.list.map((data) => {
                return {
                    label: data.name,
                    value: data.name
                }
            }))
        })
    }

    useEffect(() => {
        listBankName()
    }, [])

    const submit = (event) => {

        const token = localStorage.getItem("access_token")
        const group = JSON.parse(localStorage.getItem("group"))
        delete data['bacc']
        delete data['bnum']
        delete data['bnam']

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance("set_player_det", {
                    method: "POST",
                    data: {
                        token: token,
                        group: group.wbid,
                        bnum: bankNumber,
                        bnam: bankName,
                        bacc: bankAccount,
                        flnm: data.name,
                        emal: data.emai,
                        ...data,
                        user_id: data.uid,

                    },
                }).then((res) => {
                    Swal.fire(
                        'Success!',
                        'Your data has been Updated',
                        'success'
                    )
                }).catch((err) => {
                    console.log(err)
                });

            }
        })

        event.preventDefault()
    }



    return (
        <>
            <Card className="mt-12" >
                <CardHeader size="sm" color="blueGray" contentPosition="left">
                    <div className='gap-4'>
                        <h2 className="text-white text-2xl">Information Bank</h2>
                    </div>
                    <Button onClick={() => setIsChanged(!isChanged)}>Change</Button>

                </CardHeader>
                <CardBody>
                    <form onSubmit={submit}>
                        <div className="grid grid-cols-1 gap-4">
                            <div className="">
                                <Select
                                    options={optionBank} onChange={(event) => {
                                        setBankName(event.value)
                                    }}
                                    isDisabled={isChanged}
                                    placeholder={data.bnam}
                                />

                            </div>
                            <div className="">
                                <Input
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Nama akun"
                                    defaultValue={data.bacc}
                                    disabled={isChanged}
                                    onChange={(event) => setBankAccount(event.target.value)}

                                />
                            </div>
                            <div className="">
                                <Input
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="No rekening"
                                    defaultValue={data.bnum}
                                    disabled={isChanged}
                                    onChange={(event) => setBankNumber(event.target.value)}


                                />
                            </div>
                        
                        </div>

                        <div className=" mt-4 flex">
                            <Button
                                buttonType="filled"
                                color={isChanged ? "gray" : "lightGreen"}
                                size="sm"
                                rounded={false}
                                block={false}
                                iconOnly={false}
                                ripple="light"
                                disabled={isChanged}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </CardBody>

            </Card>

        </>
    )
}

export default PlayerBankInformationForm
import Dashboard from "pages/Dashboard";
import PlayerList from "pages/PlayerManagement/PlayerList";
import PlayerRecord from "pages/PlayerManagement/PlayerRecords";
import PlayerMutation from "pages/PlayerManagement/PlayerMutation";
import PlayerReferral from "pages/PlayerManagement/PlayerReferral";
import PlayerReferralFirstDepo from "pages/PlayerManagement/PlayerReferralFirstDepo";
import PlayerReferralDetail from "pages/PlayerManagement/PlayerReferralDetail";
import UpdateLog from "pages/UpdateLog";
import CoinAdmin from "./pages/MoneyManagement/CoinAdmin";
import CoinHistory from "./pages/MoneyManagement/CoinHistory";
import DepositRequest from "./pages/MoneyManagement/DepositRequest";
import BonusDepositRequest from "./pages/MoneyManagement/BonusDepositRequest";
import WithdrawRequest from "./pages/MoneyManagement/WithdrawRequest";
import AdjustmentRequestDetail from "./pages/MoneyManagement/AdjustmentRequestDetail";
import AdjustmentRequest from "./pages/MoneyManagement/AdjustmentRequest";
import PlayerGrowth from "./pages/Reporting/PlayerGrowth";
import PlProvider from "./pages/Reporting/PlProvider";
import PlMember from "./pages/Reporting/PlMember";
import BettingDetail from "./pages/Reporting/BettingDetail";
import WalletHistory from "./pages/Reporting/WalletHistory";
import AgentReport from "./pages/Reporting/AgentReport";
import BankAccountNew from "./pages/Banking/BankAccountNew";
import BankGroup from "./pages/Banking/BankGroup";
import RegistrationBankList from "./pages/Banking/RegistrationBankList";
import ContactUs from "./pages/WebManagement/Cms/ContactUs";
import MemberMenu from "./pages/WebManagement/Cms/MemberMenu";
import Meta from "./pages/WebManagement/Cms/Meta";
import PromotionalBanner from "./pages/WebManagement/Cms/PromotionalBanner";
import SiteConfigNew from "./pages/WebManagement/Cms/SiteConfigNew";
import SlideshowBanner from "./pages/WebManagement/Cms/SlideshowBanner";
import AccessRights from "./pages/WebManagement/Users/AccessRights";
import UserList from "./pages/WebManagement/Users/UserList";
import UserLog from "./pages/WebManagement/Users/UserLog";
import DetailPlayer from "./pages/PlayerManagement/DetailPlayer";
import DepositRequestNew from "./pages/MoneyManagement/DepositRequestNew";
import DepositRequestDetail from "./pages/MoneyManagement/DepositRequestDetail";
import DepositRequestDetailUser from "./pages/MoneyManagement/DepositRequestDetailUser";
import DepositRequestHistory from "./pages/MoneyManagement/DepositRequestHistory";
import DepositRequestHistoryDetail from "./pages/MoneyManagement/DepositRequestHistoryDetail";
import WithdrawRequestDetail from "./pages/MoneyManagement/WithdrawRequestDetail";
import WithdrawRequestDetailUser from "./pages/MoneyManagement/WithdrawRequestDetailUser";
import WithdrawRequestNew from "./pages/MoneyManagement/WithdrawRequestNew";
import WithdrawRequestHistory from "./pages/MoneyManagement/WithdrawRequestHistory";
import WithdrawRequestHistoryDetail from "./pages/MoneyManagement/WithdrawRequestHistoryDetail";
import AdjustmentRequestNew from "./pages/MoneyManagement/AdjustmenRequestNew";
import BankAccount from "./pages/Banking/BankAccount";
import BankAccountEdit from "./pages/Banking/BankAccountEdit";
import RegisterBankNew from "./pages/Banking/RegisterBankNew";
import RegisterBankEdit from "./pages/Banking/RegisterBankEdit";
import SiteConfig from "./pages/WebManagement/Cms/SiteConfig";
import ContactUsNew from "./pages/WebManagement/Cms/ContactUsNew";
import PromotionalBannerNew from "./pages/WebManagement/Cms/PromotionalBannerNew";
import ContactUsUpdate from "./pages/WebManagement/Cms/ContactUsUpdate";
import SiteConfigUpdate from "./pages/WebManagement/Cms/SiteConfigUpdate";
import PromotionalBannerUpdate from "./pages/WebManagement/Cms/PromotionalBannerUpdate";
import PromoGroup from "./pages/WebManagement/Cms/PromoGroup";
import MetaUpdate from "./pages/WebManagement/Cms/MetaUpdate";
import UpdateUserList from "./pages/WebManagement/Users/UpdateUserList";
import AccessRightDetail from "./pages/WebManagement/Users/AccessRightDetail";
import NewUserList from "./pages/WebManagement/Users/NewUserList";
import Bonus from "./pages/Bonus/Bonus";
import TestPage from "./pages/Reporting/TestPage";
import UpdateSlideshowBanner from "./pages/WebManagement/Cms/UpdateSlidshowBanner";
import CreateSlideshowBanner from "./pages/WebManagement/Cms/CreateSlidshowBanner";
import UpdateWebLogo from "./pages/WebManagement/Cms/UpdateWebLogo";
import CashbackHistory from "./pages/MoneyManagement/CashbackHistory";
import CashbackHistoryDetail from "./pages/MoneyManagement/CashbackHistoryDetail";
import CashbackSettings from "./pages/MoneyManagement/CashbackSettings";
import RolingHistory from "./pages/MoneyManagement/RolingHistory";
import RolingHistoryDetail from "./pages/MoneyManagement/RolingHistoryDetail";
import RolingSettings from "./pages/MoneyManagement/RolingSettings";
import LotteryResult from "./pages/Lottery/LotteryResult";
import LotteryCalculate from "./pages/Lottery/LotteryCalculate";
import LotteryUnit from "./pages/Lottery/LotteryUnit";
import LotteryTransaction from "./pages/Lottery/LotteryTransaction";
import LotteryCancelBet from "./pages/Lottery/LotteryCancelBet";
import LotteryRecover from "./pages/Lottery/LotteryRecover";
import LotteryPrize from "./pages/Lottery/LotteryPrize";
import LotteryTransactionHistory from "./pages/Lottery/LotteryTransactionHistory";
import UpdateQris from "./pages/Banking/UpdateQris";

// eslint-disable-next-line
const routes = [
  {
    id: 1,
    name: "Dashboard",
    component: <Dashboard />,
    exact: true,
    path: "/"
  },
  {
    id: 2,
    name: "Site Config",
    component: <SiteConfig />,
    exact: true,
    path: "/web_management/cms/site_config"
  },
  {
    id: 3,
    name: "Player List",
    component: <PlayerList />,
    exact: true,
    path: "/player_management/player_list"
  },
  {
    id: 4,
    name: "Player Record",
    component: <PlayerRecord />,
    exact: true,
    path: "/player_management/player_record"
  },
  {
    id: 5,
    name: "Player Mutation",
    component: <PlayerMutation />,
    exact: true,
    path: "/player_management/player_mutation"
  },
  {
    id: 6,
    name: "Player Referral",
    component: <PlayerReferral />,
    exact: true,
    path: "/player_management/player_referral"
  },
  {
    id: 7,
    name: "Player Referral First Deposit",
    component: <PlayerReferralFirstDepo />,
    exact: true,
    path: "/player_management/player_referral_first_depo"
  },
  {
    id: 117,
    name: "Player Referral Detail",
    component: <PlayerReferralDetail />,
    exact: true,
    path: "/player_management/referral_detail/:user"
  },
  {
    id: 7,
    name: "Coin Admin",
    component: <CoinAdmin />,
    exact: true,
    path: "/money_management/coin_admin"
  },
  {
    id: 8,
    name: "Coin History",
    component: <CoinHistory />,
    exact: true,
    path: "/money_management/coin_history"
  },
  {
    id: 9,
    name: "Deposit Request",
    component: <DepositRequest />,
    exact: true,
    path: "/money_management/deposit_request"
  },
  {
    id: 10,
    name: "Deposit Transaction",
    component: <DepositRequestDetail />,
    exact: true,
    path: "/money_management/deposit_transaction/:date?"
  },
  {
    id: 11,
    name: "Deposit History",
    component: <DepositRequestHistory />,
    exact: true,
    path: "/money_management/deposit_history"
  },
  {
    id: 12,
    name: "Withdraw Transaction",
    component: <WithdrawRequestDetail />,
    exact: true,
    path: "/money_management/withdraw_transaction/:date?"
  },
  {
    id: 13,
    name: "Withdraw Request",
    component: <WithdrawRequest />,
    exact: true,
    path: "/money_management/withdraw_request"
  },
  {
    id: 14,
    name: "Withdraw History",
    component: <WithdrawRequestHistory />,
    exact: true,
    path: "/money_management/withdraw_history"
  },
  {
    id: 15,
    name: "Adjustment Transaction",
    component: <AdjustmentRequestDetail />,
    exact: true,
    path: "/money_management/adjustment_transaction"
  },
  {
    id: 16,
    name: "Adjustment Request",
    component: <AdjustmentRequest />,
    exact: true,
    path: "/money_management/adjustment_request"
  },
  {
    id: 17,
    name: "Player Growth",
    component: <PlayerGrowth />,
    exact: true,
    path: "/reporting/player_growth"
  },
  {
    id: 18,
    name: "PL Provider",
    component: <PlProvider />,
    exact: true,
    path: "/reporting/pl_provider"
  },
  {
    id: 19,
    name: "PL Member",
    component: <PlMember />,
    exact: true,
    path: "/reporting/pl_member/:user?"
  },
  {
    id: 20,
    name: "Betting Detail",
    component: <BettingDetail />,
    exact: true,
    path: "/reporting/betting_detail"
  },
  {
    id: 21,
    name: "Wallet History",
    component: <WalletHistory />,
    exact: true,
    path: "/reporting/wallet_history"
  },
  {
    id: 211,
    name: "Agent Report",
    component: <AgentReport />,
    exact: true,
    path: "/reporting/agent_report"
  },
  {
    id: 22,
    name: "Bank Account",
    component: <BankAccount />,
    exact: true,
    path: "/banking/bank_account"
  },
  {
    id: 23,
    name: "Bank Group",
    component: <BankGroup />,
    exact: true,
    path: "/banking/bank_group"
  },
  {
    id: 24,
    name: "Registration Bank List",
    component: <RegistrationBankList />,
    exact: true,
    path: "/banking/registration_bank_list"
  },
  {
    id: 25,
    name: "User List",
    component: <UserList />,
    exact: true,
    path: "/web_management/users/user_list"
  },
  {
    id: 26,
    name: "User Log",
    component: <UserLog />,
    exact: true,
    path: "/web_management/users/user_log"
  },
  {
    id: 27,
    name: "Access Rights",
    component: <AccessRights />,
    exact: true,
    path: "/web_management/users/access_rights"
  },
  {
    id: 28,
    name: "Slideshow Banner",
    component: <SlideshowBanner />,
    exact: true,
    path: "/web_management/cms/slideshow_banner"
  },
  {
    id: 29,
    name: "Promotional Banner Group",
    component: <PromoGroup />,
    exact: true,
    path: "/web_management/cms/promotional_banner"
  },
  {
    id: 30,
    name: "Contact Us",
    component: <ContactUs />,
    exact: true,
    path: "/web_management/cms/contact_us"
  },
  {
    id: 31,
    name: "Meta",
    component: <Meta />,
    exact: true,
    path: "/web_management/cms/meta"
  },
  {
    id: 32,
    name: "Member Menu",
    component: <MemberMenu />,
    exact: true,
    path: "/web_management/cms/member_menu"
  },
  {
    id: 33,
    name: "Annoucement",
    component: <UpdateLog />,
    exact: true,
    path: "/announcement"
  },
  {
    id: 34,
    name: "Detail Player",
    component: <DetailPlayer />,
    exact: true,
    path: "/player_detail/:id"
  },

  {
    id: 35,
    name: "New Request Deposit",
    component: <DepositRequestNew />,
    exact: true,
    path: "/money_management/create_deposit_request"
  },
  {
    id: 36,
    name: "Detail Request Deposit",
    component: <DepositRequestDetail />,
    exact: true,
    path: "/money_management/detail_deposit_request"
  },
  {
    id: 37,
    name: "Detail Request Deposit",
    component: <WithdrawRequestDetail />,
    exact: true,
    path: "/money_management/detail_withdraw_request"
  },
  {
    id: 38,
    name: "New Request Withdraw",
    component: <WithdrawRequestNew />,
    exact: true,
    path: "/money_management/create_withdraw_request"
  },
  {
    id: 39,
    name: "New Request Withdraw",
    component: <AdjustmentRequestNew />,
    exact: true,
    path: "/money_management/create_adjustment_request"
  },
  {
    id: 40,
    name: "Add Bank Account",
    component: <BankAccountNew />,
    exact: true,
    path: "/banking/create_bank_account"
  },
  ,
  {
    id: 41,
    name: "Edit Bank Account",
    component: <BankAccountEdit />,
    exact: true,
    path: "/banking/update_bank_account/:id"
  },
  {

    id: 42,
    name: "Register Bank",
    component: <RegisterBankNew />,
    exact: true,
    path: "/banking/register_bank"

  }
  ,
  {

    id: 43,
    name: "Register Bank Edit",
    component: <RegisterBankEdit />,
    exact: true,
    path: "/banking/register_bank_edit/:id"

  },
  {
    id: 44,
    name: "Site Config New",
    component: <SiteConfigNew />,
    exact: true,
    path: "/web_management/cms/site_config_new"
  },
  {
    id: 45,
    name: "Add Contact",
    component: <ContactUsNew />,
    exact: true,
    path: "/web_management/cms/contact_us_new"
  },
  {
    id: 46,
    name: "Promotial Banner New",
    component: <PromotionalBannerNew />,
    exact: true,
    path: "/web_management/cms/promotional_banner_new"
  },
  {
    id: 47,
    name: "Update Contact",
    component: <ContactUsUpdate />,
    exact: true,
    path: "/web_management/cms/contact_us_update/:id"
  },
  {
    id: 48,
    name: "Site Config Update",
    component: <SiteConfigUpdate />,
    exact: true,
    path: "/web_management/cms/site_config_update/:id"
  },
  {
    id: 49,
    name: "Promotial Banner Update",
    component: <PromotionalBannerUpdate />,
    exact: true,
    path: "/web_management/cms/promotional_banner_update/:id"
  },
  {
    id: 50,
    name: "Promotional Banner",
    component: <PromotionalBanner />,
    exact: true,
    path: "/web_management/cms/promotional_group"
  },
  {
    id: 51,
    name: "Meta Update",
    component: <MetaUpdate />,
    exact: true,
    path: "/web_management/cms/meta_update/:id"
  },
  {
    id: 52,
    name: "User List Update",
    component: <UpdateUserList />,
    exact: true,
    path: "/web_management/cms/user_list/:id"
  },
  {
    id: 53,
    name: "Access right detail",
    component: <AccessRightDetail />,
    exact: true,
    path: "/web_management/cms/detail_access_right/:id"
  },
  {
    id: 54,
    name: "User List New",
    component: <NewUserList />,
    exact: true,
    path: "/web_management/users/user_list_new"
  },
  {
    id: 55,
    name: "Deposit History Detail",
    component: <DepositRequestHistoryDetail />,
    exact: true,
    path: "/money_management/deposit_history_detail/:date"
  },
  {
    id: 56,
    name: "Bonus",
    component: <Bonus />,
    exact: true,
    path: "/bonus"
  },

  {
    id: 57,
    name: "Deposit Transaction Per user",
    component: <DepositRequestDetailUser />,
    exact: true,
    path: "/money_management/deposit_transaction_user/:user"
  },

  {
    id: 58,
    name: "Withdraw Transaction",
    component: <WithdrawRequestDetailUser />,
    exact: true,
    path: "/money_management/withdraw_transaction_user/:user"
  },

  {
    id: 61,
    name: "Withdraw History Detail",
    component: <WithdrawRequestHistoryDetail />,
    exact: true,
    path: "/money_management/withdraw_history_detail/:date"
  },

  {
    id: 59,
    name: "Deposit Bonus Request",
    component: <BonusDepositRequest />,
    exact: true,
    path: "/money_management/bonus"
  },

  {
    id: 60,
    name: "test",
    component: <TestPage />,
    exact: true,
    path: "/test"
  },

  {
    id: 61,
    name: "Update Slidshow Banner",
    component: <UpdateSlideshowBanner />,
    exact: true,
    path: "/web_management/cms/update_baner/:id"
  },

  {
    id: 62,
    name: "Add Slideshow Banner",
    component: <CreateSlideshowBanner />,
    exact: true,
    path: "/web_management/cms/create_baner"
  },

  {
    id: 62,
    name: "Update Web Logo",
    component: <UpdateWebLogo />,
    exact: true,
    path: "/web_management/cms/update_web_logo"
  },

  {
    id: 63,
    name: "Lottery Result",
    component: <LotteryResult />,
    exact: true,
    path: "/lottery/result"
  },

  {
    id: 64,
    name: "Lottery Calculate",
    component: <LotteryCalculate />,
    exact: true,
    path: "/lottery/calculate"
  },

  {
    id: 65,
    name: "Lottery Unit",
    component: <LotteryUnit />,
    exact: true,
    path: "/lottery/unit"
  },

  {
    id: 66,
    name: "Lottery Transaction",
    component: <LotteryTransaction />,
    exact: true,
    path: "/lottery/transaction"
  },

  {
    id: 67,
    name: "Lottery Cancel Bet",
    component: <LotteryCancelBet />,
    exact: true,
    path: "/lottery/transaction"
  },

  {
    id: 68,
    name: "Lottery Transaction History",
    component: <LotteryTransactionHistory />,
    exact: true,
    path: "/lottery/transaction_history"
  },

  {
    id: 69,
    name: "Lottery Recover",
    component: <LotteryRecover />,
    exact: true,
    path: "/lottery/recover"
  },

  {
    id: 70,
    name: "Lottery Unit Prize",
    component: <LotteryPrize />,
    exact: true,
    path: "/lottery/unit/:market"
  },

  {
    id: 71,
    name: "Cashback History",
    component: <CashbackHistory />,
    exact: true,
    path: "/money_management/cashback"
  },

  {
    id: 72,
    name: "Cashback History Detail",
    component: <CashbackHistoryDetail />,
    exact: true,
    path: "/money_management/cashback/:date"
  },

  {
    id: 73,
    name: "Cashback Settings",
    component: <CashbackSettings />,
    exact: true,
    path: "/money_management/cashback_settings"
  },

  {
    id: 74,
    name: "Roling History",
    component: <RolingHistory />,
    exact: true,
    path: "/money_management/roling"
  },

  {
    id: 75,
    name: "Roling History Detail",
    component: <RolingHistoryDetail />,
    exact: true,
    path: "/money_management/roling/:date"
  },

  {
    id: 76,
    name: "Roling Settings",
    component: <RolingSettings />,
    exact: true,
    path: "/money_management/roling_settings"
  },

  {
    id: 77,
    name: "Update Qris Image",
    component: <UpdateQris />,
    exact: true,
    path: "/banking/update-qris"
  },
]

export default routes;
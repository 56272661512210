import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
import Select from 'react-select'


export default function CardTable({ listPLMember,
  pending,
  usernameEvent,
  onChangeRowsPerPage,
  optionsLevel,
  levelEvent,
  fromdateEvent,
  todateEvent,
}) {
  const filteredItems = listPLMember;


  const columns = [
      {
        name: "#",
        width: "60px",
        selector: (row, index) => index+1,
        sortable: true,
      },
      {
        id: 'datetime',
        name: "datetime",
        width: '200px',
        selector: (row, index) => row.datetime,
        sortable: true,
      },
      {
        id: 'username',
        name: "username",
        width: '150px',
        selector: (row, index) => row.username,
        sortable: true,
      },
      {
        id: 'turnover',
        name: "TurnOver",
        width: '150px',
        selector: 'turnover',
        cell: (row, index) => row.turnover.toLocaleString(),
        sortable: true,
      },
      {
        id: 'menang_kalah',
        name: "Player Menang / Kalah",
        width: '180px',
        selector: 'menang_kalah',
        cell: (row, index) => <tt><font color={row.menang_kalah < 0 ? "red" : "black"}>{row.menang_kalah.toLocaleString()}</font></tt>,
        sortable: true,
        right: true
      },
  ];

  return (
    <>
      <Card>
        <h5>Advanced Search</h5>
        <CardBody>
          <div className="flex gap-2">
          <div style={{ width: '25%' }}>
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Username"
              iconName="search"
              onChange={usernameEvent}
            />
            </div>
             <div style={{ width: '25%' }}>
              <Select options={optionsLevel.map((option) => {
                return {
                  value: option.leid,
                  label: option.name
                }
              })
              }
                onChange={levelEvent}
                placeholder="Level" />
            </div>
            <div style={{ width: '50%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '5px' }}>
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="From Date"
                iconName=""
                onChange={fromdateEvent}
              />
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="To Date"
                iconName=""
                onChange={todateEvent}
              />
            </div>
          </div>
        
        </CardBody>
      </Card>
      <Card className="mt-10">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">PL Member</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            defaultSortFieldId="turnover"
            defaultSortAsc={false}
            data={filteredItems}
            progressPending={pending}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination
          />
        </CardBody>
      </Card >
    </>
  );
}

import React, { useEffect, useState, useContext } from "react";
import StatusCard from "components/StatusCard";
import { Link, useHistory } from "react-router-dom";
import axiosInstance from "../config/axios";
import Swal from "sweetalert2";
import { RootContext } from "../App";
import ReactLoading from "react-loading";
import refactorStructureData from "../helpers/refactorDataMenu";
import Button from "@material-tailwind/react/Button";
import SweetAlert2 from "react-sweetalert2";

export default function Dashboard() {
  const history = useHistory();
  const { state, setState } = useContext(RootContext);
  const [dataDashboard, setDataDashboard] = useState();
  const [swalProps, setSwalProps] = useState({});
 
  useEffect(() => {
    // console.log(localStorage.getItem("access_token"))

    getData();
    verifyToken();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyToken = () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      history.push("/login");
    } else {
      try {
        axiosInstance("verify_token", {
          method: "POST",
          data: {
            token: token,
          },
        })
          .then((res) => {
            setState({
              ...state,
              group: res.data.data.group,
              menu: refactorStructureData(res.data.data.menu),
            });
            if (!localStorage.getItem("group")) {
              localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
            }
          })
          .catch((err) => {
            console.log(err)
            localStorage.removeItem("access_token");
            history.push("/login");
          });

      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  const getData = () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      history.push("/login");
    } else {
      try {
        axiosInstance("get_dashboard", {
          method: "POST",
          data: {
            token: token,
          },
        })
          .then((res) => {
            setDataDashboard(res.data.data)
          })
          .catch((err) => {
            Swal.fire({
              icon: "warning",
              title: "Oops!",
              text: "Sesi anda telah habis, silahkan login ulang",
            }).then(() => {
              localStorage.removeItem("access_token");
              history.push("/login");
            });
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  return (
    <>
      {!dataDashboard ? (
        <div className="flex justify-center" style={{ marginTop: 150 }}>
          <ReactLoading
            type="spinningBubbles"
            color="rgb(53, 126, 221)"
            height={100}
            width={100}
          />
        </div>
      ) : (
        <>

        {
            swalProps.show && (
              <SweetAlert2 {...swalProps}>
              <div style={{ height: "50px" }}>
                <h3>Ganti Status Web jadi Maintenance?</h3>
              </div>
            </SweetAlert2>
            )
          }

         {/* button emergency */}
         <div className="bg-light-blue-500 px-12 md:px-2 h-auto">
          <div className="container mx-auto max-w-full">
              <Button size="md" color="red"  onClick={(event) => {
                console.log('emergency clicked');
                }}> Emergency
              </Button>
            </div>
          </div>

          <div className="bg-light-blue-500 pt-14 px-3 md:px-8 h-auto">
            <div className="container mx-auto max-w-full">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                <StatusCard
                  color="green"
                  icon="account_balance_wallet"
                  title="Total Wallet Balance"
                  amount={dataDashboard.totalbalance.toString()}
                />
                <StatusCard
                  color="orange"
                  icon="groups"
                  title="New Player Today"
                  amount={dataDashboard.newplayer.toString()}
                />
              </div>
            </div>
          </div>

          <div className="px-3 md:px-8 h-auto">
            <div className="container mx-auto max-w-full">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                <StatusCard
                  color="purple"
                  icon="paid"
                  title="First Deposit Today"
                  amount={dataDashboard.firstdepotoday.toString()}
                />
                <StatusCard
                  color="gray"
                  icon="person"
                  title="Current Player Online"
                  amount={dataDashboard.onlineplayer.toString()}
                />
              </div>
            </div>
          </div>

          <div className="px-3 md:px-8 h-auto">
            <div className="container mx-auto max-w-full">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                <StatusCard
                  color="yellow"
                  icon="local_activity"
                  title="Total Deposit today"
                  amount={dataDashboard.totaldepotoday.toString()}
                />
                <StatusCard
                  color="green"
                  icon="paid"
                  title="Deposit Amount Today"
                  amount={dataDashboard.depoamounttoday.toString()}
                />
                <StatusCard
                  color="red"
                  icon="paid"
                  title="Total Withdraw Today"
                  amount={dataDashboard.totalwdtoday}
                />
                <StatusCard
                  color="orange"
                  icon="paid"
                  title="Withdraw Amount Today"
                  amount={dataDashboard.wdamounttoday.toString()}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
import React, { useContext, useEffect, useState } from 'react';
import { RootContext } from '../../../App';
import TableMeta from '../../../components/TableMeta';
import axiosInstance from '../../../config/axios'
import refactorStructureData from '../../../helpers/refactorDataMenu'

const Meta = () => {

  const token = localStorage.getItem("access_token")
  const group = JSON.parse(localStorage.getItem("group"))
  const [data, setData] = useState([])
  const { state, setState } = useContext(RootContext)
  const [isLoading, setIsLoading] = useState(true)
  const [length, setLength] = useState(10)

  const getMeta = () => {
    axiosInstance.post('get_site_meta', {
      lgth: length,
      token: token,
      group: group.wbid,
    }).then((res) => {
      setIsLoading(false)
      setData(res.data.data.list)
      setState({
        ...state,
        group: res.data.data.additional_data.group,
        menu: refactorStructureData(res.data.data.additional_data.menu),
      })
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    getMeta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length])



  return (
    <div>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableMeta data={data} pending={isLoading}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setLength(currentRowsPerPage)
              }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Meta;
import React, { useContext, useEffect, useState } from 'react'
import { RootContext } from '../../../App'
import TableContactUs from '../../../components/TableContactUs'
// import TableSiteConfig from '../../../components/TableSiteConfig'
import axiosInstance from '../../../config/axios'
import refactorStructureData from '../../../helpers/refactorDataMenu'
function ContactUs() {
  const token = localStorage.getItem("access_token")
  const group = JSON.parse(localStorage.getItem("group"))
  const [data, setData] = useState([])
  const { state, setState } = useContext(RootContext)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    axiosInstance.post('get_site_contact', {
      token: token,
      group: group.wbid,
    }).then((res) => {
      setIsLoading(false)
      setData(res.data.data.list)
      setState({
        ...state,
        group: res.data.data.additional_data.group,
        menu: refactorStructureData(res.data.data.additional_data.menu),
      })
    }).catch((err) => {
      console.log(err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableContactUs data={data} pending={isLoading} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs
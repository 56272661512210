import React, { useState } from 'react'
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Input from "@material-tailwind/react/Input";
import Button from "@material-tailwind/react/Button";
import axiosInstance from "../config/axios";
import Swal from 'sweetalert2';

function PlayerTransactionForm({ data }) {

    const [amount, setAmount] = useState()

    const submit = (event) => {

        const token = localStorage.getItem("access_token")
        const group = JSON.parse(localStorage.getItem("group"))


        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance("add_balance_player_main", {
                    method: "POST",
                    data: {
                        token: token,
                        group: group.wbid,
                        amount: amount,
                        uid: data.uid,
                    },
                }).then((res) => {
                    Swal.fire(
                        'Success!',
                        `Your balance is updated to ${res.data.data}`,
                        'success'
                    )
                }).catch((err) => {
                    console.log(err)
                });

            }
        })
        event.preventDefault()

    }

    return (
        <>
            <Card className="mt-12">
                <CardHeader size="sm" color="blueGray" contentPosition="left">
                    <h2 className="text-white text-2xl">Player Transaction</h2>
                </CardHeader>
                <CardBody>
                    <div className="grid grid-cols-1 gap-4">
                        <div className="">
                            <Input
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="Total sum deposit"
                                value={data.sude}
                                disabled
                            />
                        </div>
                        <div className="">
                            <Input
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="Total count deposit"
                                value={data.code}
                                disabled
                            />
                        </div>
                        <div className="">
                            <Input
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="Total sum withdraw"
                                value={data.suwd}
                                disabled
                            />
                        </div>
                        <div className="">
                            <Input
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="Total count withdraw"
                                value={data.cowd}
                                disabled
                            />
                        </div>
                        <div className="">
                            <Input
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="Total sum hadiah referral"
                                value={data.sure}
                                disabled
                            />
                        </div>
                        <div className="">
                            <Input
                                type="text"
                                color="lightBlue"
                                size="sm"
                                outline={true}
                                placeholder="	Total count downline"
                                value={data.core}
                                disabled
                            />
                        </div>
                    </div>

                </CardBody>
            </Card>
        </>
    )
}

export default PlayerTransactionForm
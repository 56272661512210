const resources = {
  en: {
    translation: {
      "login": "Login"
    }
  },
  id: {
    translation: {
      "login": "Masuk"
    }
  }
};

export default resources;
import React, { useState, useEffect, useContext } from "react";
import TableCoinAdmin from "components/TableCoinAdmin";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "@material-tailwind/react";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";

export default function CoinAdmin() {
  const formTransferCoinInit = {
    id: '',
    current_coin: 0,
    new_coin: '',
    amount: 0,
  }

  const history = useHistory();
  const token = localStorage.getItem("access_token");

  const [data, setData] = useState();
  // const [pending, setPending] = useState(false);
  const [pending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formTransferCoin, setFormTransferCoin] = useState(formTransferCoinInit);
  const { state, setState } = useContext(RootContext)
  useEffect(() => {
    getData();
    verifyToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const verifyToken = () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      history.push("/login");
    } else {
      try {
        axiosInstance("verify_token", {
          method: "POST",
          data: {
            token: token,
          },
        })
          .then((res) => {
            setState({
              ...state,
              group: res.data.data.group,
              menu: refactorStructureData(res.data.data.menu),
            });
            if (!localStorage.getItem("group")) {
              localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
            }
          })
          .catch((err) => {

            localStorage.removeItem("access_token");
            history.push("/login");
          });

      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  const getData = () => {
    if (!token) {
      history.push("/login");
    } else {
      try {
        axiosInstance("get_coin_admin", {
          method: "POST",
          data: {
            token: token,
            group: 1
          },
        })
          .then((res) => {
            setData(res.data.data)
          })
          .catch((err) => {

            localStorage.removeItem("access_token");
            history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };

  const formatNum = (str) => {
    return +str.replace(/\./g, '').replace(/,/g, '.')
  }

  const handleModal = (isOpen, data) => {
    setIsModalOpen(isOpen);
    setFormTransferCoin((state) => ({
      ...state,
      id: data.id,
      current_coin: formatNum(data.coin),
      amount: formatNum(data.coin),
    }))
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormTransferCoin({
      ...formTransferCoin,
      [name]: value,
      amount: formTransferCoin.current_coin + formatNum(value)
    });
  };

  const handleOnSubmit = () => {
    setLoading(true);
    try {
      axiosInstance("coin_transfer", {
        method: "POST",
        data: {
          token: token,
          group: 1,
          receiver_id: formTransferCoin.id,
          coin: formTransferCoin.new_coin,
        },
      })
        .then((res) => {
          getData()
          setLoading(false);
          setIsModalOpen(false);
          setFormTransferCoin(formTransferCoinInit)
        })
        .catch((err) => {
          console.log('Error', err)
          setLoading(false);
          setIsModalOpen(false);
          setFormTransferCoin(formTransferCoinInit)
        });
    } catch (error) {
      setLoading(false);
      setIsModalOpen(false);
      setFormTransferCoin(formTransferCoinInit)
      console.log('error => ', error);
    }
  };

  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableCoinAdmin
              title="Coin Admin"
              data={data}
              pending={pending}
              handleModal={handleModal}
            />
            <Modal
              size="sm"
              active={isModalOpen}
              toggler={() => setIsModalOpen(false)}
            >
              <ModalHeader toggler={() => setIsModalOpen(false)}>
                Transfer Coin
              </ModalHeader>
              <ModalBody>
                <div className='flex flex-col space-y-4 w-72'>
                  <Input
                    type="text"
                    name="current_coin"
                    className="block w-full"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Current coin"
                    value={formTransferCoin.current_coin}
                    readOnly={true}
                  />
                  <Input
                    type="number"
                    name="new_coin"
                    className="block w-full"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Amount you want to transfer"
                    value={formTransferCoin.new_coin}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <Input
                    type="text"
                    name="amount"
                    className="block w-full"
                    color="lightBlue"
                    size="regular"
                    outline={true}
                    placeholder="Amount"
                    value={formTransferCoin.amount}
                    readOnly={true}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="red"
                  buttonType="link"
                  onClick={() => setIsModalOpen(false)}
                  ripple="dark"
                >
                  Cancel
                </Button>

                <Button
                  color="green"
                  onClick={() => handleOnSubmit()}
                  ripple="light"
                >
                  {loading ? 'Loading...' : 'Transfer'}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";

export default function CardTable() {
  return (
    <Card>
      <CardHeader color="blueGray" contentPosition="left">
        <h2 className="text-white text-2xl">Update Log</h2>
      </CardHeader>
      <CardBody>
        <div className="overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  style={{ width: 50 }}
                  className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left"
                >
                  Tanggal
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Waktu
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Details
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Aksi
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                  03/05/2022
                </th>
                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                  11:19
                </th>
                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                  Fitur baru - update bank 
                </th>
                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left flex">
                  <Button
                    color="lightBlue"
                    buttonType="outline"
                    size="regular"
                    rounded={false}
                    block={false}
                    iconOnly={false}
                    ripple="dark"
                  >
                    <Icon name="visibility" />
                    Lihat
                  </Button>
                  <Button
                    color="red"
                    buttonType="outline"
                    size="regular"
                    rounded={false}
                    block={false}
                    iconOnly={false}
                    ripple="dark"
                    className="ml-2"
                  >
                    <Icon name="delete" />
                    Hapus
                  </Button>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
// import { Button, Input, Textarea } from '@material-tailwind/react';
import { Button, Input } from '@material-tailwind/react';
import { RootContext } from '../../../App';
import refactorStructureData from '../../../helpers/refactorDataMenu';
import axiosInstance from '../../../config/axios'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import SelectRequired from '../../../components/RequiredSelect';
import Loading from '../../../components/Loading';
const ContactUsUpdate = () => {
    const { state, setState } = useContext(RootContext)
    const token = localStorage.getItem("access_token");
    const [isSubmit, setIsSubmit] = useState(false)
    const history = useHistory()
    const group = JSON.parse(localStorage.getItem("group"))
    const [value, setValue] = useState(null)
    const [previewImage, setPreviewImage] = useState()
    const { id } = useParams()
    const getContact = () => {

        axiosInstance("get_site_contact_detail", {
            method: "POST",
            data: {
                token: token,
                group: group.wbid,
                id: id
            },
        })
            .then((res) => {
                setValue(res.data.data.list[0])
                setState({
                    ...state,
                    group: res.data.data.additional_data.group,
                    menu: refactorStructureData(res.data.data.additional_data.menu),
                })
                if (!localStorage.getItem("group")) {
                    localStorage.setItem("group", JSON.stringify(res.data.data.group[0]))
                }
            })
            .catch((err) => {
                console.log(err)
            });


    };

    useEffect(() => {
        getContact()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleSubmit = (event) => {
        event.preventDefault()
        setIsSubmit(true)
        var bodyFormData = new FormData()
        bodyFormData.append("token", token)
        bodyFormData.append('group', group.wbid)
        bodyFormData.append('id', id)
        bodyFormData.append('app_name', value.name)

        // if (! typeof value.iurl === "string") {
        //     bodyFormData.append('icon_url', value.iurl)
        // }
        bodyFormData.append('isactive', value.actv)
        bodyFormData.append('value', value.valu)
        axiosInstance.post('update_site_contact', bodyFormData).then((res) => {
            history.push('/web_management/cms/contact_us')
            Swal.fire("Success", "Contact data is update", 'success')
            // console.log(res)
        }).catch((err) => {
            console.log(err.response)
            Swal.fire(err.response.data.data.icon_url[0], 'Please choose .jpg .png file', 'error')
        })
    }



    return (
        <div>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            {value ? (
                <div className="px-3 md:px-8 h-auto -mt-24">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 px-4 mb-16">
                            <Card>
                                <CardHeader color="blueGray" contentPosition="left">
                                    <h2 className="text-white text-2xl">Update Contact</h2>
                                </CardHeader>
                                <CardBody >
                                    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                                        <Input
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="App Name"
                                            defaultValue={value?.name}
                                            onChange={(event) => setValue({ ...value, name: event.target.value })}
                                            required
                                        />
                                        <Input
                                            type="file"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Icon URL"
                                            onChange={(event) => {
                                                setValue({ ...value, iurl: event.target.files[0] })
                                                setPreviewImage(URL.createObjectURL(event.target.files[0]))
                                            }}
                                        />
                                        <img src={previewImage ? previewImage : value.iurl} className="w-64 md:w-32 lg:w-48" alt=""/>
                                        <SelectRequired
                                            onChange={(event) => { setValue({ ...value, actv: event.value }) }}
                                            value={[{ label: 'active', value: 1 }, { label: 'disactive', value: 0 }].find((data) => data.value === value.actv)}
                                            options={[{ label: 'active', value: 1 }, { label: 'disactive', value: 0 }]}
                                        />

                                        <Input
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Value"
                                            onChange={(event) => {
                                                setValue({ ...value, valu: event.target.value })
                                            }}
                                            required
                                            defaultValue={value.valu}
                                        />
                                        <Button disabled={isSubmit}>Save</Button>
                                    </form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            ) : <Loading />}
        </div>
    )
}

export default ContactUsUpdate;
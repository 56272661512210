import React from 'react'
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import DataTable from 'react-data-table-component';
import Label from "@material-tailwind/react/Label";

function PlayerWalletForm({ data }) {

    const columns = [
        {
            name: 'Provider',
            selector: (row, idx) => row.prnm,
            width: '120px',
            sortable: true,

        },
        {
            name: 'Balance',
            selector: (row) => row.bala,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => {
                return Number(row.isac) === 1 ? (
                    <Label color="green">Active</Label>
                ) : Number(row.isac) === 0 ? (
                    <Label color="red">Locked</Label>
                ) : null
            },
            sortable: true,
        },

    ];

    return (
        <div>
            <Card >
                <CardHeader size="sm" color="blueGray" contentPosition="left">
                    <h2 className="text-white text-2xl">Player Wallet</h2>
                </CardHeader>
                <CardBody >
                    <div style={{ width: '100%' }}>
                        <DataTable
                            columns={columns}
                            data={data}
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default PlayerWalletForm
import React, { useState, useEffect, useContext } from "react";
import TableCoinHistory from "components/TableCoinHistory";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from "use-debounce";
import { RootContext } from "../../App";
import refactorStructureData from "../../helpers/refactorDataMenu";
export default function CoinHistory() {
  const history = useHistory();
  const [data, setData] = useState();
  const [sender, setSender] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [receiver, setReceiver] = useState(null)
  const [length, setLength] = useState(10)
  const [pending, setPending] = useState(true);
  const {state, setState } = useContext(RootContext)
  const debounced = useDebouncedCallback(
    (action) => {
      action()
    },
    200
  );

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiver, sender, toDate, fromDate, length])


  const getData = () => {

    const token = localStorage.getItem("access_token");

    const group = JSON.parse(localStorage.getItem("group"))
    const data = {
      token: token,
      group: group.wbid,
      user: sender,
      date: fromDate,
      date2: toDate,
      receiver_user: receiver,
      lgth: length,
    }
    if (!token) {
      history.push("/login");
    } else {
      try {

        axiosInstance("get_coin_history", {
          method: "POST",
          data,
        })
          .then((res) => {
            const data = res.data.data.list
            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });
            setData(data)
          })
          .then(() => {
            setPending(false);
          })
          .catch((err) => {
            localStorage.removeItem("access_token");
            history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };


  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TableCoinHistory
              title="Player List"
              data={data}
              pending={pending}
              senderEvent={(event) => {
                debounced(() => setSender(event.target.value))
              }}
              receiverEvent={(event) => {
                debounced(() => setReceiver(event.target.value))
              }}
              dateToEvent={(event) => {
                setToDate(event.target.value)
              }}
              dateFromEvent={(event) => {
                setFromDate(event.target.value)
              }}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setLength(currentRowsPerPage)
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import { RootContext } from '../../App';
import refactorStructureData from '../../helpers/refactorDataMenu';
import Swal from 'sweetalert2';
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import TablePlayerReferralFirstDepo from '../../components/TablePlayerReferralFirstDepo'

export default function PlayerReferral() {
    const history = useHistory();

    const token = localStorage.getItem("access_token");
    const group = JSON.parse(localStorage.getItem("group"))
    const [playerReferral, setPlayerReferral] = useState();
    const [uplineUsername, setUplineUsername] = useState(null);
    const [length, setLength] = useState(10);
    const { state, setState } = useContext(RootContext);
    const debounced = useDebouncedCallback( (action) => { action(); }, 200 );

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uplineUsername, length]);

    const getData = () => {
        if ( !token ) { history.push("/login"); }

        try {
            axiosInstance("get_downline_list", {
                method: "POST",
                data: {
                    token   : token,
                    group   : group.wbid,
                    user    : uplineUsername,
                },
            }).then((res) => {
                setState({
                  ...state,
                  group: res.data.data.additional_data.group,
                  menu: refactorStructureData(res.data.data.additional_data.menu),
                });
    
                setPlayerReferral(res.data.data.list);
            }).catch((err) => {
                Swal.fire(
                  'Error',
                  'get data failed',
                  'warning'
                );
            });
        } catch (error) {
            console.log("ERROR: ", error);
        }
    }
    
    return (
        <>
            <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16 ">
                        <Card>
                            <h5>Advanced Search</h5>
                            <CardBody>
                                <div className="flex gap-4" />
                                <InputIcon
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder="Upline"
                                    iconName="search"
                                    onChange={(event) => {
                                        debounced(() => setUplineUsername(event.target.value))
                                    }}
                                />
                            </CardBody>
                        </Card>
                        <TablePlayerReferralFirstDepo
                            title="Referal First Deposit" 
                            playerReferral={playerReferral}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
// import Button from "@material-tailwind/react/Button";
// import Label from "@material-tailwind/react/Label";
import InputIcon from "@material-tailwind/react/InputIcon";
// import { RootContext } from "../App";
// import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
// import { Link } from 'react-router-dom'
// import Select from 'react-select'


export default function CardTable({ listPlayer,
  pending,
  usernameEvent,
  // activeEvent,
  onChangeRowsPerPage,
  // optionsLevel,
  // levelEvent
}) {
  // const history = useHistory();
  const filteredItems = listPlayer;

  const columns = [
    {
      name: "#",
      width: "60px",
      selector: (row,index) => index+1,
      sortable: true,
    },
    {
      name: "username",
      width: '150px',
      selector: (row, index) => row.username,
      sortable: true,
    },
    {
      name: "TurnOver",
      width: '150px',
      selector: (row, index) => row.turnover.toLocaleString(),
      sortable: true,
    },
    {
      name: "Player Menang / Kalah",
      width: '150px',
      selector: (row, index) => <tt><font color="row.menang_kalah < 0 ? red : black">{row.menang_kalah.toLocaleString()}</font></tt>,
      sortable: true,
      right: true
    },
    
  ];

  return (
    <>
      <Card>
        <h5>Advanced Search</h5>
        <CardBody>
          <div className="flex gap-4" style={{ width: '25%' }}>
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="ID Pemain"
              iconName="search"
              onChange={usernameEvent}
            />
            
          </div>
      
        </CardBody>
      </Card>
      <Card className="mt-10">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Betting Detail</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={filteredItems}
            progressPending={pending}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination
          />
        </CardBody>
      </Card >
    </>
  );
}

import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
// import reportWebVitals from "../reportWebVitals";
// import formatDate from "../helpers/formatDate";
// import { RootContext } from "../App";
// import { Link } from 'react-router-dom'
// import Button from "@material-tailwind/react/Button";
// import { useHistory } from "react-router-dom";


export default function CardTable({ playerRecords, pending, usernameEvent, fromCreateAtEvent, toCreateAtEvent, onChangeRowsPerPage }) {
  // const history = useHistory();

  const columns = [
    {
      name: "#",
      width: "80px",
      selector: (row, idx) => row.uid,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.user,
      sortable: true,
    },
    {
      name: "Penjelasan",
      selector: (row) => row.desc,
      sortable: true,
    },
    {
      name: "Nama ISP",
      selector: (row) => row.isp,
      sortable: true,
    },
    {
      name: "Kota",
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: "Provinsi",
      selector: (row) => row.prov,
      sortable: true,
    },
    {
      name: "Negara",
      selector: (row) => row.coun,
      sortable: true,
    },
    {
      name: "Log IP",
      selector: (row) => {
        return row.ip
      },
      sortable: true,
    },

  ];

  return (
    <>
      <Card>
        <h5>Advanced Search</h5>
        <CardBody>
          <div className="flex gap-4">
            <InputIcon
              type="text"
              color="lightBlue"
              size="sm"
              outline={true}
              placeholder="Username"
              iconName="search"
              onChange={usernameEvent}
            />
          </div>
          <div className="flex mt-4">
            <div style={{ width: '100%', display: 'flex', gap: '10px', flexDirection: "column", border: '1px solid #DCDCDC', borderRadius: '10px', padding: '20px' }}>
              <p>Created Date</p>
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="From"
                iconName=""
                onChange={fromCreateAtEvent}
              />
              <InputIcon
                type="date"
                color="lightBlue"
                size="sm"
                outline={true}
                placeholder="To"
                iconName=""
                onChange={toCreateAtEvent}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card className="mt-12">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Player Records</h2>
        </CardHeader>
        <CardBody>
          <DataTable
            columns={columns}
            data={playerRecords}
            progressPending={pending}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationRowsPerPageOptions={[10, 50, 100, 500]}
            pagination
          />
        </CardBody>
      </Card>
    </>
  );
}

import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import { Link } from 'react-router-dom';
import React from "react";

function PlayerTotalTransactionForm({ data }) {

    function formatRupiah(angka) {
        return "Rp " + angka.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <>
            <Card className="mt-12">
                <CardHeader size="sm" color="blueGray" contentPosition="left">
                    <h2 className="text-white text-1xl">Player Total Transaction</h2>
                </CardHeader>
                <CardBody>
                    <div className="flex justify-between mb-2  gap-2">
                        <div className="basis-1/2">
                            <p className="text-xs">Total Jumlah Deposit Count</p>
                        </div>
                        <div className="basis-1/4">
                            <p className="text-xs ">{data.code}</p>
                        </div>
                    </div>
                    <div className="flex justify-between mb-2  gap-2">
                        <div className="basis-1/2">
                        <Link to={"/money_management/deposit_transaction_user/"+data.user}>
                            <Button color="blue" size="sm">Total Deposit</Button>
                        </Link>
                        </div>
                        <div className="basis-1/4">
                            <p className="text-xs ">{data.sude}</p>
                        </div>
                    </div>
                   
                    <div className="flex justify-between mb-2  gap-2">
                        <div className="basis-1/2">
                            <p className="text-xs">Total Jumlah Withdraw Count</p>
                        </div>
                        <div className="basis-1/4">
                            <p className="text-xs ">{data.cowd}</p>
                        </div>
                    </div>

                    <div className="flex justify-between mb-2  gap-2">
                        <div className="basis-1/2">
                            <Link to={"/money_management/withdraw_transaction_user/"+data.user}>
                                <Button color="blue" size="sm">Total Withdraw</Button>
                            </Link>
                        </div>
                        <div className="basis-1/4">
                            <p className="text-xs ">{data.suwd}</p>
                        </div>
                    </div>
                   
                    <div className='flex justify-between mb-2 mt-4 gap-2'>
                        <p className="text-xs font-semibold">Transaksi Wallet</p>
                    </div>
                    <div className="flex justify-between mb-4  gap-2">
                        <div className="basis-1/4">
                            <p className="text-xs mt-2">Turnover</p>
                        </div>
                        <div className="basis-1/2">
                            <p className="text-xs font-semibold mt-2">Total</p>
                        </div>
                        <div className="basis-1/4">
                            <p className="text-xs  mt-2">{data.turno}</p>
                        </div>
                    </div>

                    <div className="flex justify-between mb-2  gap-2">
                        <div className="basis-1/4">
                            <p className="text-xs mt-2">Winlose</p>
                        </div>
                        <div className="basis-1/2">
                            <p className="text-xs font-semibold mt-2">Total</p>
                        </div>
                        <div className="basis-1/4">
                            <p className="text-xs  mt-2"><font color={data.twlose < 0 ? "red" : "black"} >{data.twlose}</font></p>
                        </div>
                    </div>

                    <hr></hr>
                    <br></br>
                    <div className='flex justify-between mb-2 mt-4 gap-2'>
                        <p className="text-xs font-semibold">Register Date</p>
                    </div>
                    <div className="flex justify-between  gap-2">
                        <div className="basis-1/4">
                            <p className="text-xs  mt-2">Date Time</p>
                        </div>
                    </div>
                    <div className="basis-1/4">
                        <p className="text-xs font-semibold  mt-2">{data.retm}</p>
                    </div>
                    <div className='flex justify-between mb-2 mt-4 gap-2'>
                        <p className="text-xs font-semibold">First Deposit</p>
                    </div>
                    <div className="flex justify-between  gap-2">
                        <div className="basis-1/4">
                            <p className="text-xs  mt-2">Request Date</p>
                        </div>
                        <div className="basis-1/2">
                            <p className="text-xs  mt-2">Proses Date</p>
                        </div>
                        <div className="basis-1/4">
                            <p className="text-xs mt-2">Nominal</p>
                        </div>
                    </div>

                    {data.fdpd ? (
                        <div className="flex justify-between mb-2 gap-2">
                            <div className="basis-1/4">
                                <p className="text-xs font-semibold  mt-2">{data.fdpd}</p>
                            </div>
                            <div className="basis-1/2">
                                <p className="text-xs font-semibold mt-2">{data.fdpd}</p>
                            </div>
                            <div className="basis-1/4">
                                <p className="text-xs font-semibold mt-2">{formatRupiah(data.fdpa)}</p>
                            </div>
                        </div>
                    ) : (
                        <div className="text-xs font-semibold mt-2">No first deposit found.</div>
                    )}


                </CardBody>
            </Card>
        </>
    )
}

export default PlayerTotalTransactionForm
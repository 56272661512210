import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import InputIcon from "@material-tailwind/react/InputIcon";
import DataTable from "react-data-table-component";
import Button from "@material-tailwind/react/Button";

export default function TableCashbackHistoryDetail({
  data,
  pending,
  date,
  usernameEvent,
}) {

  function numberFormat(number) {

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "From Date",
      selector: (row) => row.fromdate,
      sortable: true,
    },
    {
      name: "To Date",
      selector: (row) => row.todate,
      sortable: true,
    },
    {
      name: "Lose",
      selector: (row) => (numberFormat(row.lose)),
      sortable: true,
    },
    {
      name: "Cashback",
      selector: (row) => (numberFormat(row.total_bonus)),
      sortable: true,
    },
    {
      name: "Percentage",
      selector: (row) => row.percentage_bonus + '%',
      sortable: true,
    },
    {
      name: "Before",
      selector: (row) => (numberFormat(row.balance_before)),
      sortable: true,
    },
    {
      name: "After",
      selector: (row) => (numberFormat(row.balance_after)),
      sortable: true,
    },
  ];

  const handleExport = () => {
    const header = 'username, lose, cashback, percentage, before, after\n';
    const csvData = data.map(d => `"${d.username}","${d.lose}","${d.total_bonus}","${d.percentage_bonus}","${d.balance_before}","${d.balance_after}"\n`).join('');
    const blob = new Blob([header + csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'cashback-'+date+'.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    };

  return (
    <>
      <Card>
        <CardBody>
          <InputIcon
            type="text"
            color="lightBlue"
            size="sm"
            outline={true}
            placeholder="Username"
            iconName="search"
            onChange={usernameEvent}
          />
        </CardBody>
      </Card>
      <Card className="mt-12">
        <CardHeader color="blueGray" contentPosition="left">
          <h2 className="text-white text-2xl">Cashback Detail</h2>
          <p>{date}</p>
        </CardHeader>
        <CardBody>
          <div className="flex gap-4">
              <Button
                onClick={handleExport}
              >
                Export To CSV
              </Button>
          </div>
          <DataTable
            columns={columns}
            data={data}
            progressPending={pending}
            pagination
          />
        </CardBody>
      </Card>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import TablePlayerWinLose from "components/TablePlayerWinLose";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { useDebouncedCallback } from 'use-debounce';
import refactorStructureData from "../../helpers/refactorDataMenu";
import { RootContext } from '../../App'

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

export default function PLMemberList() {
  const history = useHistory();
  const [listPLMember, setlistPLMember] = useState();
  const [optionsLevel, setOptionLevel] = useState([])
  const [pending, setPending] = useState(true);
  const [username, setUsername] = useState(null)
  const [level, setLevel] = useState(null)
  const [fromdate, setFromdate] = useState(null)
  const [todate, setTodate] = useState(null)
  const [length, setLength] = useState(100)
  const { state, setState } = useContext(RootContext)


  const debounced = useDebouncedCallback(
    (action) => {
      action()
    },
    200
  );

  useEffect(() => {

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    username,
    length,
    level,
    fromdate,
    todate,
  ]);

  const getData = () => {

    const token = localStorage.getItem("access_token");
    
    var todatenow = new Date();
    var dd_todatenow = String(todatenow.getDate()).padStart(2, '0');
    var mm_todatenow = String(todatenow.getMonth()+1).padStart(2, '0'); //January is 0!
    var yyyy_todatenow = todatenow.getFullYear();
    todatenow = yyyy_todatenow + '/' + mm_todatenow + '/' + dd_todatenow;

    var fromdatenow = new Date();
    fromdatenow.setDate(fromdatenow.getDate()-40);
    var dd_fromdatenow = String(fromdatenow.getDate()).padStart(2, '0');
    var mm_fromdatenow = String(fromdatenow.getMonth()+1).padStart(2, '0'); //January is 0!
    var yyyy_fromdatenow = fromdatenow.getFullYear();
    fromdatenow = yyyy_fromdatenow + '/' + mm_fromdatenow + '/' + dd_fromdatenow;

    const group = JSON.parse(localStorage.getItem("group"))
    const data = {
      token: token,
      group: group.wbid,
      username: username,
      levl: level,
      lgth: length,
      todate: todate == null ? todatenow : todate,
      fromdate: fromdate == null ? fromdatenow : fromdate,
    }
    
   

    if (!token) {
      history.push("/login");
    } else {
      try {

        axiosInstance("winlose_player", {
          method: "POST",
          data,
        })
          .then((res) => {
            // console.log(res)s
            const array = res.data.data.list;
            console.log(array);
            const result = [];

            array.forEach((item) => {
                const found = result.find((r) => r.username === item.username && formatDate(r.datetime) === formatDate(item.datetime));
                if (found) {
                    found.stake += item.stake;
                    found.wl += item.wl;
                    found.datetime = formatDate(item.datetime);
                } else {
                  result.push(item);
                }
            });

            let arr = result;
            
            for (let i = 0; i < arr.length; i++) {
              let element = arr[i];
              // Ganti nama key jadi turnover dan menang_kalah
              element['turnover'] = element['stake'];
              element['menang_kalah'] = element['wl'] - element['stake'];
              delete element['stake'];
              delete element['wl'];
            }
            // console.log(arr);

            //const data = res.data.data.list
            const data = arr;

            setlistPLMember(data)
            setPending(false);

            setState({
              ...state,
              group: res.data.data.additional_data.group,
              menu: refactorStructureData(res.data.data.additional_data.menu),
            });

            const level = res.data.data.adv_search.level
            level.unshift({ leid: null, name: 'All' })
            setOptionLevel(level)

          })
          .then(() => {
          })
          .catch((err) => {
            // localStorage.removeItem("access_token");
            // history.push("/login");
          });
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };


  return (
    <>
      <div className="bg-light-blue-500 pt-7 pb-28 px-3 md:px-8 h-auto"></div>
      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <TablePlayerWinLose
              title="Player List"
              listPLMember={listPLMember}
              optionsLevel={optionsLevel}
              pending={pending}

              usernameEvent={(event) => {

                debounced(() => setUsername(event.target.value))

              }}

              fromdateEvent={(event) => {

                setFromdate(event.target.value)
                //debounced(() => setFromdate(event.target.value) )
                console.log("fromdate: "+fromdate)

              }}

              todateEvent={(event) => {

                debounced(() => setTodate(event.target.value) )
                console.log("todate: "+todate)

              }}
              
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setLength(currentRowsPerPage)
              }}

              levelEvent={(event) => {
                setLevel(event.value)
                console.log("level: "+level)

              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

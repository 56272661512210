import axios from "axios";
import Swal from "sweetalert2";
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_URL_BACKEND}/api/bo`,
  // timeout: 1000,
  headers: { 'X-Custom-Header': 'foobar' }
});


instance.interceptors.request.use(function (config) {


  config.headers['Authorization'] = "Bearer " + localStorage.getItem("access_token")


  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data


  return response;
}, function (error) {


  if (localStorage.getItem("access_token")) {
    if (error.response.status === 401) {
      window.location.href = "/ "
      localStorage.removeItem("group")
      localStorage.removeItem("access_token")

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Your access expire!',
      })
    }
    if (error.response.status === 429) {
      window.location.href = "/ "
      localStorage.removeItem("group")
      localStorage.removeItem("access_token")

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Your access expire!',
      })
    }
  }

  if (error.response.status === 401) {
    window.location.href = "/ "
    localStorage.removeItem("group")
    localStorage.removeItem("access_token")
  }
  if (error.response.status === 500) {
    alert('internal server error')
  }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});



export default instance
